module.exports = {
  "AU": [ 10, 0 ],
  "AT": [ 20, 10, 13 ],
  "BE": [ 21, 12, 6, 0 ],
  "BG": [ 20, 9, 0 ],
  "HR": [ 25, 13, 5 ],
  "CY": [ 19, 8, 5 ],
  "CZ": [ 21, 15, 10 ],
  "DK": [ 25, 0 ],
  "EE": [ 22, 20, 9, 0 ],
  "FI": [ 25.5, 14, 10, 0 ],
  "FR": [ 20, 10, 5.5, 2.1 ],
  "DE": [ 19, 7, 0 ],
  "GR": [ 24, 13, 6.5 ],
  "HU": [ 27, 18, 5, 0 ],
  "IE": [ 23, 13.5, 9, 4.8, 0 ],
  "IT": [ 22, 10, 4, 0 ],
  "LV": [ 21, 12, 0 ],
  "LT": [ 21, 9, 5 ],
  "LU": [ 17, 14, 8, 3 ],
  "MT": [ 18, 7, 5, 0 ],
  "NL": [ 21, 9, 0 ],
  "PL": [ 23, 8, 5, 0 ],
  "PT": [ 23, 13, 6 ],
  "RO": [ 19, 9, 5 ],
  "SK": [ 20, 10 ],
  "SI": [ 22, 9.5 ],
  "ES": [ 21, 10, 4 ],
  "SE": [ 25, 12, 6, 0 ],
  "NO": [ 25, 15, 8, 0 ],
  "GB": [ 20, 5, 0 ]
}