<template>
  <div class="button-switch-container" :class="{ desktop }">
    <div
      class="button-option"
      v-for="{ title, value } in options"
      :key="value"
      :class="{ selected: value === localValue }"
      @click="changeValue(value)"
    >
      {{ title }}
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'

export default {
  name: 'UiSwitch',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: [String, Number]
    },
    options: {
      type: Array
    },
    desktop: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const localValue = ref(props.modelValue)

    watch(
      () => props.modelValue,
      () => {
        localValue.value = props.modelValue
      }
    )

    const changeValue = (newValue) => {
      emit('update:modelValue', newValue)
    }

    return {
      localValue,
      changeValue
    }
  }
}
</script>

<style lang="scss" scoped>
.button-switch-container {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  min-height: 56px;

  .button-option {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $light-blue;
    color: $medium-dark-grey;
    background: $white;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
  }

  .button-option:hover {
    color: $main-blue;
  }

  .button-option:first-child {
    border-radius: 8px 0 0 8px;
  }

  .button-option:last-child {
    border-radius: 0 8px 8px 0;
  }

  .button-option.selected {
    background: $light-blue;
    border: 2px solid $main-blue;
    color: $main-blue;
  }

  // DESKTOP VIEW
  &.desktop {
    width: max-content;
    min-height: 24px;
    background: $medium-light-grey;
    border-radius: 4px;
    padding: 4px;

    .button-option {
      border: none;
      background: transparent;
      padding: 6px 24px;
      font-size: 12px;
    }

    .button-option.selected {
      background: $white;
      border-radius: 4px;
    }
  }
}
</style>
