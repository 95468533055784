module.exports = {
  AF: 33,
  IE: 69,
  AL: 33,
  DZ: 57,
  AD: 46,
  AO: 70,
  AR: 50,
  AM: 47,
  AU: 50,
  AT: 63,
  AZ: 47,
  BS: 50,
  BH: 53,
  BY: 60,
  BD: 33,
  BE: 61,
  BA: 43,
  BW: 33,
  BR: 43,
  BN: 40,
  BG: 39,
  BF: 37,
  BT: 33,
  MK: 33,
  CZ: 50,
  CL: 47,
  DK: 80,
  CI: 40,
  EG: 43,
  EC: 33,
  EE: 47,
  ET: 33,
  PH: 40,
  GH: 47,
  GR: 55,
  GE: 53,
  GT: 33,
  IN: 33,
  ID: 33,
  IQ: 40,
  IR: 37,
  IS: 57,
  ES: 58,
  IT: 63,
  IL: 70,
  HK: 40,
  MO: 37,
  JP: 87,
  YE: 40,
  JO: 40,
  GB: 67,
  US: 53,
  AE: 47,
  ME: 53,
  KY: 53,
  KH: 33,
  CA: 55,
  QA: 52,
  KZ: 47,
  KE: 40,
  CN: 37,
  CY: 62,
  KG: 50,
  CO: 33,
  CD: 70,
  KR: 67,
  KP: 33,
  XK: 35,
  CR: 33,
  HR: 40,
  CU: 50,
  KW: 57,
  LA: 33,
  LV: 44,
  PL: 48,
  LS: 33,
  LB: 47,
  LR: 57,
  LY: 33,
  LI: 53,
  LT: 15,
  LU: 61,
  MG: 33,
  MY: 33,
  MW: 33,
  ML: 40,
  MT: 60,
  MA: 50,
  MU: 40,
  MR: 33,
  MX: 47,
  MM: 33,
  MD: 53,
  MC: 49,
  MN: 47,
  MZ: 40,
  NA: 33,
  NZ: 40,
  NP: 33,
  NG: 33,
  NL: 64,
  NO: 53,
  OM: 47,
  PK: 33,
  PA: 33,
  PG: 37,
  PE: 50,
  ZA: 33,
  SS: 37,
  PT: 56,
  FR: 63,
  PR: 43,
  RW: 43,
  RO: 35,
  RU: 60,
  SM: 40,
  SA: 57,
  SC: 57,
  SN: 43,
  KN: 57,
  RS: 53,
  SG: 50,
  SY: 53,
  SK: 53,
  SI: 47,
  SD: 37,
  FI: 69,
  LK: 33,
  SE: 65,
  CH: 53,
  TJ: 50,
  TH: 40,
  TW: 37,
  TZ: 33,
  TN: 40,
  TR: 37,
  TM: 53,
  UG: 37,
  UA: 53,
  UY: 37,
  UZ: 50,
  VA: 40,
  VE: 57,
  HU: 48,
  VN: 33,
  DE: 62,
  ZM: 33,
  ZW: 33
}
