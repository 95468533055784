<template>
  <div class="info-box">
    <div class="icon-section">
      <slot name="icon"></slot>
    </div>
    <div class="text-section">
      <slot name="text"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiInfoBox'
}
</script>

<style lang="scss" scoped>
.info-box {
  display: flex;
  padding: 16px;
  width: 100%;
  border-radius: 8px;
  background: $white;
  box-shadow: $shadow-extended;
}

.icon-section,
.text-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.icon-section {
  align-items: center;
  margin-right: 18px;
}
</style>
