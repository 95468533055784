/**
 * Finds element with most occurences. If two or more elements have equal amount of occurences,
 * the one that appears latest in array will be returned.
 * @param {Array}
 * @returns Element with most occurences
 */
export default function findMostCommonOccurence(list = []) {
  if (list.length < 1) {
    return ''
  }
  return list.sort((a, b) => list.filter(v => v === a).length - list.filter(v => v === b).length)[list.length - 1]
}
