import { Directive } from 'vue'
import striptags from 'striptags'

// Gets rid of HTML injection vulnerability in input fields. Can be applied to input and textarea fields.
const sanitize: Directive = {
  beforeMount(el, binding) {
    if (['value', undefined, null].includes(binding.arg)) {
      el.addEventListener('input', () => {
        // Check if input field type is a string input (text) or textarea (textarea)
        if (el?.type === 'text' || el?.type === 'textarea') {
          el.value = striptags(el.value)
        }
      })
    }
  }
}

export default sanitize
