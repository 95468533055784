import {
  CONFIG_STORE_ACTIONS,
  CONFIG_STORE_MUTATIONS
} from '@/enums'

import axios from 'axios'

export default {
  [CONFIG_STORE_ACTIONS.LOAD_CONFIG]: async ({ commit, ...rest }: any) => {
    const { data: config } = await axios.get('/config.json', {})
    commit(CONFIG_STORE_MUTATIONS.SET_CONFIG, config)

    return config
  }
}