export default {
  US: {
    'US, New York': {
      eng: 'New York',
      est: 'New York',
      fin: 'New York',
      lav: 'Ņujorka',
      rus: 'Нью-Йорк',
      pol: 'Nowy Jork',
      swe: 'New York',
      lit: 'Niujorkas',
      esp: 'Nueva York'
    },
    'US, Virgin Islands': {
      eng: 'Virgin Islands',
      est: 'Neitsisaared',
      fin: 'Neitsytsaaret',
      lav: 'Virdžīnas',
      rus: 'Виргинские острова',
      pol: 'Wyspy Dziewicze',
      swe: 'Jungfruöarna',
      lit: 'Mergelių salos',
      esp: 'Islas Vírgenes'
    }
  },
  RU: {
    'RU, Moscow': {
      eng: 'Moscow',
      est: 'Moskva',
      fin: 'Moskova',
      lav: 'Maskava',
      rus: 'Москва',
      pol: 'Moskwa',
      swe: 'Moskva',
      lit: 'Maskva',
      esp: 'Moscú'
    },
    'RU, Saint Petersburg': {
      eng: 'Saint Petersburg',
      est: 'Peterburi',
      fin: 'Pietari',
      lav: 'Sanktpēterburga',
      rus: 'Санкт-Петербург',
      pol: 'Petersburg',
      swe: 'Sankt Petersburg',
      lit: 'Sankt Petersburgas',
      esp: 'San Petersburgo'
    }
  },
  CN: {
    'CN, Beijing': {
      eng: 'Beijing',
      est: 'Peking',
      fin: 'Peking',
      lav: 'Pekina',
      rus: 'Пекин',
      pol: 'Pekin',
      swe: 'Beijing',
      lit: 'Pekinas',
      esp: 'Pekín'
    },
    'CN, Shanghai': {
      eng: 'Shanghai',
      est: 'Shanghai',
      fin: 'Shanghai',
      lav: 'Šanhaja',
      rus: 'Шанхай',
      pol: 'Szanghaj',
      swe: 'Shanghai',
      lit: 'Šanchajus',
      esp: 'Shanghái'
    },
    'CN, Shenzhen': {
      eng: 'Shenzhen',
      est: 'Shenzhen',
      fin: 'Shenzhen',
      lav: 'Šeņdžeņa',
      rus: 'Шэньчжэнь',
      pol: 'Shenzhen',
      swe: 'Shenzhen',
      lit: 'Šenzenas',
      esp: 'Shenzhen'
    },
    'CN, Guangzhou': {
      eng: 'Guangzhou',
      est: 'Guangzhou',
      fin: 'Kanton',
      lav: 'Guandžou',
      rus: 'Гуанчжоу',
      pol: 'Kanton',
      swe: 'Guangzhou',
      lit: 'Guangdžou',
      esp: 'Cantón'
    },
    'CN, Hangzhou': {
      eng: 'Hangzhou',
      est: 'Hangzhou',
      fin: 'Hangzhou',
      lav: 'Handžou',
      rus: 'Ханчжоу',
      pol: 'Hangzhou',
      swe: 'Hangzhou',
      lit: 'Hangdžou',
      esp: 'Hangzhou'
    },
    'CN, Hong Kong': {
      eng: 'Hong Kong',
      est: 'Hongkong',
      fin: 'Hongkong',
      lav: 'Honkonga',
      rus: 'Гонконг',
      pol: 'Hongkong',
      swe: 'Hongkong',
      lit: 'Honkongas',
      esp: 'Hong Kong'
    },
    'CN, Macau': {
      eng: 'Macau',
      est: 'Macau',
      fin: 'Macao',
      lav: 'Makao',
      rus: 'Макао',
      pol: 'Makau',
      swe: 'Macau',
      lit: 'Makao',
      esp: 'Macao'
    }
  },
  DE: {
    'DE, Hamburg': {
      eng: 'Hamburg',
      est: 'Hamburg',
      fin: 'Hampuri',
      lav: 'Hamburga',
      rus: 'Гамбург',
      pol: 'Hamburg',
      swe: 'Hamburg',
      lit: 'Hamburgas',
      esp: 'Hamburgo'
    },
    'DE, Munich': {
      eng: 'Munich',
      est: 'München',
      fin: 'München',
      lav: 'Minhene',
      rus: 'Мюнхен',
      pol: 'Monachium',
      swe: 'München',
      lit: 'Miunchenas',
      esp: 'Múnich'
    },
    'DE, Frankfurt': {
      eng: 'Frankfurt',
      est: 'Frankfurt',
      fin: 'Frankfurt',
      lav: 'Frankfurte',
      rus: 'Франкфурт',
      pol: 'Frankfurt',
      swe: 'Frankfurt',
      lit: 'Frankfurtas',
      esp: 'Fráncfort'
    },
    'DE, Berlin': {
      eng: 'Berlin',
      est: 'Berliin',
      fin: 'Berliini',
      lav: 'Berlīne',
      rus: 'Берлин',
      pol: 'Berlin',
      swe: 'Berlin',
      lit: 'Berlynas',
      esp: 'Berlín'
    }
  }
}
