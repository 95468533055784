import { RECEIPTS_STORE_MUTATIONS, RECEIPTS_STORE_STATE } from '@/enums'

import { ReceiptsStoreState, Receipt } from '@/models'

export default {
  [RECEIPTS_STORE_MUTATIONS.SET_RECEIPTS]: (state: ReceiptsStoreState, receipts: Receipt[]) => {
    for (const receipt of receipts) {
      state[RECEIPTS_STORE_STATE.RECEIPTS][receipt.id] = receipt
    }
  },
  [RECEIPTS_STORE_MUTATIONS.SET_RECEIPT_VALUES]: (state: ReceiptsStoreState, payload: any = {}) => {
    const { receipt, values } = payload
    const { id } = receipt

    state[RECEIPTS_STORE_STATE.RECEIPTS][id] = {
      ...state[RECEIPTS_STORE_STATE.RECEIPTS][id],
      ...values
    }
  },
  [RECEIPTS_STORE_MUTATIONS.SET_RECEIPT_DUPLICATES]: (state: ReceiptsStoreState, payload: any) => {
    const { receiptId, duplicates } = payload

    state[RECEIPTS_STORE_STATE.RECEIPT_DUPLICATES][receiptId] = duplicates
  },
  [RECEIPTS_STORE_MUTATIONS.SET_SELECTED_RECEIPTS]: (
    state: ReceiptsStoreState,
    payload: { receiptIds: number[]; selected: boolean }
  ) => {
    const { receiptIds, selected } = payload

    if (selected && receiptIds.length > 1) {
      state[RECEIPTS_STORE_STATE.SELECTED_RECEIPTS] = receiptIds
    } else if (selected &&receiptIds.length === 1) {
      state[RECEIPTS_STORE_STATE.SELECTED_RECEIPTS].push(receiptIds[0])
    } else if (!selected && receiptIds.length === 1) {
      const receiptIndex = state[RECEIPTS_STORE_STATE.SELECTED_RECEIPTS].indexOf(receiptIds[0])
      state[RECEIPTS_STORE_STATE.SELECTED_RECEIPTS].splice(receiptIndex, 1)
    } else {
      receiptIds.forEach((receiptId) => {
        const receiptIndex = state[RECEIPTS_STORE_STATE.SELECTED_RECEIPTS].indexOf(receiptId)
        state[RECEIPTS_STORE_STATE.SELECTED_RECEIPTS].splice(receiptIndex, 1)
      })
    }
  },
  [RECEIPTS_STORE_MUTATIONS.CLEAR_SELECTED_RECEIPTS]: (state: ReceiptsStoreState) => {
    state[RECEIPTS_STORE_STATE.SELECTED_RECEIPTS] = []
  },
  [RECEIPTS_STORE_MUTATIONS.REMOVE_RECEIPT]: (state: ReceiptsStoreState, id: number) => {
    if (state[RECEIPTS_STORE_STATE.RECEIPTS][id]) {
      delete state[RECEIPTS_STORE_STATE.RECEIPTS][id]
    }
  },
  [RECEIPTS_STORE_MUTATIONS.SET_RECEIPT_BROWSING_LIST]: (state: ReceiptsStoreState, payload: any = {}) => {
    const { receiptIds }: { receiptIds: Array<string | number> } = payload

    state[RECEIPTS_STORE_STATE.RECEIPT_BROWSING_LIST] = receiptIds
  }
}
