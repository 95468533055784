import { OVERVIEW_STATUS_KEYS, OVERVIEW_STORE_MUTATIONS, OVERVIEW_STORE_STATE } from '@/enums'
import { OverviewStoreState } from '@/models'

export default {
  [OVERVIEW_STORE_MUTATIONS.SET_TRIPS_COUNT]: (state: OverviewStoreState, tripsCount: Array<any>) => {
    state[OVERVIEW_STORE_STATE.TRIPS_COUNT] = {}
    
    tripsCount.forEach((company) => {
      const statuses: any = {}

      Object.keys(company.statusCount).forEach((statusKey) => {
        // @ts-ignore
        if (company.statusCount[statusKey] > 0 && OVERVIEW_STATUS_KEYS[statusKey]) {
          statuses[statusKey] = company.statusCount[statusKey]
        }
      })

      state[OVERVIEW_STORE_STATE.TRIPS_COUNT][company.companyId] = statuses
    })
  },
  [OVERVIEW_STORE_MUTATIONS.SET_RECEIPTS_COUNT]: (state: OverviewStoreState, receiptsCount: Array<any>) => {
    state[OVERVIEW_STORE_STATE.RECEIPTS_COUNT] = {}

    receiptsCount.forEach((company) => {
      const statuses: any = {}
      company.statusCount.Unsubmitted =
        company.statusCount.Any - company.statusCount.Submitted - company.missingFileCount

      company.statusCount.NoPicture = company.missingFileCount

      company.statusCount.Digitized =
        company.statusCount.Any -
        company.statusCount.Digitizing -
        company.statusCount.Processing -
        company.statusCount.Unsubmitted -
        company.statusCount.Bounced -
        company.statusCount.Approving -
        company.statusCount.Rejected -
        Math.max(company.statusCount.Approved, company.statusCount.Sent)

      Object.keys(company.statusCount).forEach((statusKey) => {
        // @ts-ignore
        if (company.statusCount[statusKey] > 0 && OVERVIEW_STATUS_KEYS[statusKey]) {
          statuses[statusKey] = company.statusCount[statusKey]
        }
      })

      state[OVERVIEW_STORE_STATE.RECEIPTS_COUNT][company.companyId] = statuses
    })
  }
}
