import { cloneDeep } from 'lodash'
import { APPROVAL_SETTINGS_STORE_MUTATIONS, APPROVAL_SETTINGS_STORE_STATE, APPROVAL_SCOPE } from '@/enums'
import { ApprovalSettingsStoreState, Workflow, Policy, Round } from '@/models'

export default {
  [APPROVAL_SETTINGS_STORE_MUTATIONS.SET_APPROVAL_WORKFLOWS]: (state: ApprovalSettingsStoreState, payload: any) => {
    const { companyId, workflows } = payload

    workflows.forEach((workflow: Workflow) => {
      if (!state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_WORKFLOWS][companyId]) {
        state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_WORKFLOWS][companyId] = {}
      }
      state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_WORKFLOWS][companyId][workflow.id] = workflow
    })
  },
  [APPROVAL_SETTINGS_STORE_MUTATIONS.SET_APPROVAL_WORKFLOW]: (state: ApprovalSettingsStoreState, payload: any) => {
    const { companyId, workflow } = payload

    if (!state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_WORKFLOWS][companyId]) {
      state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_WORKFLOWS][companyId] = {}
    }

    state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_WORKFLOWS][companyId][workflow.id] = workflow
  },
  [APPROVAL_SETTINGS_STORE_MUTATIONS.SET_WORKFLOW_VALUE]: (state: ApprovalSettingsStoreState, payload: any) => {
    const { companyId, workflowId, values } = payload

    state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_WORKFLOWS][companyId][workflowId] = {
      ...state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_WORKFLOWS][companyId][workflowId],
      ...values
    }
  },
  [APPROVAL_SETTINGS_STORE_MUTATIONS.SET_APPROVAL_POLICIES]: (state: ApprovalSettingsStoreState, payload: any) => {
    const { companyId, policies } = payload

    policies.forEach((policy: Policy) => {
      if (!state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_POLICIES][companyId]) {
        state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_POLICIES][companyId] = {}
      }

      state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_POLICIES][companyId][policy.id] = policy
    })
  },
  [APPROVAL_SETTINGS_STORE_MUTATIONS.SET_APPROVAL_OPTIONS]: (state: ApprovalSettingsStoreState, payload: any) => {
    const { companyId, options } = payload

    state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_OPTIONS][companyId] = options
  },
  [APPROVAL_SETTINGS_STORE_MUTATIONS.SET_APPROVAL_POLICY]: (state: ApprovalSettingsStoreState, payload: any) => {
    const { companyId, policy, workflowId, roundId, scope } = payload
    // UPDATE WORKFLOW POLICIES
    if (scope === APPROVAL_SCOPE.WORKFLOW && workflowId) {
      const policies = state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_WORKFLOWS][companyId][workflowId]?.policies || []

      const filteredPolicies = policies?.filter((existingPolicy: Policy) => existingPolicy.id !== policy.id) || []

      filteredPolicies.push(policy)

      state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_WORKFLOWS][companyId][workflowId] = {
        ...state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_WORKFLOWS][companyId][workflowId],
        policies: filteredPolicies
      }
    // UPDATE ROUND POLICIES
    } else if (scope === APPROVAL_SCOPE.ROUND && workflowId && roundId) {
      const currentRound = state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_WORKFLOWS][companyId][workflowId]?.rounds?.find(
        ({ id }) => Number(id) === Number(roundId)
      )

      if (currentRound) {
        const updatedRound: Round = cloneDeep(currentRound)

        const policyExists = updatedRound?.policies?.find(({ policyKey}) => policyKey === policy.policyKey)
        if (policyExists) {
          updatedRound.policies = updatedRound?.policies?.map((existingPolicy) => {
            return existingPolicy.policyKey === policy.policyKey ? policy : existingPolicy
          })
        } else {
          if (!updatedRound.policies) updatedRound.policies = []
          updatedRound.policies?.push(policy)
        }

        const updatedRounds = state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_WORKFLOWS][companyId][
          workflowId
        ]?.rounds?.map((round) => {
          return round.id === Number(roundId) ? updatedRound : round
        })

        state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_WORKFLOWS][companyId][workflowId] = {
          ...state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_WORKFLOWS][companyId][workflowId],
          rounds: updatedRounds
        }
      }
    // UPDATE COMPANY POLICIES
    } else {
      if (!state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_POLICIES][companyId]) {
        state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_POLICIES][companyId] = {}
      }

      state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_POLICIES][companyId][policy.id] = policy
    }
  },
  [APPROVAL_SETTINGS_STORE_MUTATIONS.SET_APPROVAL_ROUND]: (state: ApprovalSettingsStoreState, payload: any) => {
    const { companyId, workflowId, round } = payload

    state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_WORKFLOWS][companyId][workflowId] = {
      ...state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_WORKFLOWS][companyId][workflowId],
      rounds: [...(state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_WORKFLOWS][companyId][workflowId]?.rounds || []), round]
    }
  },
  [APPROVAL_SETTINGS_STORE_MUTATIONS.DELETE_WORKFLOW]: (state: ApprovalSettingsStoreState, payload: any) => {
    const { companyId, workflowId } = payload

    delete state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_WORKFLOWS][companyId][workflowId]
  },
  [APPROVAL_SETTINGS_STORE_MUTATIONS.DELETE_POLICY]: (state: ApprovalSettingsStoreState, payload: any) => {
    const { companyId, policyId, scope } = payload

    if (scope === APPROVAL_SCOPE.COMPANY) {
      delete state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_POLICIES][companyId][policyId]
    }
  }
}
