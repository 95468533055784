import { TRANSACTIONS_STORE_GETTERS, TRANSACTIONS_STORE_STATE } from '@/enums'
import { TransactionsStoreState, BankAccountStoreState } from '@/models'

export default {
  [TRANSACTIONS_STORE_GETTERS.TRANSACTIONS]: (state: TransactionsStoreState) => {
    return state[TRANSACTIONS_STORE_STATE.TRANSACTIONS] || {}
  },
  [TRANSACTIONS_STORE_GETTERS.BANK_ACCOUNT_LIST]: (state: BankAccountStoreState) => {
    return state[TRANSACTIONS_STORE_STATE.BANK_ACCOUNT_LIST] || {}
  }
}
