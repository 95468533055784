import { computed } from 'vue'
import xstore from '@/store'
import { INTEGRATION, RECEIPTS_STORE_ACTIONS, TRIPS_STORE_ACTIONS } from '@/enums'
import { useContext, useActiveDates, useIntegrations, useInstanceHelpers } from '@/composables'

export default function useDownloads() {
  const { t } = useInstanceHelpers()
  const { root } = useContext()
  const { activeDates } = useActiveDates()
  const { activeIntegration } = useIntegrations()

  const companyId = computed(() => root?.$route.params.companyId)

  const downloadableDocTypes = (type: string, isReportView = false): Array<any> => {
    const eInvoiceTitle = activeIntegration.value?.id === INTEGRATION.ZALKTIS ? 'Zalktis eksports' : 'E-invoice'
    const allOptions = [
      { title: 'PDF', value: 'application/pdf', types: ['TRIP', 'RECEIPT', 'ALL'] },
      { title: 'CSV', value: 'text/csv', types: ['TRIP', 'RECEIPT', 'ALL'] },
      { title: t('csvDocumentList'), value: 'text/csv', query: 'displaySimpleFormat', types: ['RECEIPT'] },
      {
        title: 'Excel',
        value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        types: ['TRIP', 'RECEIPT', 'ALL']
      },
      { title: 'ZIP', value: 'application/zip', types: ['RECEIPT', 'ALL'] },
      { title: eInvoiceTitle, eInvoice: true, value: 'application/vnd.cp.einvoice.ee+xml', types: ['RECEIPT', 'ALL'] },
      { title: `${t('nrOfDocuments')} (ZIP)`, value: 'DOWNLOAD_RECEIPTS', types: ['RECEIPT'] }
    ]
    const hideEInvoiceOption = activeIntegration.value?.id === INTEGRATION.ZALKTIS && type === 'RECEIPT' && isReportView
    return allOptions.filter((option) => option.types.includes(type) && !(hideEInvoiceOption && option.eInvoice))
  }

  const downloadPeriodic = (docType: string): Promise<any> | undefined => {
    return xstore.dispatch(RECEIPTS_STORE_ACTIONS.EXPORT_PERIODIC, {
      docType,
      companyId: companyId.value,
      from: activeDates.value?.startDate,
      to: activeDates.value?.endDate
    })
  }

  const downloadTrip = (docType: string, tripId: string | number): Promise<any> | undefined => {
    return xstore.dispatch(TRIPS_STORE_ACTIONS.EXPORT_TRIP, {
      docType,
      companyId: companyId.value,
      tripId
    })
  }

  const downloadReceiptsZIP = (companyId: string | number, receiptIds: Array<any>): Promise<any> | undefined => {
    if (!companyId) return
    return xstore.dispatch(RECEIPTS_STORE_ACTIONS.DOWNLOAD_RECEIPTS, { companyId, receiptIds })
  }

  const exportReceipts = (
    companyId: string | number,
    receiptIds: Array<any>,
    docType: string,
    options: any
  ): Promise<any> | undefined => {
    if (!companyId) return
    return xstore.dispatch(RECEIPTS_STORE_ACTIONS.EXPORT_RECEIPTS, {
      companyId,
      receiptIds,
      docType,
      options
    })
  }

  const downloadReceiptPDF = (companyId: string | number, receiptId: string | number, fileName: string) => {
    if (!companyId || !receiptId) return
    return xstore.dispatch(RECEIPTS_STORE_ACTIONS.DOWNLOAD_RECEIPT_PDF, { companyId, receiptId, fileName })
  }

  const downloadImportSourceFile = (companyId: string | number, receiptId: string | number) => {
    if (!companyId || !receiptId) return
    return xstore.dispatch(RECEIPTS_STORE_ACTIONS.DOWNLOAD_IMPORT_SOURCE_FILE, { companyId, receiptId })
  }

  return {
    downloadableDocTypes,
    downloadPeriodic,
    downloadTrip,
    downloadReceiptsZIP,
    downloadReceiptPDF,
    downloadImportSourceFile,
    exportReceipts
  }
}
