import { ACCOUNTING_STORE_STATE, ACCOUNTING_STORE_MUTATIONS } from '@/enums'
import { AccountingStoreState, AccountingRef, AccountingEntry } from '@/models'

export default {
  [ACCOUNTING_STORE_MUTATIONS.SET_RECEIPT_ACCOUNTING_ENTRIES]: (
    state: AccountingStoreState,
    payload: { accountingEntries: AccountingEntry[]; receiptId: number }
  ) => {
    const { receiptId, accountingEntries } = payload

    if (!receiptId || !accountingEntries?.length) return

    state[ACCOUNTING_STORE_STATE.RECEIPT_ACCOUNTING_ENTRIES][receiptId] = accountingEntries
  },
  [ACCOUNTING_STORE_MUTATIONS.SET_TRIP_ACCOUNTING_ENTRIES]: (
    state: AccountingStoreState,
    payload: { accountingEntries: AccountingEntry[]; tripId: number }
  ) => {
    const { tripId, accountingEntries } = payload

    if (!tripId || !accountingEntries?.length) return

    state[ACCOUNTING_STORE_STATE.TRIP_ACCOUNTING_ENTRIES][tripId] = accountingEntries
  },
  [ACCOUNTING_STORE_MUTATIONS.SET_RECEIPT_ACCOUNTING_REFS]: (
    state: AccountingStoreState,
    payload: { receiptAccountingRefs?: Array<AccountingRef>; receiptId?: string | number } = {}
  ) => {
    const { receiptId, receiptAccountingRefs } = payload

    if (!receiptId || !receiptAccountingRefs) return

    if (!state[ACCOUNTING_STORE_STATE.RECEIPT_ACCOUNTING_REFS][Number(receiptId)]) {
      state[ACCOUNTING_STORE_STATE.RECEIPT_ACCOUNTING_REFS][Number(receiptId)] = {}
    }

    receiptAccountingRefs.forEach((accountingRef) => {
      if (accountingRef.id) {
        state[ACCOUNTING_STORE_STATE.RECEIPT_ACCOUNTING_REFS][Number(receiptId)][accountingRef.id] = accountingRef
      }
    })
  },
  [ACCOUNTING_STORE_MUTATIONS.SET_TRIP_ACCOUNTING_REFS]: (
    state: AccountingStoreState,
    payload: { tripAccountingRefs?: Array<AccountingRef>; tripId?: string | number } = {}
  ) => {
    const { tripId, tripAccountingRefs } = payload

    if (!tripId || !tripAccountingRefs) return

    if (!state[ACCOUNTING_STORE_STATE.TRIP_ACCOUNTING_REFS][Number(tripId)]) {
      state[ACCOUNTING_STORE_STATE.TRIP_ACCOUNTING_REFS][Number(tripId)] = {}
    }

    tripAccountingRefs.forEach((accountingRef) => {
      if (accountingRef.id) {
        state[ACCOUNTING_STORE_STATE.TRIP_ACCOUNTING_REFS][Number(tripId)][accountingRef.id] = accountingRef
      }
    })
  },
  [ACCOUNTING_STORE_MUTATIONS.DELETE_RECEIPT_ACCOUNTING_REF]: (
    state: AccountingStoreState,
    payload: { accountingRefId: string | number; receiptId: string | number }
  ) => {
    const { receiptId, accountingRefId } = payload
    delete state[ACCOUNTING_STORE_STATE.RECEIPT_ACCOUNTING_REFS][Number(receiptId)][Number(accountingRefId)]
  },
  [ACCOUNTING_STORE_MUTATIONS.DELETE_TRIP_ACCOUNTING_REF]: (
    state: AccountingStoreState,
    payload: { accountingRefId: string | number; tripId: string | number }
  ) => {
    const { tripId, accountingRefId } = payload
    delete state[ACCOUNTING_STORE_STATE.TRIP_ACCOUNTING_REFS][Number(tripId)][Number(accountingRefId)]
  }
}
