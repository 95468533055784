<template>
  <transition name="snackbar">
    <div
      v-if="isSnackbarVisible"
      class="snackbar-container"
      :class="snackbarType"
      :style="{ bottom: appearanceHeight }"
      @click="hideSnackbar"
    >
      <div class="text">
      <p class="paragraph-lg-light">{{ snackbarMessage }}</p>
      <icon-close v-if="showCloseIcon" class="close-icon" :fill="snackbarType === 'success' ? '#ffffff' : '#dd0e3e'"></icon-close>
      </div>
      <a v-if="goToLink && linkText" :href="goToLink" target="_blank">{{ linkText }}</a>
    </div>
  </transition>
</template>

<script>
import { ref, onBeforeMount, getCurrentInstance } from 'vue'
import emitter from 'tiny-emitter/instance'
import IconClose from '../icons/IconClose.vue'

export default {
  name: 'UiSnackbar',
  components: {
    IconClose
  },
  setup() {
    const instance = getCurrentInstance()
    const root = instance?.proxy ?? {t:()=>''}

    const isSnackbarVisible = ref(false)
    const snackbarMessage = ref('')
    const snackbarType = ref('success')
    const appearanceHeight = ref('24px')
    const showCloseIcon = ref(false)
    const goToLink = ref(false)
    const linkText = ref(false)
    let timeout = false
    
    onBeforeMount(() => {
      emitter.on('appear', showSnackbar)
    })

    function showSnackbar(message = '', type = '', timeToDisappear = false, bottomOffset = false, options = {}) {
      clearTimeout(timeout)
      const messagePayload = (root?.t && root.t(message) !== '') ? root.t(message) : message
      snackbarMessage.value = messagePayload
      snackbarType.value = type === 'success' || type === 'error' ? type : 'success'
      appearanceHeight.value = bottomOffset ? bottomOffset : '24px'

      if (options.link && options.linkText) {
        goToLink.value = options.link
        linkText.value = options.linkText
      } else {
        goToLink.value = ''
        linkText.value = ''
      }

      if (timeToDisappear) {
        timeout = setTimeout(() => {
          isSnackbarVisible.value = false
        }, timeToDisappear)
        showCloseIcon.value = false
      } else {
        showCloseIcon.value = true
      }

      setTimeout(() => (isSnackbarVisible.value = true), 300)
    }

    function hideSnackbar() {
      isSnackbarVisible.value = false
    }

    return {
      isSnackbarVisible,
      snackbarMessage,
      snackbarType,
      appearanceHeight,
      showCloseIcon,
      goToLink,
      linkText,
      hideSnackbar
    }
  }
}
</script>

<style lang="scss" scoped>


.snackbar-container {
  position: fixed;
  min-height: 56px;
  width: 92.5%;
  max-width: 600px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 16px;
  border-radius: 4px;
  white-space: pre-line;
  z-index: 100000;

  &.error {
    background: $light-red;
    color: $main-red;
  }

  &.success {
    background: $purple-grey;
    color: $white;
  }

  .text {
    display: flex;
    justify-content: space-between;
  }

  .close-icon {
    flex-shrink: 0;
    margin-left: 8px;
  }
}

.snackbar-enter-active {
  animation: showMessage 0.5s forwards;
}

.snackbar-leave-active {
  animation: hideMessage 0.6s ease-in-out;
}

@keyframes showMessage {
  0% {
    transform: translateX(-150%);
  }
  80% {
    transform: translateX(-49%);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes hideMessage {
  0% {
    transform: translateX(-50%);
  }
  20% {
    transform: translateX(-51%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>
