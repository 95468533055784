<template>
  <transition name="notification">
    <div
      v-if="isNotificationVisible"
      class="notification-container"
      :class="notificationType"
      :style="{ bottom: appearanceHeight }"
      @click="hideNotification"
    >
      <div class="text">
        <p class="paragraph-md-regular">{{ notificationMessage }}</p>
        <icon-close
          v-if="showCloseIcon"
          class="close-icon"
          :fill="notificationType === 'success' ? '#ffffff' : '#dd0e3e'"
        ></icon-close>
      </div>
      <a v-if="goToLink && linkText" :href="goToLink" target="_blank">{{ linkText }}</a>
    </div>
  </transition>
</template>

<script>
import { ref, onBeforeMount, getCurrentInstance } from 'vue'
import emitter from 'tiny-emitter/instance'
import IconClose from '../icons/IconClose.vue'

export default {
  name: 'UiNotification',
  components: {
    IconClose
  },
  setup() {
    const instance = getCurrentInstance()
    const root = instance?.proxy ?? {t:()=>''}

    const isNotificationVisible = ref(false)
    const notificationMessage = ref('')
    const notificationType = ref('success')
    const appearanceHeight = ref('24px')
    const showCloseIcon = ref(false)
    const goToLink = ref(false)
    const linkText = ref(false)
    let timeout = false

    onBeforeMount(() => {
      emitter.on('appear', showNotification)
    })

    function showNotification(message = '', type = '', timeToDisappear = false, bottomOffset = false, options = {}) {
      clearTimeout(timeout)
      const messagePayload = (root?.t && root.t(message) !== '') ? root.t(message) : message
      notificationMessage.value = messagePayload
      notificationType.value = type === 'success' || type === 'error' ? type : 'success'
      appearanceHeight.value = bottomOffset ? bottomOffset : '24px'

      if (options.link && options.linkText) {
        goToLink.value = options.link
        linkText.value = options.linkText
      } else {
        goToLink.value = ''
        linkText.value = ''
      }

      showCloseIcon.value = false
      if (timeToDisappear) {
        timeout = setTimeout(() => {
          isNotificationVisible.value = false
        }, timeToDisappear)
      } else {
        showCloseIcon.value = true
      }

      setTimeout(() => (isNotificationVisible.value = true), 300)
    }

    function hideNotification() {
      isNotificationVisible.value = false
    }

    return {
      isNotificationVisible,
      notificationMessage,
      notificationType,
      appearanceHeight,
      showCloseIcon,
      goToLink,
      linkText,
      hideNotification
    }
  }
}
</script>

<style lang="scss" scoped>
.notification-container {
  position: fixed;
  min-height: 40px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  padding: 12px 24px;
  border-radius: 8px;
  box-shadow: $shadow-extended;
  cursor: pointer;
  z-index: 20000;

  &.error {
    background: $light-red;
    color: $main-red;
  }

  &.success {
    background: $purple-grey;
    color: $white;
  }

  .text {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .close-icon {
    flex-shrink: 0;
    margin-left: 32px;
  }
}

.notification-enter-active,
.notification-leave-active {
  transition: all 0.3s;
}

.notification-enter-from,
.notification-leave-to {
  transform: translate(-50%, 100%);
  opacity: 0;
}
</style>
