<template>
  <div :class="{ desktop }">
    <div v-show="open" class="popup-menu">
      <span
        class="select-option"
        v-for="({ value, title }, index) in options"
        :key="index"
        @click.stop="$emit('input', value)"
        >{{ title }}</span
      >
    </div>
    <div class="menu-overlay" v-show="openOverlay" @click.stop="$emit('background-click')"></div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'

export default {
  name: 'UiPopupMenu',
  emits: ['input', 'background-click'],
  props: {
    options: {
      type: [Array],
      required: true
    },
    open: {
      type: Boolean,
      default: false
    },
    desktop: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const openOverlay = ref(false)

    watch(
      () => props.open,
      () => {
        openOverlay.value = props.open
      }
    )

    return {
      openOverlay
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-menu {
  @extend %box-outline;
  position: absolute;
  right: 0;
  top: 100%;

  display: flex;
  flex-direction: column;

  padding: 8px 0;
  background: $white;
  box-shadow: $shadow-extended;

  z-index: 601;
}

.select-option {
  display: block;
  padding: 16px;
  height: 48px;
  width: 100%;

  line-height: 16px;
  font-weight: 400;
  text-align: left;
  white-space: nowrap;

  background: $white;

  cursor: pointer;

  &:hover {
    background: $light-grey;
  }
}

.menu-overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 599;
}

// DESKTOP

.desktop {
  .popup-menu {
    padding: 4px 0;
    top: 120%;
    box-shadow: $shadow-thin;
  }

  .select-option {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 8px 16px;
    font-size: 14px;
    line-height: 12px;
  }
}
</style>
