import { MILEAGE_STORE_GETTERS, MILEAGE_STORE_STATE } from '@/enums'

import { Mileage, MileageItem, MileageStoreState } from '@/models'

export default {
  [MILEAGE_STORE_GETTERS.MILEAGE]: (state: MileageStoreState): Mileage => {
    return state[MILEAGE_STORE_STATE.MILEAGE]
  },
  // [MILEAGE_STORE_GETTERS.MILEAGE_ITEM]: // need to check if it works
  //   (state: MileageStoreState) =>
  //   (id: string | number, companyId: string | number): MileageItem | undefined => {
  //     return state[MILEAGE_STORE_STATE.MILEAGE][companyId]?.[id]
  //   }
}
