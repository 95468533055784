import { ACCOUNTING_STORE_GETTERS, ACCOUNTING_STORE_STATE } from '@/enums'
import { AccountingStoreState } from '@/models'

export default {
  [ACCOUNTING_STORE_GETTERS.ACCOUNTING_OBJECTS]: (state: AccountingStoreState) => {
    return state[ACCOUNTING_STORE_STATE.ACCOUNTING_OBJECTS]
  },
  [ACCOUNTING_STORE_GETTERS.AUTOACCOUNTING_RULES]: (state: AccountingStoreState) => {
    return state[ACCOUNTING_STORE_STATE.AUTOACCOUNTING_RULES]
  },
  [ACCOUNTING_STORE_GETTERS.MANAGED_REFS]: (state: AccountingStoreState) => {
    return state[ACCOUNTING_STORE_STATE.MANAGED_REFS]
  }
}
