import mutations from './approvals.mutations'
import actions from './approvals.actions'
import getters from './approvals.getters'
import state from './approvals.state'

export default {
  mutations,
  actions,
  getters,
  state
}