import Axios from 'axios'
import xstore from '@/store'
import { CONFIG_STORE_GETTERS } from '@/enums'
Axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token
Axios.defaults.withCredentials = true

Axios.interceptors.request.use((config) => {
  config.headers.common['Authorization'] = 'Bearer ' + localStorage.token
  config.withCredentials = true

  try {
    if (config.__ignoreCustomServices) {
      return config
    }

    if (!config.__services) {
      config.__services = Axios.defaults.__services = xstore.getters[CONFIG_STORE_GETTERS.CONFIG]?.services || null
    }

    if (!config.__basenames && config.__services) {
      config.__basenames = Axios.defaults.__basenames = Object.assign({}, ...Object.entries(config.__services || {}).map(([name, {url}]) => ({['@' + name]: url})))
    }

    config.onDownloadProgress = (progressEvent) => handleDownloadPrecent(progressEvent, config.handleOnDownloadProgress)
    config.onUploadProgress = (progressEvent) => handleDownloadPrecent(progressEvent, config.handleOnDownloadProgress)

    if (config.url.startsWith('@') && config.__basenames) {
      config.url = config.url.replace(/^@\w+/, (string) => config.__basenames[string])
    }

    return config
  }

  catch (error) {
    console.log('Axios.interceptors.request - ERROR', {error})
    return config
  }
}, (error) => {
  return Promise.reject(error)
})


export function handleDownloadPrecent ({ loaded, total } = {}, callback = () => {}) {
  const percentCompleted = Math.floor(loaded / total * 100)
  callback(percentCompleted)
}