import Moment from 'moment'
import { DATE_FILTER_SELECTIONS } from '@/enums'

export default function useDateFilters() {
  const dates = {
    firstDayOfPreviousMonth: Moment().subtract(1, 'months').startOf('month'),
    lastDayOfPreviousMonth: Moment().subtract(1, 'months').endOf('month'),
    beforeYesterday: Moment().subtract(2, 'days'),
    startOfThisMonth: Moment().startOf('month'),
    startOfPastMonth: Moment().subtract(1, 'months').startOf('month'),
    startOfThisYear: Moment().startOf('year'),
    endOfThisMonth: Moment().endOf('month'),
    yesterday: Moment().subtract(1, 'days'),
    endOfThisYear: Moment().endOf('year'),
    tomorrow: Moment().add(1, 'days'),
    today: Moment()
  }

  const selectableDates = {
    [DATE_FILTER_SELECTIONS.TODAY]: {
      label: 'today',
      dateLabel: `${dates.today.format('DD.MM')}`,
      selectedDates: {
        start: dates.today,
        end: dates.today,
        addExtraDayForApi: true
      }
    },
    [DATE_FILTER_SELECTIONS.PAST_MONTH]: {
      label: 'pastMonth',
      dateLabel: `${dates.startOfPastMonth.format('DD.MM')} - ${dates.lastDayOfPreviousMonth.format('DD.MM')}`,
      selectedDates: {
        start: dates.startOfPastMonth,
        end: dates.lastDayOfPreviousMonth,
        addExtraDayForApi: true
      }
    },
    [DATE_FILTER_SELECTIONS.ONGOING_MONTH]: {
      label: 'ongoingMonth',
      dateLabel: `${dates.startOfThisMonth.format('DD.MM')} - ${dates.today.format('DD.MM')}`,
      selectedDates: {
        start: dates.startOfThisMonth,
        end: dates.today,
        addExtraDayForApi: true
      }
    },
    [DATE_FILTER_SELECTIONS.FROM_PAST_MONTH]: {
      label: 'fromPastMonth',
      dateLabel: `${dates.startOfPastMonth.format('DD.MM')} - ${dates.today.format('DD.MM')}`,
      selectedDates: {
        start: dates.startOfPastMonth,
        end: dates.today,
        addExtraDayForApi: true
      }
    },
    [DATE_FILTER_SELECTIONS.ONGOING_YEAR]: {
      label: 'ongoingYear',
      dateLabel: `${dates.startOfThisYear.format('DD.MM')} - ${dates.today.format('DD.MM')}`,
      selectedDates: {
        start: dates.startOfThisYear,
        end: dates.today,
        addExtraDayForApi: true
      }
    }
  }

  return {
    selectableDates,
    dates
  }
}
