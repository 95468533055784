module.exports = {
  FI: 51,
  AF: 59,
  AL: 81,
  DZ: 78,
  AD: 63,
  AO: 71,
  AR: 38,
  AM: 61,
  AW: 70,
  AU: 74,
  AT: 80,
  BS: 91,
  BH: 80,
  BD: 57,
  BB: 83,
  BY: 63,
  BE: 77,
  BZ: 52,
  BJ: 47,
  BM: 90,
  BT: 49,
  BO: 48,
  BW: 41,
  BR: 80,
  BG: 64,
  BF: 40,
  BI: 46,
  KH: 67,
  CM: 59,
  CA: 82,
  CV: 45,
  CF: 101,
  TD: 47,
  CL: 56,
  CN: 74,
  CO: 64,
  KM: 42,
  CK: 70,
  CR: 65,
  HR: 69,
  CU: 68,
  CW: 58,
  CY: 65,
  CZ: 89,
  DK: 79,
  DJ: 83,
  DM: 61,
  DO: 53,
  EC: 63,
  EG: 66,
  SV: 60,
  ER: 95,
  EE: 75,
  ET: 49,
  FO: 61,
  FJ: 52,
  FR: 78,
  GA: 92,
  GM: 46,
  GE: 49,
  DE: 76,
  GH: 47,
  GR: 68,
  GL: 63,
  GD: 73,
  GP: 53,
  GT: 76,
  GN: 83,
  GW: 41,
  GY: 51,
  HT: 62,
  HN: 58,
  HU: 69,
  IS: 92,
  IN: 62,
  ID: 57,
  IR: 102,
  IQ: 70,
  IE: 78,
  IL: 88,
  IT: 76,
  JM: 62,
  JP: 66,
  KZ: 59,
  KE: 70,
  XK: 58,
  KW: 84,
  LV: 73,
  LB: 102,
  LS: 34,
  LR: 60,
  LY: 52,
  LI: 79,
  LT: 72,
  LU: 77,
  MG: 45,
  MW: 77,
  MY: 50,
  MV: 68,
  ML: 47,
  MT: 71,
  MH: 65,
  MQ: 55,
  MR: 52,
  MU: 53,
  MX: 81,
  FM: 59,
  MD: 73,
  MC: 92,
  MN: 42,
  ME: 66,
  MA: 71,
  MZ: 53,
  NA: 36,
  NP: 51,
  NL: 83,
  NZ: 74,
  NI: 51,
  NE: 50,
  NG: 78,
  NO: 70,
  OM: 74,
  PK: 29,
  PW: 99,
  PA: 61,
  PG: 76,
  PY: 36,
  PE: 52,
  PH: 69,
  PL: 72,
  PT: 70,
  PR: 70,
  QA: 78,
  RO: 68,
  RW: 37,
  KN: 68,
  LC: 86,
  WS: 61,
  SM: 59,
  ST: 102,
  SA: 80,
  SN: 58,
  RS: 75,
  SC: 87,
  SL: 47,
  SG: 79,
  SK: 79,
  SI: 72,
  SB: 63,
  SO: 86,
  ZA: 50,
  SS: 102,
  ES: 74,
  LK: 29,
  SD: 83,
  SR: 78,
  SE: 64,
  CH: 93,
  TW: 69,
  TZ: 54,
  TH: 63,
  TG: 58,
  TO: 62,
  TT: 83,
  TN: 61,
  TR: 35,
  TM: 92,
  UG: 49,
  UA: 64,
  AE: 73,
  GB: 79,
  US: 89,
  UY: 59,
  UZ: 32,
  VU: 70,
  VE: 102,
  VN: 69,
  YE: 102,
  ZW: 102,
  ZM: 55,
  AG: 94,
  AZ: 70,
  BA: 54,
  BN: 45,
  CD: 51,
  CG: 64,
  CI: 80,
  TL: 46,
  SZ: 37,
  JO: 90,
  KP: 70,
  KR: 87,
  KG: 41,
  LA: 32,
  MM: 58,
  MK: 64,
  PS: 76,
  RU: 66,
  SY: 91,
  TJ: 35,
  HK: 86,
  VC: 85,
  'PT, Azores': 69,
  'ES, Canary Islands': 71,
  'PT, Madeira': 68,
  'RU, Moscow': 82,
  'RU, Saint Petersburg': 76,
  'TR, Istanbul': 37,
  'GB, London': 83,
  'GB, Edinburgh': 83,
  'US, New York': 97,
  'US, Los Angeles': 97,
  'US, Washington': 97,
  'US, Virgin Islands': 64,
}