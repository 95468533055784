<template>
  <div class="ui-input-wrapper" :class="{ desktop }">
    <input
      :id="inputIndex ? `inputField${inputIndex}` : ''"
      ref="inputField"
      class="input-field"
      :class="{
        'has-value': localValue !== '' && localValue !== null && localValue !== undefined,
        'has-floating-label': floatingLabel,
        'not-valid': !isValid,
        'is-highlighted': isHighlighted
      }"
      :type="inputType"
      :name="name"
      :disabled="isDisabled"
      :placeholder="floatingLabel ? '' : placeholder"
      :min="min"
      :max="max"
      :step="step"
      :pattern="pattern"
      v-model="localValue"
      @keyup.enter="$emit('entered', $event)"
      @focus="handleFocus(true, $event)"
      @blur="handleFocus(false, $event)"
      @keydown="onKeydown($event)"
      @paste="onPaste($event)"
      v-sanitize
    />
    <label v-if="floatingLabel" class="floating-label ellipsis">{{ placeholder }}</label>
    <div v-if="type === 'password'" class="password-toggle" @click="togglePasswordVisibility()">
      <icon-eye height="24px" width="24px" :fill="iconColor"></icon-eye>
    </div>

    <div v-if="autocomplete && isFocused && localAutocompleteOptions.length" class="input-autocomplete-container">
      <div
        class="input-autocomplete-option paragraph-sm-regular"
        :class="{ focused: focusedOption === option }"
        v-for="(option, index) in localAutocompleteOptions"
        :key="option + index"
        @click="selectAutocomplete(option)"
      >
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch, nextTick } from 'vue'

export default {
  name: 'UiInput',
  emits: ['update:modelValue', 'entered', 'focused', 'blurred'],
  props: {
    modelValue: {
      type: [String, Number, Object]
    },
    placeholder: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    isHighlighted: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isValid: {
      type: Boolean,
      default: true
    },
    onlyNumbers: {
      type: Boolean,
      default: false
    },
    floatingLabel: {
      type: Boolean,
      default: false
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    min: {},
    max: {},
    maxLength: {},
    pattern: {
      type: String,
      default: ''
    },
    step: {
      type: String
    },
    desktop: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: Boolean,
      default: false
    },
    filtered: {
      type: Boolean,
      default: false
    },
    autocompleteOptions: {
      type: Array,
      default: () => []
    },
    inputIndex: {
      type: Number
    },
    selectionRange: {
      type: Number,
      default: 0
    }
  },
  setup(props, { emit }) {
    const ENTER_KEY_CODE = 13
    const DOWN_ARROW_KEY_CODE = 40
    const UP_ARROW_KEY_CODE = 38
    const ALLOWED_KEY_CODES = [8, 9, 13, 16, 27, 37, 38, 39, 40, 46]

    const inputField = ref(null)
    const inputType = ref('')
    const _value = ref(props.modelValue)
    const isFocused = ref(false)
    const focusedOption = ref('')

    const localValue = computed({
      get: () => _value.value,
      set: (newValue) => {
        _value.value = newValue
        emit('update:modelValue', newValue)
      }
    })

    const iconColor = computed(() => (inputType.value === 'password' ? '#e3e3e4' : '#484e70'))
    const allowOnlyNumbers = computed(() => props.onlyNumbers && window.X_PLATFORM !== 'Android')
    // Dont activate onlynumbers for Android, because Android anyway has a number keyboard

    const localAutocompleteOptions = computed(() => {
      if (!_value.value) {
        return []
      }
      if (!props.filtered) {
        return [...props.autocompleteOptions]
      }
      return [...props.autocompleteOptions]
        .filter(
          (option) =>
            option.toLowerCase().includes(_value.value?.toLowerCase()) &&
            option.toLowerCase() !== _value.value.toLowerCase()
        )
        .slice(0, 5)
    })

    onMounted(() => {
      if (props.autoFocus) {
        inputField.value?.focus()

        // Set the focusing selection range if it is provided
        if (props.selectionRange !== 0) {
          // Temporarily change input type to 'text' to allow setSelectionRange
          inputType.value = 'text'
          inputField.value.setSelectionRange(_value.value.length - props.selectionRange, _value.value.length)
        }
      }

      if (props.type) {
        if (allowOnlyNumbers.value) {
          // Set input to text in order to determine where the caret is located
          inputType.value = 'text'
        } else {
          inputType.value = props.type
        }
      }
    })

    watch(
      () => props.modelValue,
      () => {
        _value.value = props.modelValue
      }
    )

    watch(
      () => props.type,
      () => {
        inputType.value = props.type
      }
    )

    function onKeydown(e) {
      const keyCode = e.which || e.keyCode
      const selection = window.getSelection()
      const isSelected = selection && selection.type === 'Range'
      const isAllowedKey = ALLOWED_KEY_CODES.includes(keyCode)
      const targetValue = String(e.target.value)

      if (props.maxLength) {
        const overMaxLength = Number(props.maxLength) && targetValue.length >= Number(props.maxLength)
        if (overMaxLength && !isAllowedKey && !isSelected) {
          return e.preventDefault()
        }
      }

      if (allowOnlyNumbers.value && !e.ctrlKey) {
        const isNumeric = !/[^0-9.,-]/.test(e.key)
        const isDot = e.key === '.'
        const isComma = e.key === ','
        const includesDot = targetValue?.includes('.')

        if (isComma && !includesDot) {
          e.preventDefault()
          const startIndex = e.target.selectionStart

          // Replace comma with dot
          localValue.value = targetValue.slice(0, startIndex) + '.' + targetValue.slice(startIndex)

          // Use nextTick because value change and setSelectionRange dont work together
          nextTick(() => {
            // Put the caret back where it was
            inputField.value.setSelectionRange(startIndex + 1, startIndex + 1)
          })
        } else if (!isAllowedKey && (!isNumeric || (includesDot && (isDot || isComma)))) {
          // If not a number or already has a dot, then dont enter anything
          e.preventDefault()
        }
      }
    }

    function onPaste(e) {
      let paste = (e.clipboardData || window.clipboardData).getData('text')

      if (props.onlyNumbers) {
        paste = findNumberFromString(paste)
      }

      if (Number(props.maxLength)) {
        paste = paste.slice(0, Number(props.maxLength))
      }

      e.preventDefault()
      localValue.value = paste
    }

    function findNumberFromString(paste) {
      // Remove all non-numeric characters except dot, comma and dash
      let result = paste.replace(/[^0-9.,-]/g, '')

      const dotIndex = result.indexOf('.')
      // Firstly try to find dot
      if (dotIndex !== -1) {
        // Ensure only one dot
        result = result.slice(0, dotIndex + 1).replace(/,/g, '') + result.slice(dotIndex + 1).replace(/[.,]/g, '')
      } else {
        // if no dot, then try to find comma
        const commaIndex = result.indexOf(',')
        if (commaIndex !== -1) {
          // Ensure only one comma, change to dot
          result = result.slice(0, commaIndex + 1).replace(/,/g, '.') + result.slice(commaIndex + 1).replace(/,/g, '')
        }
      }

      return result
    }

    function focusNextListItem(direction) {
      let nextActiveElementIndex = localAutocompleteOptions.value.indexOf(focusedOption.value)

      // Select next option
      if (nextActiveElementIndex === -1) {
        nextActiveElementIndex = 0
      } else if (direction === DOWN_ARROW_KEY_CODE) {
        nextActiveElementIndex += 1
      } else if (direction === UP_ARROW_KEY_CODE) {
        nextActiveElementIndex -= 1
      }

      // Go from first to last and last to first option
      nextActiveElementIndex =
        (nextActiveElementIndex + localAutocompleteOptions.value.length) % localAutocompleteOptions.value.length
      focusedOption.value = localAutocompleteOptions.value[nextActiveElementIndex]
    }

    function onKeyup(event) {
      switch (event.keyCode) {
        case ENTER_KEY_CODE:
          selectAutocomplete(focusedOption.value)
          return
        case DOWN_ARROW_KEY_CODE:
          focusNextListItem(DOWN_ARROW_KEY_CODE)
          return
        case UP_ARROW_KEY_CODE:
          focusNextListItem(UP_ARROW_KEY_CODE)
          return
        default:
          return
      }
    }

    function addListener(shouldListen) {
      if (shouldListen) {
        window.addEventListener('keyup', onKeyup, true)
        return
      }
      window.removeEventListener('keyup', onKeyup, true)
    }

    function handleFocus(shouldFocus, event) {
      if (shouldFocus) {
        isFocused.value = true
        emit('focused')
        addListener(true)
      } else if (!props.autocomplete) {
        isFocused.value = false
        addListener(false)
        emit('blurred', event)
      } else {
        setTimeout(() => {
          isFocused.value = false
          addListener(false)
          emit('blurred', event)
        }, 300)
      }
    }

    function selectAutocomplete(option) {
      if (option) {
        localValue.value = option
      }
    }

    function togglePasswordVisibility() {
      if (props.type !== 'password') return

      if (inputType.value === 'password') {
        inputType.value = ''
      } else {
        inputType.value = 'password'
      }
    }

    return {
      localValue,
      inputField,
      isFocused,
      onKeydown,
      onPaste,
      handleFocus,
      selectAutocomplete,
      focusedOption,
      inputType,
      iconColor,
      togglePasswordVisibility,
      localAutocompleteOptions
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-input-wrapper {
  position: relative;
  display: flex;
  width: inherit;
  min-width: 0;
}

.input-field {
  @extend %box-text;
  @extend %box-outline;
  position: relative;
  height: 56px;
  width: 100%;
  padding: 16px;
  color: $grey;
  caret-color: $main-blue;
  transition: border 150ms ease-out;
  transition: background-color 250ms ease-in-out;

  &.is-highlighted {
    border: 1.5px solid $main-yellow;
  }

  &:enabled:hover {
    border: 1.5px solid $grey;
  }

  &:enabled:focus {
    border: 2px solid $main-blue;
    color: $medium-dark-grey;
  }

  &:disabled {
    background: $light-grey;
  }

  &.has-value {
    color: $medium-dark-grey;
  }

  &.has-value:disabled {
    opacity: 1;
    color: $grey;
    -webkit-text-fill-color: $grey;
  }

  &.has-floating-label {
    padding: 24px 16px 8px;
  }

  &.not-valid {
    border: 2px solid $main-red !important;
  }
}

.password-toggle {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 56px;
  cursor: pointer;
}

::placeholder {
  color: $grey;
}

::-ms-input-placeholder {
  color: $grey;
}

.floating-label {
  @extend %box-text;
  font-weight: 400;
  width: 90%;
  position: absolute;
  left: 17px;
  top: 16px;
  text-align: left;
  color: $grey;
  pointer-events: none;
  transition: all 200ms ease;
}

input:focus + .floating-label,
input.has-value + .floating-label,
input[type='date'] + .floating-label,
input[type='time'] + .floating-label {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  transform: translateY(-8px);
}

input[type='date']::-webkit-calendar-picker-indicator,
input[type='time']::-webkit-calendar-picker-indicator {
  opacity: 0.5;
}

input[type='date']::-webkit-inner-spin-button,
input[type='time']::-webkit-inner-spin-button {
  display: none;
}

input[type='date']::-webkit-datetime-edit input[type='time']::-webkit-datetime-edit {
  border: none;
  color: $medium-dark-grey;
  background-color: $white;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

input[type='date'],
input[type='time'] {
  background-color: $white;
  -webkit-min-logical-width: calc(100% - 16px);
}

// DESKTOP VIEW
.desktop {
  .input-field {
    font-size: 12px;
    height: 40px;
    padding: 8px 12px;
  }

  input[type='date'],
  input[type='time'] {
    min-height: 40px;
  }
}

.input-autocomplete-container {
  @extend %box-outline;
  position: absolute;
  top: 120%;
  background-color: rgba(42, 46, 65, 0.9);
  padding: 8px 0;
  width: 100%;
  color: $white;
  box-shadow: $shadow-extended;
  z-index: 1;
  max-height: 200px;
  overflow-y: scroll;
  @include scrollbar;
}

.input-autocomplete-option {
  padding: 8px 16px;
  cursor: pointer;

  &:hover,
  &.focused {
    background: $grey;
  }
}
</style>
