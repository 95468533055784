import { INTEGRATIONS_STORE_MUTATIONS, INTEGRATIONS_STORE_ACTIONS } from '@/enums'
import errorHandler from '@/lib/errorHandler'
import axios from 'axios'

export default {
  [INTEGRATIONS_STORE_ACTIONS.GET_ACTIVE_INTEGRATION]: async ({ commit }: any, id: string | number) => {
    try {
      const { data: integration = {} } = await axios.get(`@api/integrations/v2/accounting/${id}/active`)
      commit(INTEGRATIONS_STORE_MUTATIONS.SET_ACTIVE_INTEGRATION, integration)
      return integration
    } catch (error: any) {
      errorHandler(INTEGRATIONS_STORE_ACTIONS.GET_ACTIVE_INTEGRATION, error)
    }
  },
  [INTEGRATIONS_STORE_ACTIONS.CHECK_SERVICE_ACTIVATION_STATE]: async ({ commit }: any, id: string | number) => {
    const { data = [] } = await axios.get(`@api/integrations/v1/accounting/${id}/check-service-activation-state`)
    if (data[0]) {
      const { state = '', code = '', partnerId = '' } = data[0]
      commit(INTEGRATIONS_STORE_MUTATIONS.SET_SERVICE_ACTIVATION, { state, code, partnerId })
    }
  },
  [INTEGRATIONS_STORE_ACTIONS.CONFIRM_SERVICE_ACTIVATION]: async ({ commit }: any, payload: any) => {
    const { companyId, code, accepted } = payload
    const encodedCode = encodeURIComponent(code)
    return axios
      .get(`@api/integrations/v1/accounting/${companyId}/confirm-service-activation?code=${encodedCode}&accepted=${accepted}`)
      .then(() => {
        if (accepted) {
          commit(INTEGRATIONS_STORE_MUTATIONS.SET_SERVICE_ACTIVATION, {})
        }
      })
  },
}
