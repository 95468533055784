<template>
  <div>
    <div class="image-preview">
      <div v-if="noFile" class="picture-placeholder">
        <div>
          <icon-receipt fill="#ffffff" height="56px" width="56px"></icon-receipt>
          <h4 class="m-t-4">{{ t('noPicture') }}</h4>
        </div>
      </div>
      <img v-else :src="imageSrc" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiImagePreview',
  props: ['imageSrc', 'noFile']
}
</script>

<style lang="scss" scoped>
// IMAGE PREVIEW
.image-preview {
  border-radius: 8px;
  position: fixed;
  bottom: 16px;
  right: 16px;
  background: $medium-light-grey;
  box-shadow: $shadow-extended;
  z-index: $window-layer;

  .picture-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    width: 28vw;
    background: $medium-light-grey;
    color: $white;
    text-align: center;
  }

  img {
    display: block;
    max-height: 70vh;
    width: auto;
    border-radius: 8px;
  }
}
</style>
