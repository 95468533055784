import mutations from './companies.mutations'
import actions from './companies.actions'
import getters from './companies.getters'
import state from './companies.state'

export default {
  mutations,
  actions,
  getters,
  state
}
