import Axios from 'axios'
import { TRANSACTIONS_STORE_ACTIONS, TRANSACTIONS_STORE_MUTATIONS } from '@/enums'

export default {
  [TRANSACTIONS_STORE_ACTIONS.GET_TRANSACTIONS]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId } = payload

      const { data: transactions = [] } = await Axios.get(`@coreapi/companies/${companyId}/payment-transactions`)

      commit(TRANSACTIONS_STORE_MUTATIONS.SET_TRANSACTIONS, { transactions, companyId })

      return transactions
    } catch (error) {
      throw error
    }
  },
  [TRANSACTIONS_STORE_ACTIONS.UPDATE_TRANSACTION]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId, transactionId, values } = payload

      const { data: transaction = {} } = await Axios.put(
        `@coreapi/companies/${companyId}/payment-transactions/${transactionId}`,
        values
      )

      commit(TRANSACTIONS_STORE_MUTATIONS.UPDATE_TRANSACTION, { transaction, companyId })

      return transaction
    } catch (error) {
      throw error
    }
  },
  [TRANSACTIONS_STORE_ACTIONS.DELETE_TRANSACTION]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId, transactionId } = payload

      await Axios.delete(`@coreapi/companies/${companyId}/payment-transactions/${transactionId}`)

      commit(TRANSACTIONS_STORE_MUTATIONS.DELETE_TRANSACTION, { transactionId, companyId })

      return true
    } catch (error) {
      throw error
    }
  },
  [TRANSACTIONS_STORE_ACTIONS.AUTOGENERATE_REPORTS]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId } = payload

      const { data: transactions = [] } = await Axios.get(`@coreapi/companies/${companyId}/card-reports/autogenerate`)

      commit(TRANSACTIONS_STORE_MUTATIONS.SET_TRANSACTIONS, { transactions, companyId })

      return transactions
    } catch (error) {
      throw error
    }
  },
  [TRANSACTIONS_STORE_ACTIONS.IMPORT_TRANSACTIONS_FROM_FILE]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId, formData } = payload

      const { data: transactions = [] } = await Axios.post(
        `@coreapi/companies/${companyId}/payment-transactions/statement`,
        formData
      )

      commit(TRANSACTIONS_STORE_MUTATIONS.SET_TRANSACTIONS, { transactions, companyId })

      return transactions
    } catch (error) {
      throw error
    }
  },
  [TRANSACTIONS_STORE_ACTIONS.GET_BANK_ACCOUNT_LIST]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId } = payload

      let { data: bankAccounts = [] } = await Axios.get(`@api/integrations/v2/banking/${companyId}/accounts`)

      commit(TRANSACTIONS_STORE_MUTATIONS.SET_BANK_ACCOUNT_LIST, { bankAccounts, companyId })

      return bankAccounts
    } catch (error) {
      throw error
    }
  }
}
