
const Estonia = require('./countries/estonia')
, Finland = require('./countries/finland')
, Latvia = require('./countries/latvia')
, Sweden = require('./countries/sweden')
, Lithuania = require('./countries/lithuania')
, Poland = require('./countries/poland')
, moment = require('moment')
, _ = require('lodash-es')

module.exports = {
calculate: function (trip, trips, country) {
  // ERROR IF MISSING TRIP, TRIPS OR COUNTRY
  if (!trip || !trips || !country) {
    return { error: 'trip, trips or country missing', errorCode: 'tripTripsOrCountryMissing' }
  }
  // VALIDATE STARTDATE
  if (!moment(trip.startDate, 'YYYY-MM-DD').isValid()) {
    return { error: 'Start date not valid', errorCode: 'startDateNotValid' }
  }
  // VALIDATE ENDDATE
  if (!moment(trip.endDate, 'YYYY-MM-DD').isValid()) {
    return { error: 'End date not valid', errorCode: 'endDateNotValid' }
  }
  // ERROR IF END IS BEFORE BEGINNING
  if (moment(trip.endDate).isBefore(trip.startDate)) {
    return { error: 'End is before beginning', errorCode: 'endIsBeforeBeginning' }
  }
  // ONLY CALCULATE WITH TRAVEL REPORTS, CAN COME AS ARRAY OR OBJECT
  let onlyTravelReports = []
  _.each(trips, function (oneTrip) {
    if (oneTrip.type === 'travel') {
      onlyTravelReports.push(oneTrip)
    }
  })
  // REMOVE IF SAME TRIP IS EDITED
  onlyTravelReports = onlyTravelReports.filter(function (oneTrip) {
    // IN CASE NO TRIP.ID IS SET, THIS CAN'T BE EDIT
    if (!trip.id) return oneTrip
    // IF TRIP.ID IS SET, MAKE SURE IT DOES NOT MATCH
    else return Number(oneTrip.id) !== Number(trip.id)
  })
  // RETURN ONLY ACTIVE
  onlyTravelReports = onlyTravelReports.filter(function (oneTrip) {
    // RETURN ONLY IF ALLOWANCE IS TRUE
    return oneTrip.includesDailyAllowance
  })

  if (country === 'EE') {
    return trip = Estonia(trip, onlyTravelReports)
  } else if (country === 'FI') {
    return trip = Finland(trip, onlyTravelReports)
  } else if (country === 'LV') {
    return trip = Latvia(trip, onlyTravelReports)
  } else if (country === 'SE') {
    return trip = Sweden(trip, onlyTravelReports)
  } else if (country === 'LT') {
    return trip = Lithuania(trip, onlyTravelReports)
  } else if (country === 'PL') {
    return trip = Poland(trip, onlyTravelReports)
  } else {
    return { error: 'Country not supported', errorCode: 'countryNotSupported' }
  }
}
}
