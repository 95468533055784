let _ = require('lodash-es'),
  moment = require('moment')

module.exports = function(tripInput, tripsInput) {
  // THIS FUNCTION RETURNS allowanceSum AND allowanceDays
  let allowanceSum = 0
  let allowanceDays = 0
  let warning = false
  let overlappingDates = {}
  // DEFAULT DAILY ALLOWANCE IN ESTONIA
  let biggerAllowance = 50
  let smallerAllowance = 32 // daily allowance after 15 days
  let maxAllowance = false
  // IF MAX ALLOWANCE SET
  if (tripInput.extraData && tripInput.extraData.maxAllowance) {
    maxAllowance = tripInput.extraData.maxAllowance
    // WORK WITH biggerAllowance
    // MAKE 50 MAXIMUM VALUE FOR maxAllowance
    if (maxAllowance > biggerAllowance) {
      maxAllowance = biggerAllowance
    } else {
      // IF UNDER 50, OVERWRITE biggerAllowance
      biggerAllowance = Number(maxAllowance)
    }
    // WORK WITH smallerAllowance
    // IF MAX IS SMALLER THEN smallerAllowance
    if (maxAllowance < smallerAllowance) {
      // OVERWRITE smallerAllowance
      smallerAllowance = maxAllowance
    } else {
      // DO NOT OVERWRITE 32, IF maxAllowance IS OVER 32
    }
  }

  // CREATE CALENDAROBJECTS FROM EXISTING TRIPS AND THE NEW TRIP
  let oldTrips = createTripCalendar(tripsInput) // {'2018-09': {'01': true, '02': true}}
  let newTrip = createTripCalendar([tripInput]) // {'2018-09': {'01': true, '02': true}}
  // ITERATE OVER EACH MONTH IN NEW TRIP
  _.each(newTrip, function(days, month) {
    // GET TOTAL NUMBER OF DAYS FROM THIS MONTH IN THIS TRIP
    let applicableDays = _.size(days)
    // IF OTHER TRIPS ARE IN THE SAME MONTH
    if (oldTrips[month]) {
      // WALK OVER ALL DAYS IN THE TRIP (FROM THIS MONTH)
      _.each(days, function(val, day) {
        if (oldTrips[month][day]) {
          // IF DATE IS USED BY OTHER TRIP, REMOVE ONE DAY FROM applicableDays
          applicableDays--
          // SHOW USER WARNING ABOUT IT
          warning = true
          if (!overlappingDates[month]) overlappingDates[month] = []
          overlappingDates[month].push(day.replace(/^0+(?!$)/, ''))
        }
      })
    }
    // CALCULATE HOW MANY 50 EUR DAYS ARE AVAILABLE THIS MONTH
    // HOW FAR IS BEGINNING OF THIS MONTH FROM BEGINNING OF TRIP?
    const beginningOfThisMonth = moment(month, 'YYYY-MM').startOf('month')
    const beginningOfTrip = moment(tripInput.startDate, 'YYYY-MM-DD')
    let availableForThisTrip = 15
    // IF TRIP BEGAN BEFORE THIS MONTH COUNT HOW MANY 15 EUR DAYS ARE USED
    if (beginningOfTrip < beginningOfThisMonth) {
      // DIFFERENCE BETWEEN BEGINNING OF MONTH
      let difference = beginningOfThisMonth.diff(beginningOfTrip, 'days')
      // SUBRACT THE DIFFERENCE FROM 50 EUR DAYS
      availableForThisTrip = 15 - difference
    }
    const thisMonthFiftyDaysAvailable = 15 - _.size(oldTrips[month])
    if (availableForThisTrip >= thisMonthFiftyDaysAvailable) {
      availableForThisTrip = thisMonthFiftyDaysAvailable
    }
    let fiftyEuroDays = availableForThisTrip
    // GO OVER EACH DAY IN THE NEW TRIP AND ADD 50 OR 32 EUR ACCORDING IF 15 DAYS ARE OVER
    let i
    for (i = 0; i < applicableDays; i++) {
      if (tripInput.destination === 'EE') {
        allowanceSum = 0
      } else if (fiftyEuroDays > 0) {
        allowanceSum = Number(Number(allowanceSum) + Number(biggerAllowance))
        if (!Number.isInteger(allowanceSum)) {
          allowanceSum = allowanceSum.toFixed(2)
        }
        fiftyEuroDays--
      } else {
        allowanceSum = Number(Number(allowanceSum) + Number(smallerAllowance))
        // IF CONTAINS COMMAS, ROUND TO 2 AFTER COMMA
        if (!Number.isInteger(allowanceSum)) {
          allowanceSum = allowanceSum.toFixed(2)
        }
      }
    }
    allowanceDays = allowanceDays + applicableDays
  })

  //SORT OVERLAPPING DATES IN ASCENDING ORDER
  _.each(overlappingDates, (_, month) => {
    overlappingDates[month].sort((a, b) => a - b)
  })

  let payload = {
    days: allowanceDays,
    sum: allowanceSum,
    warning,
    overlappingDates
  }
  if (maxAllowance) {
    payload.maxAllowance = maxAllowance
  }
  return payload
}

function createTripCalendar(trips) {
  let tripCalendar = {}
  // WALK THROUGH ALL AVAILABLE TRIPS
  _.each(trips, function(trip) {
    // AND MARK DAYS IN CALENDAR WHERE EACH IS ACTIVE
    let startDate = moment(trip.startDate, 'YYYY-MM-DD')
    let endDate = moment(trip.endDate, 'YYYY-MM-DD')
    // IF DEPARTED AFTER 22 OR ARRIVED BEFORE 03
    if (trip.extraData) {
      // IF DEPARTED AFTER 22, JUST START FROM THE NEXT DAY
      if (trip.extraData.departAfter22 === true) {
        startDate.add(1, 'days')
      }
      // IF ARRIVED (NOT DEPARTED THOUGH) BEFORE THREE, JUST END WITH THE LAST DAY
      if (trip.extraData.departBefore03 === true) {
        endDate.subtract(1, 'days')
      }
    }
    // RETURNS NUMBER OF DAYS OF TRIP AND 1 IF SAME DAY
    let totalDaysForCalculation = endDate.diff(startDate, 'days') + 1
    // CREATE ARRAY WITH ALL ACTIVE DAYS USED IN TRIPS
    let i
    let daysInTrip = []
    for (i = 0; i < totalDaysForCalculation; i++) {
      daysInTrip.push(startDate.format('YYYY-MM-DD').toString())
      startDate.add(1, 'days')
    }
    // TRANSFER daysInTrip RESULTS TO tripCalendar
    _.each(daysInTrip, function(oneDay) {
      var day = moment(oneDay, 'YYYY-MM-DD')
        .format('DD')
        .toString()
      var month = moment(oneDay, 'YYYY-MM-DD')
        .format('YYYY-MM')
        .toString()
      if (!tripCalendar[month]) tripCalendar[month] = {}
      tripCalendar[month][day] = true
    })
  })
  return tripCalendar
}
