import { SETTING_STORE_ACTIONS, SETTING_STORE_MUTATIONS } from '@/enums'

import { ActiveDates } from '@/models'

export default {
  [SETTING_STORE_ACTIONS.UPDATE_ACTIVE_DATES]: ({ commit }: any, { startDate, endDate }: ActiveDates) => {
    commit(SETTING_STORE_MUTATIONS.SET_ACTIVE_DATES, { startDate, endDate })
    return
  },
  [SETTING_STORE_ACTIONS.UPDATE_LOADING_STATE]: ({ commit }: any, isLoading: Boolean) => {
    commit(SETTING_STORE_MUTATIONS.SET_LOADING_STATE, isLoading)
    return
  },
  [SETTING_STORE_ACTIONS.UPDATE_SELECT_RECEIPTS_BY]: ({ commit }: any, criteria: string) => {
    commit(SETTING_STORE_MUTATIONS.SET_SELECT_RECEIPTS_BY, criteria)
    return
  },
  [SETTING_STORE_ACTIONS.UPDATE_SELECT_TRIPS_BY]: ({ commit }: any, criteria: string) => {
    commit(SETTING_STORE_MUTATIONS.SET_SELECT_TRIPS_BY, criteria)
    return
  }
}
