import {
  ComponentInternalInstance,
  getCurrentInstance,
} from 'vue'

export default function useInstanceHelpers () {
  const instance: ComponentInternalInstance | null = getCurrentInstance()
  const t = instance?.appContext?.config?.globalProperties?.t ?? ((s: string) => s)

  return {
    t
  }
}