import { createVuexStoreRoot } from '@/lib/vuex-types/root'

export const root = createVuexStoreRoot({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  }
})
