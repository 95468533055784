import { RECEIPTS_STORE_GETTERS, RECEIPTS_STORE_STATE } from '@/enums'
import { ReceiptsStoreState, Receipt, Receipts } from '@/models'

export default {
  [RECEIPTS_STORE_GETTERS.RECEIPTS]: (state: ReceiptsStoreState): Receipts => {
    return state[RECEIPTS_STORE_STATE.RECEIPTS] || {}
  },
  [RECEIPTS_STORE_GETTERS.SELECTED_RECEIPTS]: (state: ReceiptsStoreState): Array<number> => {
    return state[RECEIPTS_STORE_STATE.SELECTED_RECEIPTS]
  },
  [RECEIPTS_STORE_GETTERS.RECEIPTS_COUNT]: (state: ReceiptsStoreState): number => {
    const receipts = state[RECEIPTS_STORE_STATE.RECEIPTS] || {}
    return Object.values(receipts).length
  },
  [RECEIPTS_STORE_GETTERS.RECEIPT_BY_ID]:
    (state: ReceiptsStoreState) =>
    (id: number): Receipt | undefined => {
      return state[RECEIPTS_STORE_STATE.RECEIPTS][id]
    },
  [RECEIPTS_STORE_GETTERS.RECEIPT_DUPLICATES]:
    (state: ReceiptsStoreState) =>
    (receiptId: number): Array<any> => {
      return state[RECEIPTS_STORE_STATE.RECEIPT_DUPLICATES][receiptId]
    },
  [RECEIPTS_STORE_GETTERS.RECEIPT_BROWSING_LIST]: (state: ReceiptsStoreState): (string | number)[] => {
    return state[RECEIPTS_STORE_STATE.RECEIPT_BROWSING_LIST]
  }
}
