import Axios from 'axios'
import { APPROVAL_STATE, APPROVALS_STORE_ACTIONS, APPROVALS_STORE_MUTATIONS } from '@/enums'
import { ApprovalItem } from '@/models'

export default {
  [APPROVALS_STORE_ACTIONS.GET_ALL_APPROVALS]: async (
    { commit }: any,
    payload: { companyId: string | number; isAdmin: boolean; statuses?: APPROVAL_STATE[] }
  ): Promise<ApprovalItem[]> => {
    try {
      const { companyId, isAdmin, statuses = [] } = payload

      let url = `@coreapi/companies/${companyId}/approvals${isAdmin ? '/all' : ''}?include_mileage=true`

      if (statuses.length) {
        url = `${url}&state=${statuses.join(',')}`
      }

      const { data: approvals = [] } = await Axios.get(url)

      commit(APPROVALS_STORE_MUTATIONS.SET_APPROVALS, { companyId, approvals })

      return approvals
    } catch (error) {
      throw error
    }
  },
  [APPROVALS_STORE_ACTIONS.GET_APPROVAL]: async (
    { commit }: any,
    payload: { companyId: string | number; approvalId: string | number }
  ): Promise<ApprovalItem[]> => {
    try {
      const { companyId, approvalId } = payload

      const { data: approval = {} } = await Axios.get(
        `@coreapi/companies/${companyId}/approvals/${approvalId}?include_mileage=true`
      )

      commit(APPROVALS_STORE_MUTATIONS.SET_APPROVAL, { companyId, approval })

      return approval
    } catch (error) {
      throw error
    }
  },
  [APPROVALS_STORE_ACTIONS.GET_TRIP_APPROVAL]: async ({ commit }: any, payload: any): Promise<ApprovalItem> => {
    try {
      const { companyId, tripId } = payload

      const { data: approval = {} } = await Axios.get(`@coreapi/companies/${companyId}/approvals/report/${tripId}`)

      commit(APPROVALS_STORE_MUTATIONS.SET_APPROVAL, { companyId, approval })

      return approval
    } catch (error) {
      throw error
    }
  },
  [APPROVALS_STORE_ACTIONS.GET_RECEIPT_APPROVAL]: async ({ commit }: any, payload: any): Promise<ApprovalItem> => {
    try {
      const { companyId, receiptId } = payload

      const { data: approval = {} } = await Axios.get(`@coreapi/companies/${companyId}/approvals/doc/${receiptId}`)

      commit(APPROVALS_STORE_MUTATIONS.SET_APPROVAL, { companyId, approval })

      return approval
    } catch (error) {
      throw error
    }
  },
  [APPROVALS_STORE_ACTIONS.GET_APPROVER_OPTIONS]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId } = payload

      const { data = {} } = await Axios.get(`@coreapi/companies/${companyId}/approvals/options`)
      
      const { assignableApprovers = [] } = data

      commit(APPROVALS_STORE_MUTATIONS.SET_APPROVER_OPTIONS, { companyId, assignableApprovers })

      return data
    } catch (error) {
      throw error
    }
  },
  [APPROVALS_STORE_ACTIONS.APPROVE]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId, approvalId, decisionId, reason } = payload

      const { data: approval = {} } = await Axios.put(
        `@coreapi/companies/${companyId}/approvals/${approvalId}/decisions/${decisionId}/approve`,
        { reason }
      )

      commit(APPROVALS_STORE_MUTATIONS.SET_APPROVAL, { companyId, approval })

      return approval
    } catch (error) {
      throw error
    }
  },
  [APPROVALS_STORE_ACTIONS.REJECT]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId, approvalId, decisionId, payloadData } = payload

      const { data: approval = {} } = await Axios.put(
        `@coreapi/companies/${companyId}/approvals/${approvalId}/decisions/${decisionId}/reject`,
        payloadData
      )

      commit(APPROVALS_STORE_MUTATIONS.SET_APPROVAL, { companyId, approval })

      return approval
    } catch (error) {
      throw error
    }
  },
  [APPROVALS_STORE_ACTIONS.APPEND]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId, approvalId, decisionId, payloadData } = payload

      const { data: approval = {} } = await Axios.put(
        `@coreapi/companies/${companyId}/approvals/${approvalId}/decisions/${decisionId}/append`,
        payloadData
      )

      commit(APPROVALS_STORE_MUTATIONS.SET_APPROVAL, { companyId, approval })

      return approval
    } catch (error) {
      throw error
    }
  },
  [APPROVALS_STORE_ACTIONS.DELEGATE]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId, approvalId, decisionId, payloadData } = payload

      const { data: approval = {} } = await Axios.put(
        `@coreapi/companies/${companyId}/approvals/${approvalId}/decisions/${decisionId}/delegate`,
        payloadData
      )

      commit(APPROVALS_STORE_MUTATIONS.SET_APPROVAL, { companyId, approval })

      return approval
    } catch (error) {
      throw error
    }
  },
  [APPROVALS_STORE_ACTIONS.ADD_DECISION]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId, approvalId, payloadData } = payload

      const { data: approval = {} } = await Axios.post(
        `@coreapi/companies/${companyId}/approvals/${approvalId}/decisions`,
        payloadData
      )

      commit(APPROVALS_STORE_MUTATIONS.SET_APPROVAL, { companyId, approval })

      return approval
    } catch (error) {
      throw error
    }
  },
  [APPROVALS_STORE_ACTIONS.DELETE_DECISION]: async ({ commit, dispatch }: any, payload: any) => {
    try {
      const { companyId, approvalId, decisionId } = payload

      const { data: approval = {} } = await Axios.delete(
        `@coreapi/companies/${companyId}/approvals/${approvalId}/decisions/${decisionId}`
      )

      commit(APPROVALS_STORE_MUTATIONS.SET_APPROVAL, { companyId, approval })

      return approval
    } catch (error) {
      throw error
    }
  },
  [APPROVALS_STORE_ACTIONS.DELETE_APPROVAL]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId, approvalId } = payload

      const { data = {} } = await Axios.delete(`@coreapi/companies/${companyId}/approvals/${approvalId}`)

      commit(APPROVALS_STORE_MUTATIONS.DELETE_APPROVAL, { companyId, approvalId })

      return data
    } catch (error) {
      throw error
    }
  }
}
