import Axios from 'axios'
import { cloneDeep } from 'lodash-es'
import xstore from '@/store'
import vatRates from '@/lib/vatRates'
import { overWrittenValuesWhenAutofilling } from '@/lib/item-rows'
import { Receipt, ItemRow, ItemRowResponse, Company } from '@/models'
import { ITEM_ROW_KEY, RECEIPTS_STORE_MUTATIONS } from '@/enums'
import { useHelpers } from '@/composables'

export default function useItemRows() {
  const { safeAdd } = useHelpers()

  const countryDefaultVATRate = (countryCode: string) => {
    // @ts-ignore
    return vatRates[countryCode]?.[0] || 22
  }

  const addItemRow = async (company: Company, receipt: Receipt) => {
    const countryVATRate = countryDefaultVATRate(company.countryCode)
    const itemRow = {
      [ITEM_ROW_KEY.QUANTITY]: 1,
      [ITEM_ROW_KEY.VAT_RATE]: company.VATAccountable !== false ? countryVATRate : 0
    }
    if (!company?.id || !receipt?.id) {
      return
    }
    return Axios.post(`@coreapi/companies/${company.id}/receipts/${receipt.id}/itemrows`, itemRow).then(
      ({ data = {} }): ItemRowResponse => {
        xstore.commit(RECEIPTS_STORE_MUTATIONS.SET_RECEIPT_VALUES, { receipt, values: { ...data } })
        return data
      }
    )
  }

  const updateItemRow = async (
    companyId: any,
    receipt: Receipt,
    rowKey: ITEM_ROW_KEY,
    updatedValue: any,
    rowNum: ItemRow[ITEM_ROW_KEY.ROW_NUM]
  ) => {
    const autofillOverride = overWrittenValuesWhenAutofilling[rowKey] || {}
    const url = `@coreapi/companies/${companyId}/receipts/${receipt.id}/itemrows/${rowNum}?autofillValues=true`
    return Axios.put(url, { ...autofillOverride, [rowKey]: updatedValue }).then(({ data = {} }): ItemRowResponse => {
      xstore.commit(RECEIPTS_STORE_MUTATIONS.SET_RECEIPT_VALUES, { receipt, values: { ...data } })
      return data
    })
  }

  const deleteItemRow = async (companyId: any, receipt: Receipt, rowNum: ItemRow[ITEM_ROW_KEY.ROW_NUM]) => {
    if (!rowNum || !companyId || !receipt.id) return
    return Axios.delete(`@coreapi/companies/${companyId}/receipts/${receipt.id}/itemrows/${rowNum}`).then(
      ({ data = {} }): ItemRowResponse => {
        xstore.commit(RECEIPTS_STORE_MUTATIONS.SET_RECEIPT_VALUES, { receipt, values: { ...data } })
        return data
      }
    )
  }

  const deleteAllItemRows = async (receipt: Receipt | undefined, companyId: string | number | undefined) => {
    try {
      if (!receipt || !companyId) return
      const itemRows = cloneDeep(receipt.itemRows)
      for (const row of itemRows) {
        await deleteItemRow(companyId, receipt, 1)
      }
    } catch (error) {
      throw error
    }
  }

  const removeVAT = async (companyId: any, receipt: Receipt) => {
    try {
      const receiptItemRows: ItemRow[] = cloneDeep(receipt.itemRows)
      for (const row of receiptItemRows) {
        if (row.rowNum) {
          const autofillOverride = overWrittenValuesWhenAutofilling[ITEM_ROW_KEY.TOTAL] || {}
          const payload = {
            ...autofillOverride,
            [ITEM_ROW_KEY.VAT_RATE]: 0,
            [ITEM_ROW_KEY.TOTAL]: row[ITEM_ROW_KEY.TOTAL]
          }
          const url = `@coreapi/companies/${companyId}/receipts/${receipt.id}/itemrows/${
            row[ITEM_ROW_KEY.ROW_NUM]
          }?autofillValues=true`
          await Axios.put(url, payload).then(({ data = {} }): ItemRowResponse => {
            xstore.commit(RECEIPTS_STORE_MUTATIONS.SET_RECEIPT_VALUES, { receipt, values: { ...data } })
            return data
          })
        }
      }
      return true
    } catch (error) {
      throw error
    }
  }

  const calculateVATTotals = (itemRows: ItemRow[]) => {
    if (!itemRows) return {}

    const totals: {
      [key: string | number]: {
        [ITEM_ROW_KEY.VAT]: number
        [ITEM_ROW_KEY.SUM]: number
        [ITEM_ROW_KEY.TOTAL]: number
      }
    } = {}

    itemRows.forEach((row) => {
      const rowVATRate = row[ITEM_ROW_KEY.VAT_RATE]
      if (rowVATRate !== null && rowVATRate !== undefined) {
        if (!totals[rowVATRate]) {
          totals[rowVATRate] = {
            [ITEM_ROW_KEY.VAT]: 0,
            [ITEM_ROW_KEY.SUM]: 0,
            [ITEM_ROW_KEY.TOTAL]: 0
          }
        }

        let rowKey: keyof ItemRow
        for (rowKey in totals[rowVATRate]) {
          if (row[rowKey]) {
            //@ts-ignore
            totals[rowVATRate][rowKey] = safeAdd(totals[rowVATRate][rowKey], row[rowKey])
          }
        }
      }
    })
    return totals
  }

  const calculateItemRowTotals = (itemRows: ItemRow[]) => {
    const totals = {
      [ITEM_ROW_KEY.VAT]: 0,
      [ITEM_ROW_KEY.SUM]: 0,
      [ITEM_ROW_KEY.TOTAL]: 0
    }
    itemRows.forEach((row) => {
      let rowKey: keyof ItemRow
      for (rowKey in totals) {
        if (row[rowKey]) {
          //@ts-ignore
          totals[rowKey] = safeAdd(totals[rowKey], row[rowKey])
        }
      }
    })
    return totals
  }

  return {
    addItemRow,
    updateItemRow,
    deleteItemRow,
    deleteAllItemRows,
    removeVAT,
    calculateVATTotals,
    calculateItemRowTotals,
    countryDefaultVATRate
  }
}
