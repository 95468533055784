<script>
  export default {
    props: ['size', 'color', 'alignCenter']
  }
</script>
<template>
  <div 
    class="loading-bar-spinner loading-bar-spinner--is-center"
    :style="`
      min-width: ${size || 40}px; 
      min-height: ${size || 40}px;
    `">
    <div 
      class="loading-bar-spinner-wrapper"
      :style="`
        top: calc(50% - ${(size || 40) / 2}px);
        left: calc(50% - ${(size || 40) / 2}px);
      `">
      <div 
        class="spinner-icon"
        :style="`
          width: ${size || 40}px; 
          height: ${size || 40}px;
          border-top-color:  ${color || '#006e98'} !important;
          border-left-color: ${color || '#006e98'} !important;
        `"></div>
    </div>
  </div>
</template>
<style lang="scss">

.loading-bar-spinner {
	position: relative;
  z-index: 5;
  min-width: 40px; 
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &--is-center {
    width: 100%;
    height: 100%;
  }

	.loading-bar-spinner-wrapper {
    position: absolute;
		z-index: 19 !important;
    animation: loading-bar-spinner 400ms linear infinite;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
                                 
		.spinner-icon {
      width: 40px; 
      height: 40px;
			border:  solid 4px transparent;
			border-top-color:  #ffffff !important;
			border-left-color: #ffffff !important;
			border-radius: 50%;
		}
	}
}

@keyframes loading-bar-spinner {
  0%   { 
    transform: rotate(0deg);   
    transform: rotate(0deg); 
  }
  100% { 
    transform: rotate(360deg); 
    transform: rotate(360deg); 
  }
}
</style>
