import { TRANSACTIONS_STORE_MUTATIONS, TRANSACTIONS_STORE_STATE } from '@/enums'
import { BankAccountInfo, PaymentTransaction, TransactionsStoreState } from '@/models'

export default {
  [TRANSACTIONS_STORE_MUTATIONS.SET_TRANSACTIONS]: (
    state: TransactionsStoreState,
    payload: { companyId?: string | number; transactions?: PaymentTransaction[] } = {}
  ) => {
    const { companyId, transactions = [] } = payload
    if (!companyId) return
    if (!state[TRANSACTIONS_STORE_STATE.TRANSACTIONS][companyId]) {
      state[TRANSACTIONS_STORE_STATE.TRANSACTIONS][companyId] = {}
    }
    for (const transaction of transactions) {
      state[TRANSACTIONS_STORE_STATE.TRANSACTIONS][companyId][transaction.id] = {
        ...state[TRANSACTIONS_STORE_STATE.TRANSACTIONS][companyId][transaction.id],
        ...transaction
      }
    }
  },
  [TRANSACTIONS_STORE_MUTATIONS.UPDATE_TRANSACTION]: (
    state: TransactionsStoreState,
    payload: { companyId?: string | number; transaction?: PaymentTransaction } = {}
  ) => {
    const { companyId, transaction } = payload
    if (!companyId || !transaction?.id) return
    if (!state[TRANSACTIONS_STORE_STATE.TRANSACTIONS][companyId]) {
      state[TRANSACTIONS_STORE_STATE.TRANSACTIONS][companyId] = {}
    }
    state[TRANSACTIONS_STORE_STATE.TRANSACTIONS][companyId][transaction.id] = {
      ...state[TRANSACTIONS_STORE_STATE.TRANSACTIONS][companyId][transaction.id],
      ...transaction
    }
  },
  [TRANSACTIONS_STORE_MUTATIONS.DELETE_TRANSACTION]: (
    state: TransactionsStoreState,
    payload: { companyId?: string | number; transactionId?: string | number } = {}
  ) => {
    const { companyId, transactionId } = payload
    if (!companyId || !transactionId) return
    delete state[TRANSACTIONS_STORE_STATE.TRANSACTIONS][companyId][transactionId]
  },
  [TRANSACTIONS_STORE_MUTATIONS.SET_BANK_ACCOUNT_LIST]: (
    state: TransactionsStoreState,
    payload: { companyId?: string | number; bankAccounts?: BankAccountInfo[] } = {}
  ) => {
    const { companyId, bankAccounts = [] } = payload
    if (!companyId) return
    if (!state[TRANSACTIONS_STORE_STATE.BANK_ACCOUNT_LIST][companyId]) {
      state[TRANSACTIONS_STORE_STATE.BANK_ACCOUNT_LIST][companyId] = {}
    }
    bankAccounts.forEach((bankAccount, index) => {
      state[TRANSACTIONS_STORE_STATE.BANK_ACCOUNT_LIST][companyId][index] = bankAccount
    })
  }
}
