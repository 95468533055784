import mutations from './payments.mutations'
import actions from './payments.actions'
import getters from './payments.getters'
import state from './payments.state'

export default {
  mutations,
  actions,
  getters,
  state
}