export default function particularizeReportName(existingName, otherReportNames) {
  if (otherReportNames.find((prevName) => prevName === existingName)) {
    let nameNumber = 1
    let newName = ''
    while (!newName) {
      if (!otherReportNames.find((prevName) => prevName === `${existingName} (${nameNumber})`)) {
        newName = `${existingName} (${nameNumber})`
      }
      nameNumber++
    }
    return newName
  }

  return existingName
}
