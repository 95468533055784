module.exports = {
  FI: 48,  
  AF: 55,
  AL: 71,
  DZ: 74,
  AD: 59,
  AO: 73,
  AR: 45,
  AM: 62,
  AW: 74,
  AU: 77,
  AT: 75,
  BS: 92,
  BH: 86,
  BD: 68,
  BB: 90,
  BY: 66,
  BE: 74,
  BZ: 54,
  BJ: 45,
  BM: 94,
  BT: 52,
  BO: 49,
  BW: 46,
  BR: 77,
  BG: 59,
  BF: 40,
  BI: 54,
  KH: 72,
  CM: 55,
  CA: 86,
  CV: 44,
  CF: 96,
  TD: 56,
  CL: 55,
  CN: 82,
  CO: 55,
  KM: 38,
  CK: 69,
  CR: 60,
  HR: 65,
  CU: 52,
  CW: 60,
  CY: 63,
  CZ: 82,
  DK: 77,
  DJ: 85,
  DM: 64,
  DO: 58,
  EC: 65,
  EG: 73,
  SV: 59,
  ER: 95,
  EE: 72,
  ET: 43,
  FO: 60,
  FJ: 56,
  FR: 74,
  GA: 88,
  GM: 50,
  GE: 48,
  DE: 71,
  GH: 41,
  GR: 66,
  GL: 61,
  GD: 76,
  GP: 51,
  GT: 78,
  GN: 82,
  GW: 38,
  GY: 53,
  HT: 55,
  HN: 59,
  HU: 56,
  IS: 89,
  IN: 64,
  ID: 61,
  IR: 122,
  IQ: 65,
  IE: 73,
  IL: 100,
  IT: 72,
  JM: 64,
  JP: 70,
  KZ: 51,
  KE: 85,
  XK: 52,
  KW: 86,
  LV: 69,
  LB: 122,
  LS: 37,
  LR: 71,
  LY: 53,
  LI: 77,
  LT: 68,
  LU: 74,
  MG: 46,
  MW: 69,
  MY: 54,
  MV: 71,
  ML: 43,
  MT: 67,
  MH: 67,
  MQ: 54,
  MR: 53,
  MU: 53,
  MX: 71,
  FM: 60,
  MD: 64,
  MC: 92,
  MN: 44,
  ME: 61,
  MA: 69,
  MZ: 55,
  NA: 40,
  NP: 53,
  NL: 80,
  NZ: 75,
  NI: 51,
  NE: 49,
  NG: 81,
  NO: 76,
  OM: 79,
  PK: 31,
  PW: 94,
  PA: 65,
  PG: 79,
  PY: 39,
  PE: 51,
  PH: 70,
  PL: 64,
  PT: 68,
  PR: 72,
  QA: 81,
  RO: 62,
  RW: 40,
  KN: 70,
  LC: 86,
  WS: 60,
  SM: 62,
  ST: 101,
  SA: 83,
  SN: 55,
  RS: 66,
  SC: 93,
  SL: 53,
  SG: 79,
  SK: 73,
  SI: 68,
  SB: 63,
  SO: 87,
  ZA: 55,
  SS: 119,
  ES: 72,
  LK: 34,
  SD: 50,
  SR: 74,
  SE: 66,
  CH: 91,
  TW: 73,
  TZ: 59,
  TH: 65,
  TG: 54,
  TO: 59,
  TT: 84,
  TN: 59,
  TR: 38,
  TM: 92,
  UG: 50,
  UA: 62,
  AE: 84,
  GB: 73,
  US: 92,
  UY: 56,
  UZ: 35,
  VU: 70,
  VE: 100,
  VN: 74,
  YE: 122,
  ZW: 75,
  ZM: 70,
  AG: 95,
  AZ: 68,
  BA: 52,
  BN: 46,
  HK: 92,
  CG: 62,
  CD: 50,
  CI: 77,
  TL: 51,
  SZ: 41,
  JO: 96,
  KP: 75,
  KR: 85,
  KG: 42,
  LA: 35,
  MM: 61,
  MK: 56,
  RU: 69,
  VC: 86,
  SY: 98,
  TJ: 39,
  'PT, Azores': 67,
  'ES, Canary Islands': 70,
  'PT, Madeira': 66,
  'RU, Moscow': 85,
  'RU, Saint Petersburg': 78,
  'TR, Istanbul': 39,
  'GB, London': 77,
  'GB, Edinburgh': 77,
  'US, New York': 100,
  'US, Los Angeles': 100,
  'US, Washington': 100,
  'US, Virgin Islands': 66,
}