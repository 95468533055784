import Big from 'big.js'
import moment from 'moment'

export default function (receipts) {
  const receiptsById = {}
    receipts.forEach((receipt) => {
    if (receipt.sum) {
      receipt.sum = new Big(receipt['sum']).toFixed(2).toString()
    }
    if (receipt.sumNoVAT) {
      receipt.sumNoVAT = new Big(receipt['sumNoVAT']).toFixed(2).toString()
    }
    if (receipt.VAT) {
      receipt.VAT = new Big(receipt['VAT']).toFixed(2).toString()
    }
    receipt.issued = new moment(receipt?.issued, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD').toString()
    receiptsById[receipt.id] = receipt
  })
  return receiptsById
}
