import actions from './mileage.actions'
import getters from './mileage.getters'
import mutations from './mileage.mutations'
import state from './mileage.state'

export default {
    actions,
    getters,
    mutations,
    state
}