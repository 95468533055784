import { APPROVAL_SETTINGS_STORE_GETTERS, APPROVAL_SETTINGS_STORE_STATE } from '@/enums'
import { ApprovalSettingsStoreState } from '@/models'

export default {
  [APPROVAL_SETTINGS_STORE_GETTERS.APPROVAL_WORKFLOWS]: (state: ApprovalSettingsStoreState) => {
    return state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_WORKFLOWS]
  },
  [APPROVAL_SETTINGS_STORE_GETTERS.APPROVAL_POLICIES]: (state: ApprovalSettingsStoreState) => {
    return state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_POLICIES]
  },
  [APPROVAL_SETTINGS_STORE_GETTERS.APPROVAL_OPTIONS]: (state: ApprovalSettingsStoreState) => {
    return state[APPROVAL_SETTINGS_STORE_STATE.APPROVAL_OPTIONS]
  }
}
