import axios from 'axios'
import { MILEAGE_STORE_ACTIONS, MILEAGE_STORE_MUTATIONS, SETTING_STORE_ACTIONS } from '@/enums'

export default {
  [MILEAGE_STORE_ACTIONS.GET_MILEAGE]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId } = payload

      const { data: mileage = [] } = await axios.get(`@coreapi/companies/${companyId}/trips/mileage`)

      commit(MILEAGE_STORE_MUTATIONS.SET_MILEAGE, { mileage, companyId })

      return mileage
    } catch (error) {
      throw error
    }
  },
  [MILEAGE_STORE_ACTIONS.GET_TRIP_MILEAGE]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId, tripId } = payload

      const { data: mileage = [] } = await axios.get(`@coreapi/companies/${companyId}/trips/${tripId}/mileage`)

      commit(MILEAGE_STORE_MUTATIONS.SET_MILEAGE, { companyId, mileage })

      return mileage
    } catch (error) {
      throw error
    }
  },
  [MILEAGE_STORE_ACTIONS.ADD_MILEAGE_ITEM]: async ({ commit, dispatch }: any, payload: any) => {
    try {
      const { companyId, tripId, mileageItem } = payload

      dispatch(SETTING_STORE_ACTIONS.UPDATE_LOADING_STATE, true)

      const { data = {} } = await axios.post(`@coreapi/companies/${companyId}/trips/${tripId}/mileage`, mileageItem)

      commit(MILEAGE_STORE_MUTATIONS.SET_MILEAGE, { companyId, mileage: [data] })

      return data
    } catch (error) {
      throw error
    } finally {
      dispatch(SETTING_STORE_ACTIONS.UPDATE_LOADING_STATE, false)
    }
  },
  [MILEAGE_STORE_ACTIONS.UPDATE_MILEAGE_ITEM]: async ({ commit, dispatch }: any, payload: any) => {
    try {
      const { companyId, tripId, mileageId, mileageItem } = payload

      dispatch(SETTING_STORE_ACTIONS.UPDATE_LOADING_STATE, true)

      const { data = {} } = await axios.put(
        `@coreapi/companies/${companyId}/trips/${tripId}/mileage/${mileageId}`,
        mileageItem
      )

      commit(MILEAGE_STORE_MUTATIONS.SET_MILEAGE, { companyId, mileage: [data] })

      return data
    } catch (error) {
      throw error
    } finally {
      dispatch(SETTING_STORE_ACTIONS.UPDATE_LOADING_STATE, false)
    }
  },
  [MILEAGE_STORE_ACTIONS.DELETE_MILEAGE_ITEM]: async ({ commit, dispatch }: any, payload: any) => {
    try {
      const { companyId, tripId, mileageId } = payload

      dispatch(SETTING_STORE_ACTIONS.UPDATE_LOADING_STATE, true)

      const _ = await axios.delete(`@coreapi/companies/${companyId}/trips/${tripId}/mileage/${mileageId}`)

      commit(MILEAGE_STORE_MUTATIONS.REMOVE_MILEAGE, { companyId, mileageId })

      return true
    } catch (error) {
      throw error
    } finally {
      dispatch(SETTING_STORE_ACTIONS.UPDATE_LOADING_STATE, false)
    }
  }
}
