/**
 * Returns '0.00' if sum is non-numeric value
 * Leaves only the first inserted comma (if multiple are present),
 * replaces comma with dot and removes all spaces 
 * @param {string}
 * @returns {string} formatted sum
 */

export default function transformSumInput(sum = '') {
  sum = sum === null || sum === undefined ? '' : sum
  const sumWithDots = sum.toString().replaceAll(',', '.').replaceAll(' ', '')
  if (!sumWithDots.length) return '0.00'
  const transformedSum = [...sumWithDots].reduce((formattedSum, nextChar, index) => {
    if (nextChar === '.') {
      const remainingString = sum.toString().slice(index + 1)
      nextChar = remainingString.includes('.') ? '' : nextChar
    }
    return formattedSum + nextChar
  })
  if (transformedSum && isNaN(transformedSum)) return '0.00'
  return transformedSum
}
