import xstore from '@/store'
import { validateStatus } from '@/lib/status'
import { useInstanceHelpers, useContext, useIntegrations } from '@/composables'
import {
  RECEIPTS_STORE_MUTATIONS,
  TRIPS_STORE_ACTIONS,
  RECEIPTS_STORE_GETTERS,
  TRIP_STATUS_VALIDATOR,
  TRIP_STATUS,
  RECEIPT_STATUS,
  RECEIPTS_STORE_ACTIONS,
  PREVALIDATION_ERROR_MESSAGES,
  ERRROR_MESSAGES,
  INTEGRATION
} from '@/enums'
import { Company, Receipt, Trip } from '@/models'

export default function useExpenseFlow() {
  const { t } = useInstanceHelpers()
  const { root } = useContext()
  const { activeIntegration } = useIntegrations()

  const findPrevalidationError = (validationErrors: Array<any>): string => {
    let message = 'somethingWentWrong'
    if (!validationErrors[0]) return message

    const invalid: {
      field: string
      condition: keyof typeof PREVALIDATION_ERROR_MESSAGES
    } = validationErrors[0].invalidFields?.[0] || {}

    if (invalid.field && invalid.condition) {
      message = `${t(PREVALIDATION_ERROR_MESSAGES[invalid.condition]).replace('$field', t(invalid.field))}`
    }

    return message
  }

  const digitizeReceipt = async (company: Company | undefined, receipt: Receipt | undefined): Promise<Boolean> => {
    try {
      if (!company?.id || !receipt?.id) return false
      if (receipt.status && validateStatus(receipt.status, [RECEIPT_STATUS.DIGITIZED])) {
        return true
      }
      const data = await xstore.dispatch(RECEIPTS_STORE_ACTIONS.DIGITIZE_RECEIPT, { company, receipt })
      const { efException, efAction } = data
      if (efException && efAction === 'NONE') {
        throw efException
      }
      return true
    } catch (error: any) {
      throw root?.eh(error)
    }
  }

  const receiptSubmitOnly = async (company: Company, receipt: Receipt, suppressDuplicateCheck = false) => {
    const isSubmitted = receipt.status && validateStatus(receipt.status, [RECEIPT_STATUS.SUBMITTED])
    const isRejected = receipt.status && validateStatus(receipt.status, [RECEIPT_STATUS.REJECTED])
    let efData =
      isSubmitted || isRejected
        ? await xstore.dispatch(RECEIPTS_STORE_ACTIONS.RESUBMIT_RECEIPT, { company, receipt, suppressDuplicateCheck })
        : await xstore.dispatch(RECEIPTS_STORE_ACTIONS.SUBMIT_RECEIPT, { company, receipt, suppressDuplicateCheck })

    if (!efData) throw new Error("Can't submit report")

    if (receipt.tripId) return 'submitted'

    const isAdmin = Boolean(company?.companyRoles)

    const efAction = efData.efAction
    const efException = efData.efException
    if (efAction === 'MARK_FOR_PROCESSING') {
      return await sendReceipt(company, receipt)
    } else if (efAction === 'CHECK_PROCESSING' && efException === 'DOC_NOT_READY' && isAdmin) {
      return await sendReceipt(company, receipt)
    } else if (efAction === 'MARK_FOR_APPROVAL') {
      return 'receiptWaitingForApproval'
    } else if (efAction === 'NONE' && efException) {
      throw efException
    }
    return 'submitted'
  }

  const submitReceipt = async (company: Company, receipt: Receipt): Promise<any> => {
    try {
      if (!company.id || !receipt.id) throw 'forwardingReceiptFailed'

      if (company?.digitization !== true || receipt.extraData?.emptyReceipt || receipt.docType === 'nonexpense') {
        await digitizeReceipt(company, receipt)
      }

      return await receiptSubmitOnly(company, receipt)
    } catch (error: any) {
      throw root?.eh(error)
    }
  }

  const confirmReceipt = async (company: Company, receipt: Receipt) => {
    try {
      await xstore.dispatch(RECEIPTS_STORE_ACTIONS.CONFIRM_RECEIPT, { company, receipt })
    } catch (error) {
      throw error
    }
  }

  const sendReceipt = async (company: Company, receipt: Receipt): Promise<any> => {
    try {
      if (!company.id || !receipt.id) throw 'forwardingReceiptFailed'

      const requiresConfirmation = company?.settings?.requireExpenseConfirmation
      const isAdmin = Boolean(company?.companyRoles)

      if (
        requiresConfirmation &&
        receipt.status &&
        !validateStatus(receipt.status, [RECEIPT_STATUS.READY]) &&
        isAdmin
      ) {
        await confirmReceipt(company, receipt)
      }

      const result = await xstore.dispatch(RECEIPTS_STORE_ACTIONS.SEND_TO_OUTBOUND, { company, receipt })

      let errorText = 'forwardingReceiptFailed'
      if (!result.success && result.error && result.error.codename) {
        let options: { link?: string; linkText?: string } = {}
        switch (result.error.codename) {
          case 'OPERATOR_BOUNCE':
            errorText = t('operatorBounce')
            if (localStorage.language === 'est') {
              options.link =
                'https://costpocket.com/et/learn/Dokumendi%20saatmine%20l%C3%A4bi%20e-arve%20operaatori%20eba%C3%B5nnestub'
              options.linkText = t('readTheTutorial')
            }
            break
          case 'INVALID_CREDENTIALS':
            errorText = t('invalidCredentials')
            break
          case 'INVALID_CONTENT':
            errorText = `${t('invalidContent')} ${result.error.message || ''}`
            break
          default:
            break
        }
        throw errorText
      } else if (result.efException) {
        throw result.efException
      } else if (!result.success) {
        throw errorText
      } else if (result.success && result.message === 'This document has already been imported') {
        throw ERRROR_MESSAGES.DOC_ALREADY_IMPORTED
      }
      return 'sent'
    } catch (error: any) {
      if (error.response?.data?.efException === 'DOC_NOT_APPROVED') {
        await receiptSubmitOnly(company, receipt)
        return
      }

      if (error.response?.data?.validationErrors && error.response?.data?.validationErrors.length) {
        const message = findPrevalidationError(error.response.data.validationErrors)
        if (message) throw message
      }

      throw root?.eh(error)
    }
  }

  const unsubmitReceipt = async (companyId: string | number, receipt: Receipt): Promise<any> => {
    if (!companyId || !receipt?.id) return { message: 'somethingWentWrong', success: false }
    try {
      const data = await xstore.dispatch(RECEIPTS_STORE_ACTIONS.UNSUBMIT_RECEIPT, { companyId, receipt })
      const { efException } = data

      if (efException) {
        return { message: root?.eh(data), success: false }
      }
      return { message: t('saved'), success: true }
    } catch (error: any) {
      if (error?.response?.message) {
        return { message: error.response.mesage, success: false }
      }
      return { message: error, success: false }
    }
  }

  const unsubmitReport = async (company: any, trip: any) => {
    if (!company.id || !trip.id) return { message: 'somethingWentWrong', success: false }

    try {
      const data = await xstore.dispatch(TRIPS_STORE_ACTIONS.UNSUBMIT_TRIP, { company, trip })
      const { efException } = data

      if (efException) {
        return { message: root?.eh(data), success: false }
      }
      return { message: t('saved'), success: true }
    } catch (error: any) {
      if (error?.response?.message) {
        return { message: error.response.mesage, success: false }
      }
      return { message: error, success: false }
    }
  }

  const submitReport = async (company: any, trip: any, tripReceipts: any) => {
    if (!company.id || !trip.id) {
      return { message: t('sendingReportFailed'), success: false }
    }

    try {
      let efData =
        validateStatus(trip.status, [TRIP_STATUS.CONFIRMED]) || validateStatus(trip.status, [TRIP_STATUS.REJECTED])
          ? await xstore.dispatch(TRIPS_STORE_ACTIONS.RESUBMIT_TRIP, { company, trip })
          : await xstore.dispatch(TRIPS_STORE_ACTIONS.SUBMIT_TRIP, { company, trip })

      if (!efData) throw new Error("Can't submit report")

      const efAction = efData.efAction
      const efException: keyof typeof ERRROR_MESSAGES = efData.efException

      let message = 'sent'
      if (efAction === 'MARK_FOR_PROCESSING') {
        return await sendReport(company, trip, tripReceipts)
      } else if (efAction === 'MARK_FOR_VERIFIED_DIGITIZATION') {
        message = t('reportWillBeSentAfterAllDocumentsDigitized')
      } else if (efAction === 'MARK_FOR_APPROVAL') {
        message = t('reportWaitingForApproval')
      } else if (efAction === 'NONE' && efException) {
        return { message: root?.eh(efData), success: false }
      } else if (efException === 'REPORTS_NOT_ENABLED') {
        return { message: ERRROR_MESSAGES[efException], success: false }
      }

      return { message, success: true }
    } catch (error: any) {
      if (error?.response?.message) {
        return { message: error.response.mesage, success: false }
      }
      return { message: error, success: false }
    }
  }

  const sendReport = async (company: any, trip: any, tripReceipts: any) => {
    if (!company.id || !trip.id) return { mesage: t('sendingReportFailed'), success: false }

    try {
      const data = await xstore.dispatch(TRIPS_STORE_ACTIONS.SEND_TRIP_TO_SOFTWARE, { company, trip })

      if (data?.receipts) {
        data.receipts.forEach((receipt: any) => {
          const currentReceiptState = xstore.getters[RECEIPTS_STORE_GETTERS.RECEIPT_BY_ID](receipt.id)

          // currentReceiptState might not exist if the receipt has not been put to RECEIPTS_STORAGE
          if (currentReceiptState) {
            xstore.commit(RECEIPTS_STORE_MUTATIONS.SET_RECEIPT_VALUES, {
              receipt: currentReceiptState,
              values: { status: receipt.status }
            })
          }
        })
      }

      let errorText = t('sendingReportFailed')
      let willBeSent = false

      if (!data.success && data.error && data.error.codename) {
        let options: any = {}
        switch (data.error.codename) {
          case 'OPERATOR_BOUNCE':
            errorText = t('operatorBounce')
            if (localStorage.language === 'est') {
              // TODO: use store to get language
              options.link =
                'https://costpocket.com/et/learn/Dokumendi%20saatmine%20l%C3%A4bi%20e-arve%20operaatori%20eba%C3%B5nnestub'
              options.linkText = t('readTheTutorial')
            }
            break
          case 'INVALID_CREDENTIALS':
            errorText = t('invalidCredentials')
            break
          case 'INVALID_CONTENT':
            errorText = `${t('invalidContent')} ${data.error.message || ''}`
            break
          case 'DEPART_CONDITIONS_NOT_MET':
            const hasUndigitizedReceipts = Object.values(tripReceipts.receipts).find((receipt: any) =>
              validateStatus(receipt.status, [-RECEIPT_STATUS.DIGITIZED])
            )
            willBeSent = company?.digitization && hasUndigitizedReceipts
            break
          default:
            break
        }
        if (willBeSent) {
          return { message: reportSentMessage(true), success: true }
        }
        return { message: errorText, success: false, options }
      } else if (!data.success) {
        return { message: errorText, success: false }
      }
      return { message: reportSentMessage(false), success: true }
    } catch (error: any) {
      if (error?.response?.message) {
        return { message: error.response.mesage, success: false }
      }
      return { message: error, success: false }
    }
  }

  const confirmAndSendReport = async (company: Company, trip: Trip, tripReceipts: Receipt[]) => {
    try {
      if (!validateStatus(trip?.status, TRIP_STATUS_VALIDATOR.CONFIRMED)) {
        const data = await xstore.dispatch(TRIPS_STORE_ACTIONS.SUBMIT_TRIP, { company, trip })

        const efAction = data.efAction
        const efException = data.efException
        let message = 'submitted'
        if (efAction === 'MARK_FOR_VERIFIED_DIGITIZATION') {
          message = t('reportWillBeSentAfterAllDocumentsDigitized')
          return { message, success: true }
        } else if (efAction === 'MARK_FOR_APPROVAL') {
          message = t('reportWaitingForApproval')
          return { message, success: true }
        } else if (efAction === 'NONE' && efException) {
          return { message: root?.eh(data), success: false }
        }
      }

      const requiredConfirmation = company?.settings?.requireReportConfirmation === true
      if (requiredConfirmation && !validateStatus(trip?.status, TRIP_STATUS_VALIDATOR.READY)) {
        const data = await xstore.dispatch(TRIPS_STORE_ACTIONS.CONFIRM_AND_SEND, { company, trip })
        if (data.efException) {
          return { message: root?.eh(data), success: false }
        }
        return { message: 'sent', success: true }
      } else {
        return sendReport(company, trip, tripReceipts)
      }
    } catch (error: any) {
      if (error?.response?.message) {
        return { message: error.response.mesage, success: false }
      }
      return { message: error, success: false }
    }
  }

  const reportSentMessage = (onlyAfterDigitizing: Boolean) => {
    const usesEmail = activeIntegration.value?.id === INTEGRATION.EMAIL
    let message = usesEmail ? t('yourReportsPDFCSVWillBeEmailedToYou') : t('reportIsForwaredToERP')
    if (onlyAfterDigitizing) {
      message += ' ' + t('afterDocsDigitized')
    }
    return message
  }

  return {
    digitizeReceipt,
    submitReceipt,
    receiptSubmitOnly,
    sendReceipt,
    submitReport,
    confirmAndSendReport,
    sendReport,
    findPrevalidationError,
    unsubmitReport,
    unsubmitReceipt
  }
}
