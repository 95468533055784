import { ACCOUNTING_STORE_GETTERS, ACCOUNTING_STORE_STATE } from '@/enums'
import { AccountingStoreState } from '@/models'

export default {
  [ACCOUNTING_STORE_GETTERS.RECEIPT_ACCOUNTING_ENTRIES]: (state: AccountingStoreState) => {
    return state[ACCOUNTING_STORE_STATE.RECEIPT_ACCOUNTING_ENTRIES]
  },
  [ACCOUNTING_STORE_GETTERS.TRIP_ACCOUNTING_ENTRIES]: (state: AccountingStoreState) => {
    return state[ACCOUNTING_STORE_STATE.TRIP_ACCOUNTING_ENTRIES]
  },
  [ACCOUNTING_STORE_GETTERS.RECEIPT_ACCOUNTING_REFS]: (state: AccountingStoreState) => {
    return state[ACCOUNTING_STORE_STATE.RECEIPT_ACCOUNTING_REFS]
  },
  [ACCOUNTING_STORE_GETTERS.TRIP_ACCOUNTING_REFS]: (state: AccountingStoreState) => {
    return state[ACCOUNTING_STORE_STATE.TRIP_ACCOUNTING_REFS]
  }
}