import { MILEAGE_STORE_STATE, MILEAGE_STORE_MUTATIONS } from '@/enums'
import { MileageItem, MileageStoreState } from '@/models'

export default {
  [MILEAGE_STORE_MUTATIONS.SET_MILEAGE]: (
    state: MileageStoreState,
    payload: { companyId: string | number; mileage: MileageItem[] }
  ) => {
    const { companyId, mileage } = payload
    if (!state[MILEAGE_STORE_STATE.MILEAGE][companyId]) {
      state[MILEAGE_STORE_STATE.MILEAGE][companyId] = {}
    }
    for (const mileageItem of mileage) {
      state[MILEAGE_STORE_STATE.MILEAGE][companyId][mileageItem.id] = mileageItem
    }
  },
  [MILEAGE_STORE_MUTATIONS.REMOVE_MILEAGE]: (
    state: MileageStoreState,
    payload: { companyId: string | number; mileageId: string | number }
  ) => {
    const { companyId, mileageId } = payload
    if (state[MILEAGE_STORE_STATE.MILEAGE][companyId]?.[mileageId]) {
      delete state[MILEAGE_STORE_STATE.MILEAGE][companyId][mileageId]
    }
  }
}
