<template>
  <button class="nav-drawer" :class="{ selected: isSelected }" @click="route && !isDisabled ? $router.push(route) : $emit('click')">
    <span class="text-section">
      <span>{{ label }}</span>
      <slot></slot>
    </span>
    <span class="icon-section">
      <icon-chevron-right fill="#2a2e41" height="24px" width="24px"></icon-chevron-right>
    </span>
  </button>
</template>

<script>
import IconChevronRight from '../icons/IconChevronRight.vue'

export default {
  name: 'UiNavigationDrawer',
  components: {
    IconChevronRight
  },
  props: {
    label: {
      type: String
    },
    route: {
      type: String
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-drawer {
  @extend %box-text;
  @extend %box-outline;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 56px;
  height: auto;
  width: 100%;
  padding: 15px 16px;
  font-weight: 500;
  color: $medium-dark-grey;
  background: $white;

  &.selected {
    border: 2px solid $main-blue;
  }

  .text-section {
    text-align: left;
    padding-right: 8px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .icon-section {
    display: flex;
    align-items: center;
    height: 100%;
  }
}
</style>
