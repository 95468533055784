const lithuaniaPerDiems2022 = require('../data/lithuaniaPerDiems2022')
const lithuaniaPerDiems2023 = require('../data/lithuaniaPerDiems2023')
const { each, times } = require('lodash-es')
const moment = require('moment')

module.exports = function (tripInput, tripsInput) {
  let sum = 0
  let warning = false
  let overlappingDates = {}
  let newTripFirstDay = tripInput.startDate
  let oldTripEndDates = []

  // CREATE CALENDAR OBJECTS FROM EXISTING TRIPS AND THE NEW TRIP
  let oldTrips = createPreviousTripCalendar(tripsInput)
  let newTrip = createTripCalendar(tripInput)
  let days = calculateTripDays(newTrip)
  let dataPerDestination = []

  each(tripsInput, (trip) => {
    oldTripEndDates.push(trip.endDate)
  })

  each(newTrip, (days, month) => {
    // GO OVER EVERY DAY IN MONTH
    each(days, (_, day) => {
      let endIsStart = `${month}-${day}` === newTripFirstDay && oldTripEndDates.includes(newTripFirstDay)
      if (oldTrips[month] && oldTrips[month][day] && !endIsStart) {
        // IF THIS DAY ALREADY EXISTS IN ANOTHER TRAVEL REPORT, DON'T ADD IT
        newTrip[month][day].perDiem = 0
        // SHOW WARNING TO USER THAT ALLOWANCE IS TAKEN OFF
        warning = true
        if (!overlappingDates[month]) overlappingDates[month] = []
        overlappingDates[month].push(day.replace(/^0+(?!$)/, ''))
      }
      // ADD ALLOWANCE TO TOTAL SUM
      sum = Number((sum + newTrip[month][day].perDiem).toFixed(2))
      // CALCULATE SUM AND DAYS PER DESTINATION
      let destinationInfo = dataPerDestination.find((item) => item.index === newTrip[month][day].index)
      if (destinationInfo) {
        destinationInfo.sum = Number((destinationInfo.sum + newTrip[month][day].perDiem).toFixed(2))
        destinationInfo.days++
      } else {
        dataPerDestination.push({ sum: newTrip[month][day].perDiem, days: 1, index: newTrip[month][day].index })
      }
    })
  })

  //SORT OVERLAPPING DATES IN ASCENDING ORDER
  each(overlappingDates, (_, month) => {
    overlappingDates[month].sort((a, b) => a - b)
  })

  return {
    days,
    sum,
    dataPerDestination,
    warning,
    overlappingDates
  }
}

function createCalendarOfDestinations(allDestinations) {
  let calendar = {}

  allDestinations.forEach((dest) => {
    if (!dest.startDate || !dest.endDate || !dest.destination) {
      return
    }

    let start = moment(dest.startDate)
    let end = moment(dest.endDate)
    let tripDays = end.diff(start, 'days') + 1

    times(tripDays, function (d) {
      let currentDay = start.clone().add(d, 'days')
      let day = currentDay.format('DD').toString()
      let month = currentDay.format('YYYY-MM').toString()

      if (!calendar[month]) calendar[month] = {}
      if (!calendar[month][day]) {
        calendar[month][day] = [{ dest: dest.destination, index: dest.index }]
      } else {
        calendar[month][day].push({ dest: dest.destination, index: dest.index })
      }
    })
  })
  return calendar
}

function findPerDiem(country, date) {
  const financialYear = moment(date).isSameOrAfter('2023-08-01') ? '2023' : '2022'
  const perDiemOfYear = {
    '2022': lithuaniaPerDiems2022,
    '2023': lithuaniaPerDiems2023
  }
  let perDiem = perDiemOfYear[financialYear]?.[country]
  if (!perDiem) {
    // DEFAULT PER DIEM IF DESTINATION COUNTRY WAS NOT LISTED
    perDiem = financialYear === '2023' ? 33 : 31
  }
  return perDiem
}

function calculateAllowanceForEachDay(calendarWithDestinations, maxAllowance) {
  let calendar = {}

  each(calendarWithDestinations, (daysOfMonth, month) => {
    each(daysOfMonth, (_, day) => {
      let countriesInOneDay = calendarWithDestinations[month][day]
      const date = `${month}-${day}`
      let perDiem = 0

      if (countriesInOneDay.length === 1) {
        perDiem = findPerDiem(countriesInOneDay[0].dest, date)
      }

      if (countriesInOneDay.length > 1) {
        // IF MORE THAN ONE COUNTRY IS VISITED IN ONE DAY, CALCULATE AVERAGE PER DIEM
        let perDiems = []
        countriesInOneDay.forEach((item) => {
          perDiems.push(findPerDiem(item.dest, date))
        })

        let total = 0
        for (let i = 0; i < perDiems.length; i++) {
          total += perDiems[i]
        }
        perDiem = Number((total / perDiems.length).toFixed(2))
      }

      if (maxAllowance) {
        // IF COMPANY HAS MAXIMUM ALLOWANCE, MAKE SURE PER DIEM IS NOT LARGER
        perDiem = perDiem > maxAllowance ? maxAllowance : perDiem
      }

      if (!calendar[month]) calendar[month] = {}
      if (!calendar[month][day]) calendar[month][day] = {}
      calendar[month][day].perDiem = perDiem
      calendar[month][day].index = countriesInOneDay[countriesInOneDay.length - 1].index
    })
  })

  return calendar
}

function mergeCalendars(calendar, oneTripCalendar) {
  Object.keys(oneTripCalendar).forEach((month) => {
    if (calendar[month]) {
      calendar[month] = { ...calendar[month], ...oneTripCalendar[month] }
    } else {
      calendar[month] = oneTripCalendar[month]
    }
  })
}

function calculateTripDays(tripCalendar) {
  let tripDays = 0
  Object.keys(tripCalendar).forEach((month) => {
    Object.keys(tripCalendar[month]).forEach(() => {
      tripDays++
    })
  })
  return tripDays
}

function createTripCalendar(trip) {
  let calendar = {}
  let tripCalendar = {}
  let calendarOfDestinations = {}
  let tripDestinations = []
  if (trip.dailyAllowanceInfo.destination) {
    // SHOULDNT EXIST ANYMORE
    tripDestinations = [
      {
        destination: trip.dailyAllowanceInfo.destination,
        startDate: trip.startDate,
        endDate: trip.endDate
      }
    ]
  }
  if (trip.dailyAllowanceInfo.destinations) {
    tripDestinations = [...trip.dailyAllowanceInfo.destinations]
  }
  const { maxAllowance, nextTripOnLastDay } = trip.dailyAllowanceInfo

  let end = moment(trip.endDate)
  calendarOfDestinations = createCalendarOfDestinations(tripDestinations)
  tripCalendar = calculateAllowanceForEachDay(calendarOfDestinations, maxAllowance)
  mergeCalendars(calendar, tripCalendar)

  // IF NEXT TRIP WILL START AS THIS ONE ENDS, ALLOWANCE FOR THIS TRIP LAST DAY IS 0
  if (nextTripOnLastDay) {
    let month = setMonth(end)
    let day = setDay(end)
    calendar[month][day].perDiem = 0
  }

  return calendar
}

function createPreviousTripCalendar(trips) {
  const calendarOfPreviousTrips = {}
  trips.forEach((trip) => {
    let start = trip.startDate
    let end = trip.endDate
    let startingDate = moment(start)
    let tripDayCount = moment(end).diff(moment(start), 'day') + 1

    times(tripDayCount, (dayOfTrip) => {
      let currentDay = startingDate.clone().add(dayOfTrip, 'day')
      let date = setDay(currentDay)
      let month = setMonth(currentDay)
      if (!calendarOfPreviousTrips[month]) calendarOfPreviousTrips[month] = {}
      calendarOfPreviousTrips[month][date] = true
    })
  })

  return calendarOfPreviousTrips
}

function setDay(date) {
  return moment(date, 'YYYY-MM-DD').format('DD').toString()
}

function setMonth(date) {
  return moment(date, 'YYYY-MM-DD').format('YYYY-MM').toString()
}
