import mutations from './accounting-settings.mutations'
import actions from './accounting-settings.actions'
import getters from './accounting-settings.getters'
import state from './accounting-settings.state'

export default {
  mutations,
  actions,
  getters,
  state
}