import type { MutationMap } from './mutations'
import type { GetterMap } from './getters'
import type { ActionMap } from './actions'
import { CustomStoreModule, ModuleMap } from './modules'
import { ActionTree, createStore, GetterTree, Module, ModuleTree, MutationTree, StoreOptions } from 'vuex'
import { CustomVuexStore } from './store'

export function createVuexStoreRoot<
  TRootState,
  TRootGetters extends GetterMap<TRootState, TRootGetters, TRootState, TRootGetters>,
  TRootMutations extends MutationMap<TRootState>,
  TRootActions extends ActionMap<TRootState, TRootGetters, TRootMutations, TRootActions, any, any, any, any>
>(root: { state?: TRootState; getters?: TRootGetters; mutations?: TRootMutations; actions?: TRootActions }) {
  function createModule<
    TPrefix extends string,
    TState,
    TGetters extends GetterMap<TRootState, TRootGetters, TState, TGetters>,
    TMutations extends MutationMap<TState>,
    TActions extends ActionMap<
      TRootState,
      TRootGetters,
      TRootMutations,
      TRootActions,
      TState,
      TGetters,
      TMutations,
      TActions
    >
  >(
    prefix: TPrefix,
    module: {
      namespaced?: boolean
      state: TState
      getters?: TGetters
      mutations?: TMutations
      actions?: TActions
    }
  ): CustomStoreModule<TRootState, TState, TGetters, TMutations, TActions> {
    // @ts-ignore 
    return module
  }

  function mount<TModules extends ModuleMap<TRootState, TRootGetters, TRootMutations, TRootActions, TModules>>(
    options: Omit<StoreOptions<TRootState>, 'modules'> & { modules: TModules }
  ) {
    const store = createStore({
      ...options,
      state: root.state,
      getters: root.getters as GetterTree<TRootState, TRootState> | undefined,
      mutations: root.mutations as MutationTree<TRootState>,
      actions: root.actions as unknown as ActionTree<TRootState, TRootState>,
      modules: options.modules as ModuleTree<TRootState>,
    })

    return store as unknown as CustomVuexStore<TRootState, TRootGetters, TRootMutations, TRootActions, TModules>
  }

  return { createModule, mount }
}
