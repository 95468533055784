module.exports = {
  AL: 322,
  DZ: 388,
  AO: 530,
  AG: 834,
  AR: 290,
  AM: 557,
  AU: 817,
  AZ: 471,
  BS: 1267,
  BH: 994,
  BD: 499,
  BB: 1125,
  BE: 894,
  BZ: 681,
  BJ: 600,
  BO: 432,
  BW: 407,
  BR: 505,
  BG: 431,
  BF: 478,
  CF: 501,
  CL: 575,
  CO: 343,
  CR: 772,
  CY: 682,
  DK: 1274,
  DJ: 697,
  EC: 703,
  EG: 290,
  SV: 574,
  ER: 504,
  EE: 611,
  ET: 310,
  PH: 563,
  FI: 948,
  FR: 935,
  AE: 1060,
  GA: 731,
  GM: 321,
  GE: 381,
  GH: 514,
  GR: 728,
  GD: 706,
  GL: 1274,
  GY: 653,
  HT: 789,
  HN: 530,
  IN: 377,
  ID: 488,
  IQ: 730,
  IR: 493,
  IE: 1011,
  IS: 1064,
  IL: 921,
  IT: 856,
  JM: 506,
  JP: 486,
  JO: 950,
  KH: 593,
  CM: 554,
  CA: 1009,
  KZ: 378,
  KE: 435,
  CN: 688,
  KG: 290,
  HR: 510,
  CU: 373,
  KW: 928,
  LA: 290,
  LV: 687,
  LB: 493,
  LR: 752,
  LY: 290,
  LI: 1147,
  LT: 599,
  LU: 977,
  MO: 688,
  MG: 290,
  MW: 403,
  MY: 319,
  MV: 477,
  ML: 511,
  MT: 655,
  MA: 470,
  MR: 404,
  MU: 432,
  MX: 668,
  FM: 619,
  MD: 389,
  MC: 1009,
  MN: 504,
  ME: 416,
  NL: 792,
  NP: 312,
  NI: 542,
  NE: 387,
  NG: 435,
  MK: 320,
  NO: 1104,
  NZ: 656,
  OM: 977,
  PK: 290,
  PA: 779,
  PG: 662,
  PY: 352,
  PE: 504,
  PL: 562,
  PT: 613,
  PR: 1168,
  QA: 967,
  RO: 424,
  RW: 290,
  RU: 544,
  SM: 856,
  SA: 1220,
  CH: 1365,
  SN: 652,
  RS: 453,
  SC: 1092,
  SL: 290,
  SG: 959,
  SK: 691,
  SI: 592,
  ES: 691,
  LK: 471,
  SD: 701,
  SZ: 290,
  ZA: 318,
  KR: 681,
  TW: 615,
  TZ: 413,
  TH: 563,
  CZ: 735,
  TG: 567,
  TO: 519,
  TT: 960,
  TN: 290,
  TR: 290,
  TM: 1649,
  DE: 800,
  UG: 422,
  UA: 393,
  HU: 666,
  UY: 624,
  US: 1168,
  UZ: 290,
  VU: 743,
  VE: 420,
  VN: 433,
  ZM: 425,
  ZW: 660,
  AT: 746,
  BY: 290,
  BA: 377,
  BN: 551,
  CI: 766,
  GN: 683,
  HK: 688,
  CG: 680,
  CD: 697,
  XK: 290,
  MZ: 438,
  MM: 290,
  VC: 564,
  WS: 649,
  GB: 914,
  LC: 789,
  SE: 290
}
