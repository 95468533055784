import Axios from 'axios'
import { ACCOUNTING_ENTRY_REFERENCE_TYPE, ACCOUNTING_STORE_ACTIONS, ACCOUNTING_STORE_MUTATIONS } from '@/enums'
import { AccountingEntry, AccountingRef } from '@/models'

export default {
  [ACCOUNTING_STORE_ACTIONS.GET_RECEIPT_ACCOUNTING_ENTRIES]: async (
    { commit }: any,
    payload: any
  ): Promise<AccountingEntry[]> => {
    try {
      const { companyId, receiptId } = payload

      const url = `@coreapi/companies/${companyId}/accounting/document/${receiptId}/entries`

      const { data: accountingEntries = [] } = await Axios.get(url)

      commit(ACCOUNTING_STORE_MUTATIONS.SET_RECEIPT_ACCOUNTING_ENTRIES, { accountingEntries, receiptId })

      return accountingEntries
    } catch (error) {
      throw error
    }
  },
  [ACCOUNTING_STORE_ACTIONS.GET_TRIP_ACCOUNTING_ENTRIES]: async (
    { commit }: any,
    payload: any
  ): Promise<AccountingEntry[]> => {
    try {
      const { companyId, tripId } = payload

      const url = `@coreapi/companies/${companyId}/accounting/report/${tripId}/entries`

      const { data: accountingEntries = [] } = await Axios.get(url)

      commit(ACCOUNTING_STORE_MUTATIONS.SET_TRIP_ACCOUNTING_ENTRIES, { accountingEntries, tripId })

      // ALSO SORT AND SAVE TO STORE ACCOUNTS FOR EACH RECEIPT
      const sortedByReceiptIds: any = {}
      accountingEntries.forEach((accountingEntry: AccountingEntry) => {
        accountingEntry.references.forEach((reference) => {
          if (reference.type === ACCOUNTING_ENTRY_REFERENCE_TYPE.RECEIPT) {
            if (!sortedByReceiptIds[reference.id]) {
              sortedByReceiptIds[reference.id] = []
            }
            sortedByReceiptIds[reference.id].push(accountingEntry)
          }
        })
      })

      Object.keys(sortedByReceiptIds).forEach((receiptId) => {
        commit(ACCOUNTING_STORE_MUTATIONS.SET_RECEIPT_ACCOUNTING_ENTRIES, {
          accountingEntries: sortedByReceiptIds[receiptId],
          receiptId
        })
      })

      return accountingEntries
    } catch (error) {
      throw error
    }
  },
  [ACCOUNTING_STORE_ACTIONS.GET_RECEIPT_ACCOUNTING_REFS]: async (
    { commit }: any,
    payload: any
  ): Promise<AccountingRef[]> => {
    try {
      const { companyId, receiptId } = payload

      const url = `@coreapi/companies/${companyId}/accounting/refs?receiptId=${receiptId}`

      const { data: receiptAccountingRefs = [] } = await Axios.get(url)

      commit(ACCOUNTING_STORE_MUTATIONS.SET_RECEIPT_ACCOUNTING_REFS, { receiptAccountingRefs, receiptId })

      return receiptAccountingRefs
    } catch (error) {
      throw error
    }
  },
  [ACCOUNTING_STORE_ACTIONS.GET_TRIP_ACCOUNTING_REFS]: async (
    { commit }: any,
    payload: any
  ): Promise<AccountingRef[]> => {
    try {
      const { companyId, tripId } = payload

      const url = `@coreapi/companies/${companyId}/accounting/refs?tripId=${tripId}`

      const { data: tripAccountingRefs = [] } = await Axios.get(url)

      commit(ACCOUNTING_STORE_MUTATIONS.SET_TRIP_ACCOUNTING_REFS, { tripAccountingRefs, tripId })

      return tripAccountingRefs
    } catch (error) {
      throw error
    }
  },
  [ACCOUNTING_STORE_ACTIONS.UPDATE_ACCOUNTING_REF]: async ({ commit }: any, payload: any): Promise<AccountingRef> => {
    try {
      const { companyId, accountingObjectId, baseRefKey, baseRefId, options } = payload

      const url = `@coreapi/companies/${companyId}/accounting/${accountingObjectId}/refs?${baseRefKey}=${baseRefId}`

      const { data: updatedRef = {} } = await Axios.put(url, options)

      if (baseRefKey === 'tripId') {
        commit(ACCOUNTING_STORE_MUTATIONS.SET_TRIP_ACCOUNTING_REFS, {
          tripAccountingRefs: [updatedRef],
          tripId: baseRefId
        })
      }

      if (baseRefKey === 'receiptId') {
        commit(ACCOUNTING_STORE_MUTATIONS.SET_RECEIPT_ACCOUNTING_REFS, {
          receiptAccountingRefs: [updatedRef],
          receiptId: baseRefId
        })
      }

      return updatedRef
    } catch (error) {
      throw error
    }
  },
  [ACCOUNTING_STORE_ACTIONS.DELETE_RECEIPT_ACCOUNTING_REF]: async ({ commit }: any, payload: any): Promise<boolean> => {
    try {
      const { companyId, receiptId, accountingRef } = payload

      if (!accountingRef?.accounting?.id) return false

      const requestData = {
        data: {
          refType: accountingRef.refType,
          refId: accountingRef.refId
        }
      }

      const url = `@coreapi/companies/${companyId}/accounting/${accountingRef.accounting.id}/refs?receiptId=${receiptId}`

      const { data } = await Axios.delete(url, requestData)

      commit(ACCOUNTING_STORE_MUTATIONS.DELETE_RECEIPT_ACCOUNTING_REF, { receiptId, accountingRefId: accountingRef.id })

      return true
    } catch (error) {
      throw error
    }
  },
  [ACCOUNTING_STORE_ACTIONS.DELETE_TRIP_ACCOUNTING_REF]: async ({ commit }: any, payload: any): Promise<boolean> => {
    try {
      const { companyId, tripId, accountingRef } = payload

      if (!accountingRef?.accounting?.id) return false

      const requestData = {
        data: {
          refType: accountingRef.refType,
          refId: accountingRef.refId
        }
      }

      const url = `@coreapi/companies/${companyId}/accounting/${accountingRef.accounting.id}/refs?tripId=${tripId}`

      const { data } = await Axios.delete(url, requestData)

      commit(ACCOUNTING_STORE_MUTATIONS.DELETE_TRIP_ACCOUNTING_REF, { tripId, accountingRefId: accountingRef.id })

      return true
    } catch (error) {
      throw error
    }
  }
}
