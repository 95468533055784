<template>
  <svg :width="width" :height="height" :viewBox="viewBox" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 12H12C12.55 12 13 11.55 13 11C13 10.45 12.55 10 12 10H1C0.45 10 0 10.45 0 11C0 11.55 0.45 12 1 12ZM1 7H9C9.55 7 10 6.55 10 6C10 5.45 9.55 5 9 5H1C0.45 5 0 5.45 0 6C0 6.55 0.45 7 1 7ZM0 1C0 1.55 0.45 2 1 2H12C12.55 2 13 1.55 13 1C13 0.45 12.55 0 12 0H1C0.45 0 0 0.45 0 1Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'black'
    },
    viewBox: {
      type: String,
      default: '0 0 14 14'
    },
    width: {
      type: String,
      default: '16px'
    },
    height: {
      type: String,
      default: '16px'
    }
  }
}
</script>
