import { computed } from 'vue'
import moment from 'moment'
import Axios from 'axios'
import { useContext, useInstanceHelpers } from '@/composables'
import { useReceiptCRUD } from '@/app-composables'
import xStore from '@/store'

export default function useReceiptUpload() {
  const { root } = useContext()
  const { t } = useInstanceHelpers()
  const { createReceipt } = useReceiptCRUD()

  const companyId = computed(() => root?.$route.params.companyId)
  const tripId = computed(() => root?.$route.params.tripId)
  const mileageId = computed(() => root?.$route.params.mileageId)
  //@ts-ignore
  const companySteps = computed(() => root?.$stash.companies[companyId.value]?.steps || ['billingType'])

  const alertAllowAccess = (type: string) => {
    // @ts-ignore
    const message: any = {
      camera: t('pleaseAllowAccessToCamera'),
      gallery: t('pleaseAllowAccessToGallery')
    }[type]

    root?.$snackbar(message, 'error', 6000)
  }

  const setReceiptUploadingWarning = (receiptId: any, isBeingUploaded: Boolean) => {
    if (isBeingUploaded) {
      // @ts-ignore
      if (root?.$config.uploadingReceipt) {
        // @ts-ignore
        root?.$set(root.$config.uploadingReceipt, receiptId, true)
      } else {
        root?.$set(root.$config, 'uploadingReceipt', { [receiptId]: true })
      }
    } else {
      // @ts-ignore
      root?.$delete(root.$config.uploadingReceipt, receiptId)
    }
  }

  const goToReceiptFirstStep = (receipt: any) => {
    if (receipt.docType === 'nonexpense' && receipt.docSubType === 'carRegistrationCard' && root?.$route.query?.onlyRegCard) {
      root?.$router.push(`/pocket/${companyId.value}/report/${tripId.value}`)
    } else if (receipt.docType === 'nonexpense' && receipt.docSubType === 'carRegistrationCard') {
      root?.$router.push(`/pocket/${companyId.value}/report/${tripId.value}/km/${mileageId.value}/odometer`)
    } else if (receipt.docType === 'nonexpense') {
      // ATTACHMENT
      root?.$router.push(`/pocket/${companyId.value}/report/${tripId.value}/edit/${receipt.id}/comment`)
    } else {
      let [firstCompanyStep] = companySteps.value
      const reportRoute = tripId.value ? `/report/${tripId.value}` : ''
      if (!firstCompanyStep) {
        firstCompanyStep = 'none'
      }
      root?.$router.push(`/pocket/${companyId.value}${reportRoute}/edit/${receipt.id}/${firstCompanyStep}`)
    }

    setTimeout(() => {
      root?.$set(root.$config, 'incoming', false)
    }, 4000)
  }

  const processOcr = (receipt: any, data: any) => {

    /**
     * You are not absolutely allowed to overwrite receipt data after OCR results are returned, ( only for receipt.ocr )
     * as the server processed them based on company information and validation criteria.
     * The returned receipt already contains the processed (and validated) data.
     * Therefore use receipt.* and not receipt.ocr.* as base results
     */
    // @ts-ignore
    const tReceipt = root?.$stash.receipts[companyId.value][receipt.id]
    root?.$set(tReceipt, 'ocr10', {})
    tReceipt.currency = data.currency
    tReceipt.status = data.status
    if (data.ocr.currency && data.ocr.currency.source) {
      tReceipt.ocr10.currency = data.ocr.currency.source
    }

    // SET ITEM ROWS
    tReceipt.itemRows = data.itemRows || []

    // SET SUM
    if (data.sum) {
      tReceipt.sum = data.sum
    }

    // SET SUPPLIER NAME AND REG CODE
    if (data.supplier) {
      tReceipt.supplier = data.supplier
      tReceipt.supplierRegNumber = data.supplierRegNumber
      tReceipt.supplierVATNumber = data.supplierVATNumber

      if (data.ocr['supplier']) {
        var rd = data.ocr['supplier'].find((s: any) => {
          return (
            (s.entityId && s.entityId === data.supplierRegNumber) || (s.vatId && s.vatId === data.supplierVATNumber)
          )
        })
        if (rd) {
          tReceipt.ocr10.supplier = rd.source
          tReceipt.ocr10.supplierRegNumber = rd.source
          tReceipt.ocr10.supplierVATNumber = rd.source
        }
      }
    }

    // SET DOCUMENTID
    if (data.ocr['documentInfo'] && data.ocr['documentInfo']['documentId']) {
      tReceipt.documentId = data.ocr['documentInfo']['documentId'].value
      tReceipt.ocr10.documentId = data.ocr['documentInfo']['documentId'].source
    }

    // SET ISSUED
    if (data.issued) {
      tReceipt.issued = moment(data.issued, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD').toString()
      if (data.ocr['issued'] && data.ocr['issued']['source']) {
        tReceipt.ocr10.issued = data.ocr['issued']['source']
      }
    }

    // SET ORIGIN COUNTRY
    if (data.ocr['origin'] && data.ocr['origin']['country']) {
      tReceipt.ocr10.cc = data.ocr['origin']['country']
    }

    // SET ORIGIN COUNTRY
    if (data.ocr['wordList']) {
      tReceipt.ocr10['wordList'] = data.ocr['wordList']
    }
    // MARK OCR AS PROCESSED
    root?.$set(tReceipt, 'ocrProgress', -1)
  }

  const receiveOcr = (receipt: any) => {
    const isReceiptOcrActive = xStore.getters['Requests/IS_PROCESS_ACTIVE']({
      target: 'GET_OCR',
      receiptId: receipt.id
    })

    if (isReceiptOcrActive) {
      return
    }

    const countdownResolution = 1000 // MS
    let countDownTime = 20 // SECONDS

    const lowerfileName = receipt.fileId && receipt.fileId.toLowerCase()
    if (lowerfileName && lowerfileName.endsWith('.pdf')) {
      countDownTime = 40 // SECONDS
    }

    let countDownValue = countDownTime
    // @ts-ignore
    if (root.$stash.receipts[companyId.value][receipt.id]) {
      // @ts-ignore
      root?.$set(root.$stash.receipts[companyId.value][receipt.id], 'ocrProgress', 20)
    }


    // end result == -1 -> waiting should end
    // right now user has to wait for an extra second when there are no results
    let countdownInterval: any
    let countdownEnded = false

    function stopCountDown() {
      countdownEnded = true
      countDownValue = -1
      clearInterval(countdownInterval)
      // @ts-ignore
      if (root.$stash.receipts[companyId.value][receipt.id]) {
        // @ts-ignore
        root?.$set(root.$stash.receipts[companyId.value][receipt.id], 'ocrProgress', countDownValue)
      }
    }

    countdownInterval = setInterval(() => {
      countDownValue--

      // Disabling the self-termination here, since otherwise we have
      // two places that manage the countdown, which may, theoretically,
      // introduce unforeseen code flow. Feel free to delete the disabled
      // code once the OCR results page seems stable.

      // Further commentary. Self-termination has been re-enabled.
      // The key difference here is that self termination will occur when the countDownTime has exceeded an extra second,
      // that is a second longer than the maximum wait time of OCR results from the server.
      // Thus if the latter should fail for any reason, then this will act as a fallback mechanism.
      if (countDownValue < -2 && !countdownEnded) {
        stopCountDown()
        return
      }
      // @ts-ignore
      root?.$set(root.$stash.receipts[companyId.value][receipt.id], 'ocrProgress', Math.max(0, countDownValue))
    }, countdownResolution)

    setReceiptUploadingWarning(receipt.id, false)

    xStore
      .dispatch('Receipts/GET_OCR', {
        receiptId: receipt.id,
        companyId: companyId.value,
        timeout: countDownTime * 1000
      })
      .then((result: any) => {
        stopCountDown()
        const _receipt = result

        // @ts-ignore
        root?.$set(root.$stash.receipts[companyId.value][receipt.id], 'ocrProgress', -1)
        processOcr(receipt, _receipt)
      })
      .catch(() => {
        // TIMEOUT EXCEEDED
        stopCountDown()
      })
  }

  const uploadReceiptX = async(receipt: any, fileName: string) => {
    setReceiptUploadingWarning(receipt.id, true)

    // TODO: eventBus.$expect(RECEIPT_UPLOADED)

    async function _performUpload(didError: any) {
      try {
        var {data} = await Axios.get('/data/' + fileName, {responseType: 'arraybuffer'})

        var formData = new FormData()
        var blob = new Blob([data])

        formData.append('file', blob, fileName)

        await uploadReceipt(receipt, formData)
      }
      catch(e) {
        console.error(e)
        didError()
      }
    }

    // @ts-ignore
    let uploadURI = `${root?.$config?.services.coreapi.url}/companies/${companyId.value}/receipts/${receipt.id}/image?access_token=${localStorage.getItem('token')}`

    async function _performUploadNative(didError: any) {
      // @ts-ignore
      xcall('uploadFile', fileName, 'POST ' + uploadURI, (progress) => {
        switch (progress) {
          case -1:
            // either method was called incorrectly or file doesn't exist
            root?.$snackbar('Image upload failed', 'error', 6000)
            break
          case null:
            // upload ended with an error
            // #TODO add to offline sync
            root?.$snackbar('Image upload failed', 'error', 6000)
            progress = -1
            didError()
            break
          case 200:
            // upload ended with success
            // wait for OCR results
            // @ts-ignore
            root?.$set(root.$stash.receipts[companyId.value][receipt.id], 'status', 1)
            // TODO: eventBus.$fulfill(RECEIPT_UPLOADED, receipt.id)
            receiveOcr.call(t, receipt)
            break
          default:
            // progress is actual upload progress (1 to 100)
            // @ts-ignore
            root?.$set(root?.$stash.receipts[companyId.value][receipt.id], 'uploadProgress', progress)
        }
      })
    }

    const maxNumberOfTries = 2
    var numberOfTries = 0

    async function _nextTry() {
      // @ts-ignore
      root?.$set(root.$stash.receipts[companyId.value][receipt.id], 'uploadProgress', 0)

      numberOfTries++
      if (numberOfTries < maxNumberOfTries) {
        // iOS has a bug and needs an update
        // @ts-ignore
        if (window.X_PLATFORM === 'iOS' && window.X_VERSION_ID <= 240) _performUploadNative(_nextTry)
        else await _performUpload(_nextTry)
        return
      }
      // although upload failed, at least allow the user to continue
      setReceiptUploadingWarning(receipt.id, false)
      // @ts-ignore
      root?.$set(root.$stash.receipts[companyId.value][receipt.id], 'uploadProgress', -1)
      // @ts-ignore
      root?.$set(root.$stash.receipts[companyId.value][receipt.id], 'ocrProgress', -1)
    }

    await _nextTry()
  }

  const uploadReceipt = async (receipt: any, formData: any) => {
    try {
      setReceiptUploadingWarning(receipt.id, true)

      // NOT WORKING
      const config = {
        onUploadProgress: (progressEvent: any) => {
          // @ts-ignore
          const progress = Math.ceil((loaded * 100) / total)
          // @ts-ignore
          if (root?.$stash.receipts[companyId.value][receipt.id]) {
            // @ts-ignore
            root.$set(root.$stash.receipts[companyId.value][receipt.id], 'uploadProgress', progress)
          }
        }
      }

      // TODO: eventBus.$expect(RECEIPT_UPLOADED)

      await Axios.post(`@coreapi/companies/${companyId.value}/receipts/${receipt.id}/image`, formData, config)

      // TODO: eventBus.$fulfill(RECEIPT_UPLOADED, receipt.id)

      // @ts-ignore
      root?.$set(root.$stash.receipts[companyId.value][receipt.id], 'status', 1)
      // @ts-ignore
      root?.$set(root.$stash.receipts[companyId.value][receipt.id], 'uploadProgress', 100)

      // ATTACHMENT
      if (receipt.docType === 'nonexpense') {
        setReceiptUploadingWarning(receipt.id, false)
        return
      }

      receiveOcr(receipt)
    } catch (error: any) {
      setReceiptUploadingWarning(receipt.id, false)
      // @ts-ignore
      root?.$set(root.$stash.receipts[companyId.value][receipt.id], 'uploadProgress', -1)
      if (error.response?.data?.codename === 'UNSUPPORTED_FILE_TYPE') {
        const route = tripId.value
          ? `/pocket/${companyId.value}/report/${tripId.value}`
          : `/pocket/${companyId.value}/main/receipts`
        root?.$router.push(route)
      }
      root?.$snackbar(root.eh(error), 'error')
    }
  }

  const addReceiptX = (fileName: string, source: string, initialPayload: any) => {
    root?.$set(root.$config, 'incoming', true)

    const payload: any = {
      fileId: fileName
    }

    if (initialPayload) {
      Object.assign(payload, initialPayload)
    }

    return createReceipt(companyId.value, tripId.value, payload)
      .then((receipt) => {
        goToReceiptFirstStep(receipt)

        receipt.uploadProgress = 0
        // @ts-ignore
        root?.$set(root.$stash.receipts[companyId.value], receipt.id, receipt)

        // THIS SEEM TO BE NOT USED
        // this.setImagePreviewX.call(this, receipt, fileName, source)

        uploadReceiptX(receipt, fileName)

        return receipt
      })
      .catch((error: any) => {
        if (error === 'monthlyThreshold5Exceeded') {
          root?.$set(root.$config, 'incoming', false)
          return
        }

        root?.$snackbar(root.eh(error), 'error')
      })
  }

  const addReceipt = (e: Event, initialPayload: any = {}) => {
    root?.$set(root.$config, 'incoming', true)

    const payload = {}

    if (initialPayload) {
      Object.assign(payload, initialPayload)
    }

    return createReceipt(companyId.value, tripId.value, payload)
      .then((receipt) => {
        receipt.uploadProgress = 0
        // @ts-ignore
        root?.$set(root.$stash.receipts[companyId.value], receipt.id, receipt)

        goToReceiptFirstStep(receipt)

        const formData = new FormData()
        const target = e.target as HTMLInputElement
        const files = target.files
        const theImage = files ? files[0] : null
        if (theImage) {
          formData.append('file', theImage)
        }

        uploadReceipt(receipt, formData)

        // ENABLE NEXT DOCUMENT
        target.value = ''

        // useless ???
        // TODO: this.setImagePreview.call(this, receipt, theImage)
        return receipt
      })
      .catch((error) => {
        if (error === 'monthlyThreshold5Exceeded') {
          root?.$set(root.$config, 'incoming', false)
          return
        }
        root?.$snackbar(root.eh(error), 'error')
      })
  }

  const openCamera = async (e: Event, initialPayload: any = {}) => {
    // @ts-ignore
    if (!X_HOST) return

    e.preventDefault()

    const fileName = await new Promise((resolve, reject) => {
      // @ts-ignore
      xcall('openCamera', (allowed: any, result: string) => {
        if (!allowed) {
          alertAllowAccess('camera')
          reject('')
        }

        if (!result) {
          reject('')
        }

        // result ~ (string) filename
        resolve(result)
      })
    })

    // @ts-ignore
    return await addReceiptX(fileName, 'camera', initialPayload)
  }

  const openGallery = async (e: Event, initialPayload: any = {}) => {
    // @ts-ignore
    if (!X_HOST) return

    e.preventDefault()

    const fileName = await new Promise((resolve, reject) => {
      // @ts-ignore
      xcall('openGallery', (allowed: any, result: any) => {
        if (!allowed) {
          alertAllowAccess('gallery')
          reject('')
        }

        if (!result) {
          reject('')
        }

        // result ~ { ImageName: 'original image name', FileName: 'filename where it is saved in the app' }
        resolve(result.FileName)
      })
    })

    // @ts-ignore
    return await addReceiptX(fileName, 'gallery', initialPayload)
  }

  return {
    alertAllowAccess,
    openCamera,
    openGallery,
    addReceipt
  }
}
