import type { Plugin as PluginType } from 'vue'
import translations from '@/lib/l10n'
import store from '@/store'

import { handleError } from '@/lib/handler'

import { USERS_STORE_GETTERS } from '@/enums'
import { computed } from 'vue'

export function getLanguageISO3() {
  return store.getters[USERS_STORE_GETTERS.ACTIVE_USER]?.lang
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    t: TranslateFn
  }
}

export type TranslateFn = (
  languageKey: string,
  params?: { [key: PropertyKey]: any },
  config?: { [key: PropertyKey]: any }
) => string

const Plugin: PluginType = (app) => {
  const language = computed(() => {
    const stashUserLang = app.config.globalProperties?.$stash?.user?.lang
    const storeUserLang = getLanguageISO3()
    return stashUserLang || localStorage.language || storeUserLang || 'eng'
  })

  const t: TranslateFn = (languageKey, params = {}, config = {}) => {
    if (!languageKey) return ''

    // @ts-ignore
    let foundTranslation =
    // @ts-ignore      
      translations[languageKey]?.[language.value] || translations[languageKey]?.eng || languageKey

    if (!foundTranslation) {
      handleError('Missing translation for key "' + languageKey + '"')

      return languageKey
    }

    if (params && foundTranslation) {
      Object.keys(params).forEach((key) => {
        foundTranslation = foundTranslation.replace('$' + key, params[key])
      })
    }

    return foundTranslation
  }

  app.config.globalProperties.t = t
}

export default Plugin