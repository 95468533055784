import Axios from 'axios'
import { APPROVAL_SETTINGS_STORE_ACTIONS, APPROVAL_SETTINGS_STORE_MUTATIONS } from '@/enums'

export default {
  [APPROVAL_SETTINGS_STORE_ACTIONS.GET_WORKFLOWS]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId } = payload

      const { data: workflows = [] } = await Axios.get(`@coreapi/companies/${companyId}/approvals/workflows`)

      commit(APPROVAL_SETTINGS_STORE_MUTATIONS.SET_APPROVAL_WORKFLOWS, { workflows, companyId })

      return workflows
    } catch (error) {
      throw error
    }
  },
  [APPROVAL_SETTINGS_STORE_ACTIONS.GET_WORKFLOW]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId, workflowId } = payload

      const { data: workflow = {} } = await Axios.get(
        `@coreapi/companies/${companyId}/approvals/workflows/${workflowId}`
      )

      commit(APPROVAL_SETTINGS_STORE_MUTATIONS.SET_APPROVAL_WORKFLOW, { workflow, companyId })

      return workflow
    } catch (error) {
      throw error
    }
  },
  [APPROVAL_SETTINGS_STORE_ACTIONS.UPDATE_WORKFLOW]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId, workflowId, workflowPayload } = payload

      const { data: workflow = {} } = await Axios.put(
        `@coreapi/companies/${companyId}/approvals/workflows/${workflowId}`,
        workflowPayload
      )

      commit(APPROVAL_SETTINGS_STORE_MUTATIONS.SET_WORKFLOW_VALUE, { workflowId, companyId, values: workflowPayload })

      return workflow
    } catch (error) {
      throw error
    }
  },
  [APPROVAL_SETTINGS_STORE_ACTIONS.GET_POLICIES]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId } = payload

      const { data: policies = [] } = await Axios.get(`@coreapi/companies/${companyId}/approvals/workflows/policies`)

      commit(APPROVAL_SETTINGS_STORE_MUTATIONS.SET_APPROVAL_POLICIES, { policies, companyId })

      return policies
    } catch (error) {
      throw error
    }
  },
  [APPROVAL_SETTINGS_STORE_ACTIONS.GET_APPROVAL_OPTIONS]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId } = payload

      const { data: options = [] } = await Axios.get(`@coreapi/companies/${companyId}/approvals/workflows/options`)

      commit(APPROVAL_SETTINGS_STORE_MUTATIONS.SET_APPROVAL_OPTIONS, { options, companyId })

      return options
    } catch (error) {
      throw error
    }
  },
  [APPROVAL_SETTINGS_STORE_ACTIONS.CREATE_WORKFLOW]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId, newWorkflowPayload } = payload

      const { data: workflow = {} } = await Axios.post(`@coreapi/companies/${companyId}/approvals/workflows`, newWorkflowPayload)

      commit(APPROVAL_SETTINGS_STORE_MUTATIONS.SET_APPROVAL_WORKFLOW, { companyId, workflow })

      return workflow
    } catch (error) {
      throw error
    }
  },
  [APPROVAL_SETTINGS_STORE_ACTIONS.CREATE_POLICY]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId, newPolicy, url, workflowId, roundId, scope } = payload

      const { data: policy = {} } = await Axios.post(url, newPolicy)

      commit(APPROVAL_SETTINGS_STORE_MUTATIONS.SET_APPROVAL_POLICY, { policy, companyId, workflowId, roundId, scope })

      return policy
    } catch (error) {
      throw error
    }
  },
  [APPROVAL_SETTINGS_STORE_ACTIONS.CREATE_ROUND]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId, workflowId } = payload

      const { data: round = {} } = await Axios.post(
        `@coreapi/companies/${companyId}/approvals/workflows/${workflowId}/rounds`,
        {}
      )

      commit(APPROVAL_SETTINGS_STORE_MUTATIONS.SET_APPROVAL_ROUND, { companyId, workflowId, round })

      return round
    } catch (error) {
      throw error
    }
  },
  [APPROVAL_SETTINGS_STORE_ACTIONS.ADD_ROUND_APPROVER]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId, workflowId, roundId, approverPayload } = payload

      const { data = {} } = await Axios.post(
        `@coreapi/companies/${companyId}/approvals/workflows/${workflowId}/rounds/${roundId}/approvers`,
        approverPayload
      )

      return data
    } catch (error) {
      throw error
    }
  },
  [APPROVAL_SETTINGS_STORE_ACTIONS.DELETE_ROUND_APPROVER]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId, workflowId, roundId, approverId } = payload

      await Axios.delete(
        `@coreapi/companies/${companyId}/approvals/workflows/${workflowId}/rounds/${roundId}/approvers/${approverId}`
      )

      return true
    } catch (error) {
      throw error
    }
  },
  [APPROVAL_SETTINGS_STORE_ACTIONS.DELETE_ROUND]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId, workflowId, roundId } = payload

      await Axios.delete(`@coreapi/companies/${companyId}/approvals/workflows/${workflowId}/rounds/${roundId}`)

      return true
    } catch (error) {
      throw error
    }
  },
  [APPROVAL_SETTINGS_STORE_ACTIONS.DELETE_TARGET]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId, workflowId, targetId } = payload

      await Axios.delete(`@coreapi/companies/${companyId}/approvals/workflows/${workflowId}/targets/${targetId}`)

      return true
    } catch (error) {
      throw error
    }
  },
  [APPROVAL_SETTINGS_STORE_ACTIONS.DELETE_WORKFLOW]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId, workflowId } = payload

      await Axios.delete(`@coreapi/companies/${companyId}/approvals/workflows/${workflowId}`)
      commit(APPROVAL_SETTINGS_STORE_MUTATIONS.DELETE_WORKFLOW, { companyId, workflowId })

      return true
    } catch (error) {
      throw error
    }
  },
  [APPROVAL_SETTINGS_STORE_ACTIONS.DELETE_POLICY]: async ({ commit }: any, payload: any) => {
    try {
      const { url, scope, policyKey, companyId, policyId } = payload

      await Axios.delete(url, { data: { policyKey }})

      commit(APPROVAL_SETTINGS_STORE_MUTATIONS.DELETE_POLICY, { scope, companyId, policyId })

      return true
    } catch (error) {
      throw error
    }
  }
}
