const polandForeignTravelPerDiems = require('../data/polandPerDiems2023')
const moment = require('moment')
const { each, times, cloneDeep } = require('lodash-es')

// DOMESTIC
// up to 8 hours - no allowance /// 480 min
// from 8 to 12 hours - 50% of allowance
// more than 12 hours- full allowance /// 720 min
// breakfast - 25% of allowance,
// lunch - 50% of allowance,
// dinner - 25% of allowance

// accomodation not included = 150% ??? TODO:

// INTERNATIONAL
// up to 8 hours – 1/3 of the allowance,
// from 8 to 12 hours – 1/2 of the allowance,
// more than 12 hours – full allowance.
// deduct for breakfast 15% of the allowance,
// and dinner and supper constitute 30% of the allowance each.

// Default per diem rate 41 EUR

module.exports = function (currentTrip, previousTrips) {
  const sum = {}
  const { newTripCalendar, dataPerDestination } = createTripCalendar(currentTrip)
  const oldTripCalendar = createPreviousTripCalendar(previousTrips)
  const { overlappingDates, warning } = checkOverlappingDates(newTripCalendar, oldTripCalendar)

  return {
    sum,
    dataPerDestination,
    overlappingDates,
    warning
  }
}

function checkOverlappingDates(newTripCalendar, oldTripCalendar) {
  let warning = false
  const overlappingDates = {}
  each(newTripCalendar, (days, month) => {
    each(days, (_, day) => {
      if (oldTripCalendar[month] && oldTripCalendar[month][day]) {
        warning = true
        if (!overlappingDates[month]) overlappingDates[month] = []
        overlappingDates[month].push(day.replace(/^0+(?!$)/, ''))
      }
    })
  })

  each(overlappingDates, (_, month) => {
    overlappingDates[month].sort((a, b) => a - b)
  })

  return {
    overlappingDates,
    warning
  }
}

function createTripCalendar(trip) {
  const dataPerDestination = []
  const newTripCalendar = {}

  trip.destinations.forEach((visitedCountry) => {
    const resultData = cloneDeep(visitedCountry)
    const countryCode = visitedCountry.destination
    const start = convertToDate(visitedCountry.startDate, visitedCountry.startTime)
    const end = convertToDate(visitedCountry.endDate, visitedCountry.endTime)
    const fullDaysCount = moment(visitedCountry.endDate).diff(moment(visitedCountry.startDate), 'days') + 1

    let nextFullDay = moment(start)
    // create calendar for calculating overlapping dates
    times(fullDaysCount, (day) => {
      let month = getMonth(nextFullDay)
      let date = getDay(nextFullDay)
      if (!newTripCalendar[month]) newTripCalendar[month] = {}
      if (!newTripCalendar[month][date]) newTripCalendar[month][date] = true
      nextFullDay = nextFullDay.add(24, 'hours')
    })

    const timeSpentInDestination = end.diff(start, 'minutes')
    const fullAllowanceDays = end.diff(start, 'days')
    const exceededTime = timeSpentInDestination - fullAllowanceDays * 1440
    const daysHadBreakfast = visitedCountry.breakfastIncluded?.length || 0
    const daysHadLunch = visitedCountry.lunchIncluded?.length || 0
    const daysHadDinner = visitedCountry.dinnerIncluded?.length || 0

    const perDiemRate = findPerDiem(countryCode)
    let rate = perDiemRate.perDiem
    const lastDayProportion = findExceededTimeProportion(exceededTime, countryCode)
    const breakfastProportion = countryCode === 'PL' ? 0.25 : 0.15
    const lunchProportion = countryCode === 'PL' ? 0.5 : 0.3
    const dinnerProportion = countryCode === 'PL' ? 0.25 : 0.3

    let totalSum = Number(
      (
        fullAllowanceDays * rate -
        (daysHadBreakfast > fullAllowanceDays ? fullAllowanceDays : daysHadBreakfast) * breakfastProportion * rate -
        (daysHadLunch > fullAllowanceDays ? fullAllowanceDays : daysHadLunch) * lunchProportion * rate -
        (daysHadDinner > fullAllowanceDays ? fullAllowanceDays : daysHadDinner) * dinnerProportion * rate
      ).toFixed(2)
    )

    //partial day
    if (exceededTime > 0) {
      const reducedRate = lastDayProportion * rate
      totalSum =
        totalSum +
        Number(
          (
            reducedRate -
            (daysHadBreakfast > fullAllowanceDays ? 1 : 0) * breakfastProportion * reducedRate -
            (daysHadLunch > fullAllowanceDays ? 1 : 0) * lunchProportion * reducedRate -
            (daysHadDinner > fullAllowanceDays ? 1 : 0) * dinnerProportion * reducedRate
          ).toFixed(2)
        )
    }

    resultData.currency = perDiemRate.currency
    resultData.days = timeSpentInDestination / 1440
    resultData.sum = Number(totalSum.toFixed(2))
    dataPerDestination.push(resultData)
  })
  return {
    newTripCalendar,
    dataPerDestination
  }
}

function convertToDate(date, time) {
  return moment(date + ' ' + time, 'YYYY-MM-DDLT')
}

function getDay(date) {
  return moment(date, 'YYYY-MM-DD').format('DD').toString()
}

function getMonth(date) {
  return moment(date, 'YYYY-MM-DD').format('YYYY-MM').toString()
}

function findPerDiem(country) {
  // IF THE PER DIEMS LIST DIDN'T HAVE DESTINATION COUNTRY LISTED, DEFAULT IS 41 EUR
  let perDiem = 41
  let currency = 'EUR'
  let countryData = polandForeignTravelPerDiems[country]
  if (countryData) {
    perDiem = countryData.perDiem
    currency = countryData.currency
  }
  return { perDiem, currency }
}

function findExceededTimeProportion(exceededTime, country) {
  let proportion = 0
  if (exceededTime < 0) return proportion
  if (exceededTime < 480 && country !== 'PL') {
    proportion = 0.333333
  } else if (exceededTime >= 480 && exceededTime <= 720) {
    proportion = 0.5
  } else if (exceededTime > 720) {
    proportion = 1
  }
  return proportion
}

function createPreviousTripCalendar(trips) {
  const calendarOfPreviousTrips = {}

  trips.forEach((trip) => {
    let start = trip.startDate
    let end = trip.endDate
    let startingDate = moment(start)
    let tripDayCount = moment(end).diff(moment(start), 'day') + 1

    times(tripDayCount, (dayOfTrip) => {
      let currentDay = startingDate.clone().add(dayOfTrip, 'day')
      let date = setDay(currentDay)
      let month = setMonth(currentDay)
      if (!calendarOfPreviousTrips[month]) calendarOfPreviousTrips[month] = {}
      calendarOfPreviousTrips[month][date] = true
    })
  })

  return calendarOfPreviousTrips
}

function convertToDate(date, time) {
  return moment(date + ' ' + time, 'YYYY-MM-DDLT')
}

function setDay(date) {
  return moment(date, 'YYYY-MM-DD').format('DD').toString()
}

function setMonth(date) {
  return moment(date, 'YYYY-MM-DD').format('YYYY-MM').toString()
}
