export enum COST_TYPES_STORE_STATE {
  EXPENSE_TYPES = 'CostTypes/EXPENSE_TYPES'
}

export enum COST_TYPES_STORE_GETTERS {
  EXPENSE_TYPE_BY_ID = 'CostTypes/EXPENSE_TYPE_BY_ID',
  EXPENSE_TYPES = 'CostTypes/EXPENSE_TYPES'
}

export enum COST_TYPES_STORE_ACTIONS {
  GET_EXPENSE_TYPES = 'CostTypes/GET_EXPENSE_TYPES',
  CREATE_EXPENSE_TYPE = 'CostTyes/CREATE_EXPENSE_TYPE',
  UPDATE_EXPENSE_TYPE = 'CostTypes/UPDATE_EXPENSE_TYPE',
  DELETE_EXPENSE_TYPE = 'CostTypes/DELETE_EXPENSE_TYPE'
}

export enum COST_TYPES_STORE_MUTATIONS {
  SET_EXPENSE_TYPES = 'CostTypes/SET_EXPENSE_TYPES',
  SET_EXPENSE_TYPE = 'CostTypes/SET_EXPENSE_TYPE',
  DELETE_EXPENSE_TYPE = 'CostTypes/DELETE_EXPENSE_TYPE'
}
