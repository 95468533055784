<template>
  <div class="loading-circle" :class="{ 'initial-size': !size }" :style="{ width: size, height: size, 'border-width': border }"></div>
</template>

<script>
export default {
  name: 'UiLoder',
  props: {
    size: {
      type: String
    },
    border: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-circle {
  border-style: solid;
  border-color: #c2d6ff;
  border-top-color: $main-blue;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;

  &.initial-size {
    width: 80px;
    height: 80px;
    border-width: 4px;
  }
}
</style>
