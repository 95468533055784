import IconAccount from './IconAccount.vue'
import IconAdd from './IconAdd.vue'
import IconAddFile from './IconAddFile.vue'
import IconAddFolder from './IconAddFolder.vue'
import IconAdjust from './IconAdjust.vue'
import IconArchive from './IconArchive.vue'
import IconArrowRight from './IconArrowRight.vue'
import IconBank from './IconBank.vue'
import IconBoat from './IconBoat.vue'
import IconBriefcase from './IconBriefcase.vue'
import IconCalendar from './IconCalendar.vue'
import IconCancel from './IconCancel.vue'
import IconCar from './IconCar.vue'
import IconCard from './IconCard.vue'
import IconCash from './IconCash.vue'
import IconChevronDown from './IconChevronDown.vue'
import IconChevronLeft from './IconChevronLeft.vue'
import IconChevronRight from './IconChevronRight.vue'
import IconChevronUp from './IconChevronUp.vue'
import IconClose from './IconClose.vue'
import IconCollapse from './IconCollapse.vue'
import IconConversion from './IconConversion.vue'
import IconCopy from './IconCopy.vue'
import IconCup from './IconCup.vue'
import IconDelete from './IconDelete.vue'
import IconDocument from './IconDocument.vue'
import IconDone from './IconDone.vue'
import IconDots from './IconDots.vue'
import IconDownload from './IconDownload.vue'
import IconDoubleChevron from './IconDoubleChevron.vue'
import IconEnvelope from './IconEnvelope.vue'
import IconExit from './IconExit.vue'
import IconEye from './IconEye.vue'
import IconFacebook from './IconFacebook.vue'
import IconFilter from './IconFilter.vue'
import IconFolder from './IconFolder.vue'
import IconGlobe from './IconGlobe.vue'
import IconGoogle from './IconGoogle.vue'
import IconHail from './IconHail.vue'
import IconImportFile from './IconImportFile.vue'
import IconInfo from './IconInfo.vue'
import IconKanban from './IconKanban.vue'
import IconKey from './IconKey.vue'
import IconLines from './IconLines.vue'
import IconLinkedin from './IconLinkedin.vue'
import IconLink from './IconLink.vue'
import IconList from './IconList.vue'
import IconMatch from './IconMatch.vue'
import IconMenu from './IconMenu.vue'
import IconMessage from './IconMessage.vue'
import IconMicrosoft from './IconMicrosoft.vue'
import IconMileage from './IconMileage.vue'
import IconMinivan from './IconMinivan.vue'
import IconMoped from './IconMoped.vue'
import IconMotorcycle from './IconMotorcycle.vue'
import IconMove from './IconMove.vue'
import IconOverview from './IconOverview.vue'
import IconPaperclip from './IconPaperclip.vue'
import IconPayment from './IconPayment.vue'
import IconPencil from './IconPencil.vue'
import IconPeople from './IconPeople.vue'
import IconPerson from './IconPerson.vue'
import IconPhone from './IconPhone.vue'
import IconPhotoCamera from './IconPhotoCamera.vue'
import IconPhotos from './IconPhotos.vue'
import IconPlane from './IconPlane.vue'
import IconPlus from './IconPlus.vue'
import IconQuadbike from './IconQuadbike.vue'
import IconReceipt from './IconReceipt.vue'
import IconRemove from './IconRemove.vue'
import IconSave from './IconSave.vue'
import IconSearch from './IconSearch.vue'
import IconSettings from './IconSettings.vue'
import IconSnowmobile from './IconSnowmobile.vue'
import IconSync from './IconSync.vue'
import IconTrain from './IconTrain.vue'
import IconUnfold from './IconUnfold.vue'
import IconUpload from './IconUpload.vue'
import IconUploadFile from './IconUploadFile.vue'
import IconWallet from './IconWallet.vue'
import IconWarning from './IconWarning.vue'
import IconYoutube from './IconYoutube.vue'

export default {
  install: (app) => {
    app.component('icon-account', IconAccount)
    app.component('icon-add', IconAdd)
    app.component('icon-add-file', IconAddFile)
    app.component('icon-add-folder', IconAddFolder)
    app.component('icon-adjust', IconAdjust)
    app.component('icon-archive', IconArchive)
    app.component('icon-arrow-right', IconArrowRight)
    app.component('icon-bank', IconBank)
    app.component('icon-boat', IconBoat)
    app.component('icon-briefcase', IconBriefcase)
    app.component('icon-calendar', IconCalendar)
    app.component('icon-cancel', IconCancel)
    app.component('icon-car', IconCar)
    app.component('icon-card', IconCard)
    app.component('icon-cash', IconCash)
    app.component('icon-chevron-down', IconChevronDown)
    app.component('icon-chevron-left', IconChevronLeft)
    app.component('icon-chevron-right', IconChevronRight)
    app.component('icon-chevron-up', IconChevronUp)
    app.component('icon-close', IconClose)
    app.component('icon-collapse', IconCollapse)
    app.component('icon-conversion', IconConversion)
    app.component('icon-copy', IconCopy)
    app.component('icon-cup', IconCup)
    app.component('icon-delete', IconDelete)
    app.component('icon-document', IconDocument)
    app.component('icon-done', IconDone)
    app.component('icon-dots', IconDots)
    app.component('icon-download', IconDownload)
    app.component('icon-double-chevron', IconDoubleChevron)
    app.component('icon-envelope', IconEnvelope)
    app.component('icon-exit', IconExit)
    app.component('icon-eye', IconEye)
    app.component('icon-facebook', IconFacebook)
    app.component('icon-filter', IconFilter)
    app.component('icon-folder', IconFolder)
    app.component('icon-globe', IconGlobe)
    app.component('icon-google', IconGoogle)
    app.component('icon-hail', IconHail)
    app.component('icon-import-file', IconImportFile)
    app.component('icon-info', IconInfo)
    app.component('icon-kanban', IconKanban)
    app.component('icon-key', IconKey)
    app.component('icon-lines', IconLines)
    app.component('icon-linkedin', IconLinkedin)
    app.component('icon-link', IconLink)
    app.component('icon-list', IconList)
    app.component('icon-match', IconMatch)
    app.component('icon-menu', IconMenu)
    app.component('icon-message', IconMessage)
    app.component('icon-microsoft', IconMicrosoft)
    app.component('icon-mileage', IconMileage)
    app.component('icon-minivan', IconMinivan)
    app.component('icon-moped', IconMoped)
    app.component('icon-motorcycle', IconMotorcycle)
    app.component('icon-move', IconMove)
    app.component('icon-overview', IconOverview)
    app.component('icon-paperclip', IconPaperclip)
    app.component('icon-payment', IconPayment)
    app.component('icon-pencil', IconPencil)
    app.component('icon-people', IconPeople)
    app.component('icon-person', IconPerson)
    app.component('icon-phone', IconPhone)
    app.component('icon-photo-camera', IconPhotoCamera)
    app.component('icon-photos', IconPhotos)
    app.component('icon-plane', IconPlane)
    app.component('icon-plus', IconPlus)
    app.component('icon-quadbike', IconQuadbike)
    app.component('icon-receipt', IconReceipt)
    app.component('icon-remove', IconRemove)
    app.component('icon-save', IconSave)
    app.component('icon-search', IconSearch)
    app.component('icon-settings', IconSettings)
    app.component('icon-snowmobile', IconSnowmobile)
    app.component('icon-sync', IconSync)
    app.component('icon-train', IconTrain)
    app.component('icon-unfold', IconUnfold)
    app.component('icon-upload', IconUpload)
    app.component('icon-upload-file', IconUploadFile)
    app.component('icon-wallet', IconWallet)
    app.component('icon-warning', IconWarning)
    app.component('icon-youtube', IconYoutube)
  }
}

