<template>
  <div class="header-container" :class="{ transparent: isTransparent }">
    <div class="leftmost" :class="{ 'no-title': !pageTitle, 'wide-title': wideTitle }">
      <slot name="start"></slot>
    </div>
    <div v-if="pageTitle" class="central" :class="{ wide: wideTitle }">
      <p class="paragraph-md-regular">{{ pageTitle }}</p>
    </div>
    <div class="rightmost" :class="{ 'no-title': !pageTitle, 'wide-title': wideTitle }">
      <slot name="end"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiHeader',
  props: {
    pageTitle: {
      type: String
    },
    wideTitle: {
      type: Boolean,
      default: false
    },
    isTransparent: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.header-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  column-gap: 8px;

  position: -webkit-sticky;
  position: sticky;
  top: 0;

  min-height: 56px;
  // for handling ios transparent headers and "notch"
  padding-top: env(safe-area-inset-top);

  font-family: 'IBM Plex Sans';
  color: $white;
  background: $main-blue;

  z-index: $header-layer;

  &.transparent {
    background: $light-grey;
    color: $purple-grey;
  }

  svg {
    flex-shrink: 0;
  }
}

.central {
  justify-self: center;
  text-align: center;
  grid-column-end: span 2;
  padding: 15px 0;

  &.wide {
    grid-column-end: span 4;
  }
}

.rightmost {
  padding: 15px 16px 15px 0;
  justify-self: end;
}

.leftmost {
  justify-self: start;
}

.rightmost,
.leftmost {
  grid-column-end: span 2;

  &.no-title {
    grid-column-end: span 3;
  }

  &.wide-title {
    grid-column-end: span 1;
  }
}
</style>
