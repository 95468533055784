module.exports = {
  AF: { perDiem: 47, currency: 'EUR' },
  AL: { perDiem: 41, currency: 'EUR' },
  DZ: { perDiem: 50, currency: 'EUR' },
  AD: { perDiem: 50, currency: 'EUR' },
  AO: { perDiem: 61, currency: 'USD' },
  SA: { perDiem: 50, currency: 'EUR' },
  AR: { perDiem: 50, currency: 'USD' },
  AM: { perDiem: 42, currency: 'EUR' },
  AU: { perDiem: 95, currency: 'AUD' },
  AT: { perDiem: 57, currency: 'EUR' },
  AZ: { perDiem: 43, currency: 'EUR' },
  BD: { perDiem: 50, currency: 'USD' },
  BE: { perDiem: 55, currency: 'EUR' },
  BY: { perDiem: 42, currency: 'EUR' },
  BA: { perDiem: 41, currency: 'EUR' },
  BR: { perDiem: 43, currency: 'EUR' },
  BG: { perDiem: 40, currency: 'EUR' },
  CL: { perDiem: 60, currency: 'USD' },
  CN: { perDiem: 55, currency: 'EUR' },
  HR: { perDiem: 42, currency: 'EUR' },
  CY: { perDiem: 43, currency: 'EUR' },
  ME: { perDiem: 40, currency: 'EUR' },
  CZ: { perDiem: 41, currency: 'EUR' },
  DK: { perDiem: 446, currency: 'DKK' },
  CD: { perDiem: 66, currency: 'USD' },
  EG: { perDiem: 55, currency: 'USD' },
  EC: { perDiem: 44, currency: 'USD' },
  EE: { perDiem: 45, currency: 'EUR' },
  ET: { perDiem: 55, currency: 'USD' },
  FI: { perDiem: 53, currency: 'EUR' },
  FR: { perDiem: 55, currency: 'EUR' },
  GR: { perDiem: 50, currency: 'EUR' },
  GE: { perDiem: 48, currency: 'EUR' },
  ES: { perDiem: 50, currency: 'EUR' },
  NL: { perDiem: 50, currency: 'EUR' },
  IN: { perDiem: 42, currency: 'EUR' },
  ID: { perDiem: 41, currency: 'EUR' },
  IQ: { perDiem: 60, currency: 'USD' },
  IR: { perDiem: 41, currency: 'EUR' },
  IE: { perDiem: 52, currency: 'EUR' },
  IS: { perDiem: 56, currency: 'EUR' },
  IL: { perDiem: 70, currency: 'EUR' },
  JP: { perDiem: 7532, currency: 'JPY' },
  YE: { perDiem: 48, currency: 'USD' },
  JO: { perDiem: 50, currency: 'EUR' },
  KH: { perDiem: 45, currency: 'USD' },
  CA: { perDiem: 71, currency: 'CAD' },
  QA: { perDiem: 41, currency: 'EUR' },
  KZ: { perDiem: 45, currency: 'EUR' },
  KE: { perDiem: 41, currency: 'EUR' },
  KG: { perDiem: 41, currency: 'USD' },
  CO: { perDiem: 49, currency: 'USD' },
  KR: { perDiem: 46, currency: 'EUR' },
  CR: { perDiem: 50, currency: 'USD' },
  CU: { perDiem: 50, currency: 'EUR' },
  KW: { perDiem: 39, currency: 'EUR' },
  LA: { perDiem: 54, currency: 'USD' },
  LB: { perDiem: 57, currency: 'USD' },
  LY: { perDiem: 52, currency: 'EUR' },
  LI: { perDiem: 88, currency: 'CHF' },
  LT: { perDiem: 45, currency: 'EUR' },
  LU: { perDiem: 55, currency: 'EUR' },
  LV: { perDiem: 57, currency: 'EUR' },
  MY: { perDiem: 41, currency: 'EUR' },
  MT: { perDiem: 43, currency: 'EUR' },
  MA: { perDiem: 41, currency: 'EUR' },
  MX: { perDiem: 58, currency: 'USD' },
  MD: { perDiem: 45, currency: 'EUR' },
  MC: { perDiem: 55, currency: 'EUR' },
  MN: { perDiem: 45, currency: 'EUR' },
  DE: { perDiem: 49, currency: 'EUR' },
  NG: { perDiem: 46, currency: 'EUR' },
  NO: { perDiem: 496, currency: 'NOK' },
  NZ: { perDiem: 58, currency: 'USD' },
  OM: { perDiem: 40, currency: 'EUR' },
  PK: { perDiem: 38, currency: 'EUR' },
  PS: { perDiem: 70, currency: 'EUR' },
  PA: { perDiem: 52, currency: 'USD' },
  PE: { perDiem: 50, currency: 'USD' },
  ZA: { perDiem: 52, currency: 'USD' },
  PT: { perDiem: 49, currency: 'EUR' },
  RU: { perDiem: 48, currency: 'EUR' },
  RO: { perDiem: 42, currency: 'EUR' },
  SM: { perDiem: 53, currency: 'EUR' },
  SN: { perDiem: 44, currency: 'EUR' },
  RS: { perDiem: 40, currency: 'EUR' },
  SG: { perDiem: 56, currency: 'USD' },
  SK: { perDiem: 47, currency: 'EUR' },
  SI: { perDiem: 45, currency: 'EUR' },
  US: { perDiem: 59, currency: 'USD' },
  SY: { perDiem: 50, currency: 'USD' },
  CH: { perDiem: 88, currency: 'CHF' },
  SE: { perDiem: 510, currency: 'SEK' },
  TJ: { perDiem: 41, currency: 'EUR' },
  TH: { perDiem: 42, currency: 'USD' },
  TZ: { perDiem: 53, currency: 'USD' },
  TN: { perDiem: 37, currency: 'EUR' },
  TR: { perDiem: 53, currency: 'USD' },
  TM: { perDiem: 47, currency: 'EUR' },
  UA: { perDiem: 41, currency: 'EUR' },
  UY: { perDiem: 50, currency: 'USD' },
  UZ: { perDiem: 41, currency: 'EUR' },
  VA: { perDiem: 53, currency: 'EUR' },
  VE: { perDiem: 60, currency: 'USD' },
  HU: { perDiem: 44, currency: 'EUR' },
  GB: { perDiem: 45, currency: 'GBP' },
  VN: { perDiem: 53, currency: 'USD' },
  IT: { perDiem: 53, currency: 'EUR' },
  CI: { perDiem: 33, currency: 'EUR' },
  ZW: { perDiem: 39, currency: 'EUR' },
  AE: { perDiem: 43, currency: 'EUR' },
  PL: { perDiem: 30, currency: 'PLN' },
  TW: { perDiem: 40, currency: 'EUR' },
  HK: { perDiem: 55, currency: 'USD' },
  GI: { perDiem: 45, currency: 'GBP' }
}
