import { SETTING_STORE_MUTATIONS, SETTING_STORE_STATE } from '@/enums'

import { SettingStoreState } from '@/models'

export default {
  [SETTING_STORE_MUTATIONS.SET_ACTIVE_DATES]: (state: SettingStoreState, dates: Object) => {
    state[SETTING_STORE_STATE.ACTIVE_DATES] = dates
  },
  [SETTING_STORE_MUTATIONS.SET_LOADING_STATE]: (state: SettingStoreState, isLoading: Boolean) => {
    state[SETTING_STORE_STATE.LOADING_STATE] = isLoading
  },
  [SETTING_STORE_MUTATIONS.SET_SELECT_RECEIPTS_BY]: (state: SettingStoreState, criteria: string) => {
    state[SETTING_STORE_STATE.SELECT_RECEIPTS_BY] = criteria
  },
  [SETTING_STORE_MUTATIONS.SET_SELECT_TRIPS_BY]: (state: SettingStoreState, criteria: string) => {
    state[SETTING_STORE_STATE.SELECT_TRIPS_BY] = criteria
  }
}
