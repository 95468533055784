export enum PAYMENTS_STORE_STATE {
  PAYMENT_METHODS = 'Payments/PAYMENT_METHODS',
}

export enum PAYMENTS_STORE_GETTERS {
  PAYMENT_METHODS = 'Payments/PAYMENT_METHODS',
  PAYMENT_METHOD = 'Payments/PAYMENT_METHOD' 
}

export enum PAYMENTS_STORE_ACTIONS {
  GET_PAYMENT_METHODS = 'Payments/GET_PAYMENT_METHODS',
  CREATE_PAYMENT_METHOD = 'Payments/CREATE_PAYMENT_METHOD',
  UPDATE_PAYMENT_METHOD = 'Payments/UPDATE_PAYMENT_METHOD',
  DELETE_PAYMENT_METHOD = 'Payments/DELETE_PAYMENT_METHOD'
}

export enum PAYMENTS_STORE_MUTATIONS {
  SET_PAYMENT_METHODS = 'Payments/SET_PAYMENT_METHOD',
  REMOVE_PAYMENT_METHOD = 'Payments/REMOVE_PAYMENT_METHOD'
}

export enum PAYMENT_TYPE {
  CREDIT_CARD = 'creditCard',
  DEBIT_CARD = 'debitCard',
  BANK_ACCOUNT = 'bankAccount',
  CASH = 'cash'
}
