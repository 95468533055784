import {
  computed,
  inject
} from 'vue'

import {
  routerLocationKey
} from '@/providers'

const parseNum = (value: string | string[] | undefined): number | undefined => {
  return value
    ? parseInt(value.toString())
    : undefined
}

export default function useParamsContext () {
  const router = inject(routerLocationKey)

  const paramsContext = computed(() => {  
    return {
      companyId: parseNum(router?.currentRoute?.value?.params.companyId),
      receiptId: parseNum(router?.currentRoute?.value?.params.receiptId),
      tripId: parseNum(router?.currentRoute?.value?.params.tripId),
      tab: parseNum(router?.currentRoute?.value?.params.tab)
    }
  })

  return paramsContext
}