<template>
  <div class="global-status-bar paragraph-sm-light" v-if="globalStatus.active">
    <div class="global-status-bar-content">
      <ui-loader class="m-r-8" size="14px" border="2px"></ui-loader>
      <p>
        <span class="m-r-2">{{ globalStatus.message }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadWarning',
  computed: {
    ignoredRoute() {
      if (this.$route.params.step) return true
      return false
    },
    globalStatus() {
      const uploading = this.$config.uploadingReceipt && Object.values(this.$config.uploadingReceipt).length > 0
      const offline = this.$stash.offline || false
      const ignored = this.ignoredRoute
      let status = { active: false }

      switch (true) {
        case ignored:
          return status

        case uploading:
          return (status = {
            active: true,
            message: this.t('uploadingWarning')
          })

        case offline:
          return (status = {
            active: true,
            message: this.t('offline')
          })

        default:
          return status
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.global-status-bar {
  position: relative;
  width: 100%;
  max-width: 600px;
  background-color: $main-red;
  color: $white;
  z-index: 1000;
  // for handling ios transparent headers and "notch"
  padding-top: env(safe-area-inset-top);
}

.global-status-bar-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8px;
}

.dots span {
  opacity: 0;
  animation-name: appear;
  animation-duration: 1.4s;
  animation-delay: 0.2s;
  animation-iteration-count: infinite;

  &:nth-child(2) {
    animation-delay: 0.4s;
  }

  &:nth-child(3) {
    animation-delay: 0.6s;
  }
}

:deep(.loading-circle) {
  border-color: rgba(255, 255, 255, 0.5);
  border-top-color: $white;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
