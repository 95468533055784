const latviaForeignTravelPerDiems = require('../data/latviaForeignTravelPerDiems')
const { each, times } = require('lodash-es')
const moment = require('moment')

module.exports = function(tripInput, tripsInput) {
  let sum = 0
  let warning = false
  let overlappingDates = {}
  let newTripFirstDay = tripInput.startDate
  let oldTripEndDates = []
  let dataPerDestination = []

  // CREATE CALENDAR OBJECTS FROM EXISTING TRIPS AND THE NEW TRIP
  const oldTrips = createTripCalendar(tripsInput, false).tripCalendar
  const result = createTripCalendar([tripInput], true)
  const newTrip = result.tripCalendar
  const days = result.tripDays
  dataPerDestination = result.dataPerDestination

  each(tripsInput, trip => {
    oldTripEndDates.push(trip.endDate)
  })

  each(newTrip, (days, month) => {
    // GO OVER EVERY DAY IN MONTH
    each(days, (_, day) => {
      // IF EMPLOYEE HAS TWO DIFFERENT TRAVEL ASSIGNMENTS
      // AND NEXT TRIP STARTS THE SAME DAY AS PREVIOUS
      // TRIP ENDS, CALCULATE ALLOWANCE FOR BOTH TRIPS
      let endIsStart = `${month}-${day}` === newTripFirstDay && oldTripEndDates.includes(newTripFirstDay)

      if (oldTrips[month] && oldTrips[month][day] && !endIsStart) {
        // IF THIS DAY ALREADY EXISTS IN ANOTHER TRAVEL REPORT, DON'T ADD IT
        newTrip[month][day].perDiem = 0
        // SHOW WARNING TO USER THAT ALLOWANCE IS TAKEN OFF
        warning = true
        if (!overlappingDates[month]) overlappingDates[month] = []
        overlappingDates[month].push(day.replace(/^0+(?!$)/, ''))
      }
      // ADD ALLOWANCE TO TOTAL SUM
      sum = Number((sum + Number(newTrip[month][day].perDiem || 0)).toFixed(2))

      // CALCULATE SUM AND DAYS PER DESTINATION
      let destinationInfo = dataPerDestination.find(item => item.index === newTrip[month][day].index)
      if (destinationInfo) {
        destinationInfo.sum = Number((destinationInfo.sum + Number(newTrip[month][day].perDiem || 0)).toFixed(2))
        destinationInfo.days++
      }
    })
  })

  //SORT OVERLAPPING DATES IN ASCENDING ORDER
  each(overlappingDates, (_, month) => {
    overlappingDates[month].sort((a, b) => a - b)
  })

  return {
    days,
    sum,
    dataPerDestination,
    warning,
    overlappingDates
  }
}

function calendarPerDestination(destination, startDate, endDate, returned, meal, noWeekends, maxAllowance, index = '' ) {
  let calendar = {}
  let start = moment(startDate)
  let end = moment(endDate)
  let tripDays = end.diff(start, 'days') + 1

  times(tripDays, function(d) {
    // GET CURRENT DAY AND SET day AND month FOR tripCalendar
    let currentDay = start.clone().add(d, 'days')

    let day = currentDay.format('DD').toString()
    let dayOfWeek = currentDay.day()
    let month = currentDay.format('YYYY-MM').toString()

    // GET SUM OF MONEY TO PAY FOR EACH DAY
    let perDiem = latviaForeignTravelPerDiems[destination]

    if (!perDiem) {
      // IF THE PER DIEMS LIST DIDN'T HAVE DESTINATION COUNTRY LISTED, DEFAULT IS 30 EUR
      perDiem = 30
    }

    if (returned) {
      // IF EMPLOYEE RETURNED HOME FOR THE NIGHT, 50% OF ALLOWANCE IS CALCULATED
      perDiem = perDiem / 2
    }

    if (meal) {
      // IF 3 MEALS PER DAY AND ACCOMODATION WAS REIMBURSED, 30% OF ALLOWANCE IS CALCULATED
      perDiem = Number((perDiem * 0.3).toFixed(2))
    }

    if (maxAllowance) {
      // IF COMPANY HAS MAXIMUM ALLOWANCE, MAKE SURE PER DIEM IS NOT LARGER
      perDiem = perDiem > maxAllowance ? maxAllowance : perDiem
    }

    // IF ALLOWANCE FOR WEEKENDS IS NOT PAID AND CURRENT DAY IS ON WEEKEND
    if (noWeekends && (dayOfWeek === 6 || dayOfWeek === 0)) {
      perDiem = 0
    }

    // CREATE MONTH IF DOES NOT EXIST
    if (!calendar[month]) calendar[month] = {}
    // CREATE DAY IF DOES NOT EXIST 
    if (!calendar[month][day]) calendar[month][day] = {}
    // ADD TO CALENDAR DAILY ALLOWANCE SUM FOR EACH DAY
    calendar[month][day].perDiem = perDiem
    calendar[month][day].index = index
  })
  return calendar
}

function mergeCalendars(tripCalendar, oneDestinationCalendar) {
  Object.keys(oneDestinationCalendar).forEach(month => {
    if (tripCalendar[month]) {
      tripCalendar[month] = { ...tripCalendar[month], ...oneDestinationCalendar[month] }
    } else {
      tripCalendar[month] = oneDestinationCalendar[month]
    }
  })
}

function createTripCalendar(trips, isNewTrip) {
  let tripCalendar = {}
  let dataPerDestination = []
  // WALK THROUGH ALL AVAILABLE TRIPS
  each(trips, function(trip) {
    const { destination, destinations, returnOvernight, mealAndAccomodation, excludeWeekends, maxAllowance } = trip.dailyAllowanceInfo
    let start = moment(trip.startDate)

    // IF TRIP LASTS ONE DAY AND EMPLOYEE RETURNS HOME OVERNIGHT, NO ALLOWANCE IS PAID
    if (returnOvernight && trip.startDate === trip.endDate) {
      let month = start
        .clone()
        .format('YYYY-MM')
        .toString()

      let day = start
        .clone()
        .format('DD')
        .toString()

      // CREATE MONTH IF DOES NOT EXIST
      if (!tripCalendar[month]) tripCalendar[month] = {}
      // ADD TO CALENDAR DAILY ALLOWANCE SUM FOR EACH DAY
      tripCalendar[month][day] = 0
      return
    }

    if (destinations && destinations.length > 0) {
      trip.dailyAllowanceInfo.destinations.forEach(dest => {
        let oneDestinationData = calendarPerDestination(
          dest.destination,
          dest.startDate,
          dest.endDate,
          returnOvernight,
          mealAndAccomodation,
          excludeWeekends,
          maxAllowance,
          dest.index
        )
        if (isNewTrip) {
          dataPerDestination.push({ sum: 0, days: 0, index: dest.index })
        }
        mergeCalendars(tripCalendar, oneDestinationData)
      })
    } else {
      let oneDestinationData = calendarPerDestination(
        destination,
        trip.startDate,
        trip.endDate,
        returnOvernight,
        mealAndAccomodation,
        excludeWeekends,
        maxAllowance
      )
      mergeCalendars(tripCalendar, oneDestinationData)
    }
  })

  // COUNT AMOUNT OF DAYS SPENT IN TRIP
  let tripDays = 0
  Object.keys(tripCalendar).forEach(month => {
    Object.keys(tripCalendar[month]).forEach(() => {
      tripDays++
    })
  })

  return {
    tripCalendar,
    tripDays,
    dataPerDestination
  }
}
