<template>
  <div class="ui-tooltip" :class="messageType">
    <Popper :placement="placement" :arrow="arrow" :hover="hover" :openDelay="openDelay" :content="content">
      <slot />
      <template v-if="isUsingContentSlot" #content>
        <slot name="content" />
      </template>
    </Popper>
  </div>
</template>

<script>
import Popper from 'vue3-popper'
export default {
  name: 'UiTooltip',
  components: {
    Popper
  },
  props: {
    content: {
      type: String
    },
    arrow: {
      type: Boolean,
      default: false
    },
    hover: {
      type: Boolean,
      default: true
    },
    openDelay: {
      type: String,
      default: '0'
    },
    messageType: {
      type: String,
      default: 'neutral' // 'danger'
    },
    placement: {
      type: String,
      default: 'bottom' // 'bottom-end'|'bottom-start'|'bottom'|'left-end'|'left-start'|'left'|'right-end'|'right-start'|'right'|'top-end'|'top-start'|'top'
    },
    isUsingContentSlot: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-tooltip :deep(.popper) {
  background: $medium-dark-grey !important;
  padding: 4px 12px !important;
  border-radius: 8px !important;
  color: $white !important;
  max-width: 400px;
  font-family: 'IBM Plex Sans';
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
}

.ui-tooltip :deep(.popper:hover),
.ui-tooltip :deep(.popper #arrow::before),
.ui-tooltip :deep(.popper:hover > #arrow::before) {
  background: $medium-dark-grey;
}

.ui-tooltip.danger :deep(.popper),
.ui-tooltip.danger :deep(.popper:hover),
.ui-tooltip.danger :deep(.popper #arrow::before),
.ui-tooltip.danger :deep(.popper:hover > #arrow::before) {
  background: $main-red !important;
}
</style>
