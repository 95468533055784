//INSIDE COUNTRY (2024)
// full day - 290 kronor
// half day - 145 kronor
// after 3 Months - 203 kronor per hel dag
// after 2 years - 145 kronor per hel dag

// OUTSIDE COUNTRY
// full day - per diem per country
// half day - 1/2 per diem per country
// after 3 months - 70% of per diem rate
// after 2 years - 50% of per diem rate

// For other countries and areas with undefined per diem rate it is 456 SEK (2023), 493 SEK (2024)

// If multiple locations are visited on the same day, the location where the longest period
// of the day (defined as between 06:00 and 24:00) has been spent is the allowance location.

const swedenPerDiems2023 = require('../data/swedenPerDiems2023')
const swedenPerDiems2024 = require('../data/swedenPerDiems2024')
const moment = require('moment')
const { each, times } = require('lodash-es')

module.exports = function (currentTrip, previousTrips) {
  let sum = 0
  let newTripCalendar = {}
  let oldTripCalendar = {}
  let warning = false
  let overlappingDates = {}
  let dataPerDestination = []

  if (currentTrip.startDate === currentTrip.endDate) {
    // FOR ONE DAY TRIP ALLOWANCE IS NOT CALCULATED
    currentTrip.destinations.forEach((destination) => {
      dataPerDestination.push({ index: destination.index, days: 0, sum: 0 })
    })
    return { sum, dataPerDestination }
  }

  newTripCalendar = createTripCalendar(currentTrip)
  oldTripCalendar = createPreviousTripCalendar(previousTrips)

  each(newTripCalendar, (days, month) => {
    each(days, (_, day) => {
      // SET WARNING SHOWING DATES DATE ARE OVERLAPPING WITH PREVIOUS TRIPS
      if (oldTripCalendar[month] && oldTripCalendar[month][day]) {
        warning = true
        if (!overlappingDates[month]) overlappingDates[month] = []
        overlappingDates[month].push(day.replace(/^0+(?!$)/, ''))
      }

      let perDiemOfDay = newTripCalendar[month][day].perDiem
      let maximum = currentTrip.maxAllowance

      // CAP PER DIEM ACCORDING TO COMPANY DAILY ALLOWANCE MAXIMUM SETTING
      if (maximum !== undefined && maximum > -1) {
        perDiemOfDay = perDiemOfDay > maximum ? maximum : perDiemOfDay
      }

      // ADD THIS DAY ALLOWANCE TO TOTAL SUM
      sum = Number((sum + perDiemOfDay).toFixed(2))

      // CALCULATE SUM AND DAYS PER DESTINATION
      let destinationInfo = dataPerDestination.find((item) => item.index === newTripCalendar[month][day].index)
      if (destinationInfo) {
        destinationInfo.sum = Number((destinationInfo.sum + perDiemOfDay).toFixed(2))
        destinationInfo.days++
      } else {
        dataPerDestination.push({
          sum: perDiemOfDay,
          days: 1,
          index: newTripCalendar[month][day].index
        })
      }
    })
  })

  each(overlappingDates, (_, month) => {
    overlappingDates[month].sort((a, b) => a - b)
  })

  return {
    sum,
    dataPerDestination,
    overlappingDates,
    warning
  }
}

function createTripCalendar(trip = {}) {
  let calendar = {}
  const { breakfastIncluded, lunchIncluded, dinnerIncluded } = trip
  const locations = trip.destinations || []

  let calendarOfDestinations = prepareCalendarOfDestinations(trip.destinations)

  let lastItem = locations.length - 1
  let firstDay = locations[0].startDate
  let lastDay = locations[lastItem].endDate
  let tripDaysCount = 0

  // GET PER DIEM FOR EACH DAY
  each(calendarOfDestinations, (days, month) => {
    each(days, (item, date) => {
      let currentDate = `${month}-${date}`
      let currentYear = month.slice(0, 4)
      tripDaysCount++

      let perDiemRate = currentYear === '2023' ? swedenPerDiems2023[item.location] : swedenPerDiems2024[item.location]
      if (!perDiemRate) perDiemRate = currentYear === '2023' ? 456 : 493

      // IF 2 YEARS HAVE PASSED, REDUCE THE AMOUNT BY 50%
      if (tripDaysCount > 730) {
        perDiemRate = Number((perDiemRate / 2).toFixed(2))
      }
      // IF 3 MONTHS HAVE PASSED, RATE IS 70% OF FULL AMOUNT
      else if (tripDaysCount > 90) {
        perDiemRate = Number((perDiemRate * 0.7).toFixed(2))
      }

      if (currentDate === firstDay) {
        let firstDayStart = convertToDate(firstDay, locations[0].startTime)
        let sameDay12 = convertToDate(firstDay, '12:00')
        if (firstDayStart.isSameOrAfter(sameDay12)) {
          perDiemRate = perDiemRate / 2
        }
      }

      if (currentDate === lastDay) {
        let lastDayEnd = convertToDate(lastDay, locations[lastItem].endTime)
        let sameDay19 = convertToDate(lastDay, '19:00')
        if (lastDayEnd.isSameOrBefore(sameDay19)) {
          perDiemRate = perDiemRate / 2
        }
      }

      // IF EMPLOYER PROVIDES FREE MEALS, REDUCE ALLOWANCE
      // DEPENDS ON TYPE OF MEAL AND IF IT IS DOMESTIC TRIP
      let deductForMeal = 0 // %
      if (breakfastIncluded) deductForMeal += item.location === 'SE' ? 0.2 : 0.15
      if (lunchIncluded) deductForMeal += 0.35
      if (dinnerIncluded) deductForMeal += 0.35
      deductForMeal = Number(deductForMeal.toFixed(2))
      perDiemRate = Number((1 - deductForMeal).toFixed(2)) * perDiemRate
      perDiemRate = Number(perDiemRate.toFixed(2))

      if (!calendar[month]) calendar[month] = {}
      if (!calendar[month][date]) calendar[month][date] = {}
      calendar[month][date].perDiem = perDiemRate
      calendar[month][date].index = item.index
    })
  })
  return calendar
}

function convertToDate(date, time) {
  return moment(date + ' ' + time, 'YYYY-MM-DDLT')
}

function setDay(date) {
  return moment(date, 'YYYY-MM-DD').format('DD').toString()
}

function setMonth(date) {
  return moment(date, 'YYYY-MM-DD').format('YYYY-MM').toString()
}

function prepareCalendarOfDestinations(destinations) {
  // create calendar with destination for each day
  let daysInDestination = {}
  destinations.forEach((dest) => {
    let s = dest.startDate
    let e = dest.endDate
    let startingDate = moment(s)
    let daysInOneDestination = moment(e).diff(moment(s), 'day') + 1
    // iterate over each day in destination
    times(daysInOneDestination, (dayCount) => {
      let currentDay = startingDate.clone().add(dayCount, 'day')
      let date = setDay(currentDay)
      let month = setMonth(currentDay)
      if (!daysInDestination[month]) daysInDestination[month] = {}
      if (!daysInDestination[month][date]) daysInDestination[month][date] = {}

      // if date is already in calendar
      // check in which destination user spent more time
      if (daysInDestination[month][date].location) {
        let overlappingDate = `${month}-${date}`

        let destinationsOnSameDate = destinations.filter(
          (de) => de.startDate === overlappingDate || de.endDate === overlappingDate
        )

        destinationsOnSameDate.forEach((location) => {
          if (location.startDate === overlappingDate) {
            let dateAndTime = convertToDate(location.startDate, location.startTime)
            // remaining time to 24:00
            let endOfDay = convertToDate(location.startDate, '24:00')
            location.minutes = endOfDay.diff(dateAndTime, 'minute')
          }
          if (location.endDate === overlappingDate) {
            let dateAndTime = convertToDate(location.endDate, location.endTime)
            // time from 6:00 to leaving destination
            let startOfDay = convertToDate(location.endDate, '6:00')
            location.minutes = dateAndTime.diff(startOfDay, 'minute')
          }
        })
        // first element should be location where most time was spent
        destinationsOnSameDate.sort((a, b) => b.minutes - a.minutes)
        // if the same amount of time was spent in more than one loaction
        // the location with highest per diem should be chosen
        if (
          destinationsOnSameDate.length > 1 &&
          destinationsOnSameDate[0].minutes === destinationsOnSameDate[1].minutes
        ) {
          let repeating = destinationsOnSameDate.filter((dst) => dst.minutes === destinationsOnSameDate[0].minutes)
          if (repeating.length > 0) {
            let highestRate = 0
            let highestRatedLocation = ''
            let highestRatedLocationId = ''
            repeating.forEach((r) => {
              let currentYear = month.slice(0, 4)

              let rateForLocation =
                currentYear === '2023' ? swedenPerDiems2023[r.destination] : swedenPerDiems2024[r.destination]
              if (!rateForLocation) rateForLocation = currentYear === '2023' ? 456 : 493
              if (rateForLocation > highestRate) {
                highestRate = rateForLocation
                highestRatedLocation = r.destination
                highestRatedLocationId = r.index
              }
            })
            daysInDestination[month][date] = { location: highestRatedLocation, index: highestRatedLocationId }
          }
        } else {
          daysInDestination[month][date] = {
            location: destinationsOnSameDate[0].destination,
            index: destinationsOnSameDate[0].index
          }
        }
      } else {
        daysInDestination[month][date] = { location: dest.destination, index: dest.index }
      }
    })
  })
  return daysInDestination
}

function createPreviousTripCalendar(trips) {
  const calendarOfPreviousTrips = {}
  trips.forEach((trip) => {
    let start = trip.startDate
    let end = trip.endDate
    let startingDate = moment(start)
    let tripDayCount = moment(end).diff(moment(start), 'day') + 1

    times(tripDayCount, (dayOfTrip) => {
      let currentDay = startingDate.clone().add(dayOfTrip, 'day')
      let date = setDay(currentDay)
      let month = setMonth(currentDay)
      if (!calendarOfPreviousTrips[month]) calendarOfPreviousTrips[month] = {}
      calendarOfPreviousTrips[month][date] = true
    })
  })

  return calendarOfPreviousTrips
}
