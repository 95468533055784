// IN FINLAND ONE FULL DAY OF TRAVEL MEANS EVERY 24h FROM THE TRAVEL START

// DOMESTIC TRAVELS (2024):
//  - MORE THAN 6H: PER DIEM = 24 EUR
//  - MORE THAN 10H: PER DIEM = 51 EUR
// IF THE LAST FULL DAY OF TRAVEL IS EXCEEDED BY:
//  - 2H: ADD 24 EUR
//  - 6H: ADD 51 EUR

// TRIPS OUTSIDE FINLAND:
// IF TOTAL TRAVEL TIME IS LESS THAN 10H: DOMESTIC TRAVEL RULES APPLY
// IF THE COUNTRY HAS NO PER DIEM RATE LISTED, RATE IS 52 EUR (2024)
// IF THE LAST FULL DAY OF TRAVEL IS EXCEEDED BY:
//  - 2H: ADD 50% OF THE LAST VISITED COUNTRY PER DIEM
//  - 10H: ADD 100% OF THE LAST VISITED COUNTRY PER DIEM
// PER DIEM RATE IS DETERMINED BY THE COUNTRY WHERE THE FULL DAY OF TRAVEL(24H) ENDED

// 2 FREE MEALS (1 FREE MEAL, IF PARTIAL DAY AND DOMESTIC TRAVEL) INCLUDED REDUCES THE PER DIEM AMOUNT BY 50%

import moment from 'moment'
import { each, times } from 'lodash-es'
import { safeAdd } from '@/lib/helpers'
import finlandPerDiems2023 from '../data/finlandPerDiems2023'
import finlandPerDiems2024 from '../data/finlandPerDiems2024'

export default function (currentTrip, previousTrips) {
  let oldTrips = createOldTripCalendar(previousTrips)
  let newTrip = createNewTripCalendar(currentTrip)
  let sum = 0
  let warning = false
  let overlappingDates = {}
  let dataPerDestination = []

  each(newTrip, (days, month) => {
    // GO OVER EVERY DAY IN MONTH
    each(days, (_, day) => {
      // IF ALLOWANCE HAS BEEN CALCULATED FOR THIS DATE IN PREVIOUS TRIP,
      // DON'T ADD IT FOR THIS TRIP AND WARN ABOUT IT
      if (oldTrips[month] && oldTrips[month][day]) {
        warning = true
        if (!overlappingDates[month]) overlappingDates[month] = []
        overlappingDates[month].push(day.replace(/^0+(?!$)/, ''))
      } else {
        // ADD THIS DAY ALLOWANCE TO TOTAL SUM
        sum = Number((sum + newTrip[month][day].perDiem).toFixed(2))

        // CALCULATE SUM AND DAYS PER DESTINATION
        const destinationInfo = dataPerDestination.find((item) => item.index === newTrip[month][day].index)
        if (destinationInfo) {
          destinationInfo.sum = safeAdd(destinationInfo.sum, newTrip[month][day].perDiem)
          destinationInfo.days++
        } else {
          dataPerDestination.push({
            sum: newTrip[month][day].perDiem,
            days: 1,
            index: newTrip[month][day].index
          })
        }
      }
    })
  })

  each(overlappingDates, (_, month) => {
    overlappingDates[month].sort((a, b) => a - b)
  })

  return {
    sum,
    warning,
    overlappingDates,
    dataPerDestination
  }
}

function createOldTripCalendar(trips) {
  const calendarOfPreviousTrips = {}

  each(trips, (trip) => {
    if (!trip.includesDailyAllowance || !trip.startDate || !trip.endDate) {
      return
    }

    const start = trip.startDate
    const end = trip.endDate
    const startingDate = moment(start)
    const tripDayCount = moment(end).diff(moment(start), 'day') + 1

    times(tripDayCount, (dayOfTrip) => {
      const currentDay = startingDate.clone().add(dayOfTrip, 'day')
      const date = setDay(currentDay)
      const month = setMonth(currentDay)
      if (!calendarOfPreviousTrips[month]) calendarOfPreviousTrips[month] = {}
      calendarOfPreviousTrips[month][date] = true
    })
  })

  return calendarOfPreviousTrips
}

function createNewTripCalendar({ destinations, maxAllowance }) {
  const tripCalendar = {}
  const tripStartDate = destinations[0].startDate
  const tripStartTime = destinations[0].startTime
  const tripEndDate = destinations[destinations.length - 1].endDate
  const tripEndTime = destinations[destinations.length - 1].endTime

  if (tripStartDate && tripStartTime && tripEndDate && tripEndTime) {
    const start = convertToDate(tripStartDate, tripStartTime)
    const end = convertToDate(tripEndDate, tripEndTime)
    const totalTimeInMinutes = end.diff(start, 'minutes')

    // TRIP LASTED ONE DAY OR LESS
    if (totalTimeInMinutes <= 1440) {
      const lastDestination = destinations[destinations.length - 1]
      const day = setDay(lastDestination.endDate)
      const month = setMonth(lastDestination.endDate)
      const year = month.slice(0, 4)

      tripCalendar[month] = {}
      tripCalendar[month][day] = {}

      // TRIP LASTED LESS THAN 6H - NO ALLOWANCE IS CALCULATED
      if (totalTimeInMinutes <= 360) {
        tripCalendar[month][day].perDiem = 0
      }

      // TRIP LASTED BETWEEN 6 AND 10 H - DOMESTIC RULES APPLY, PARTIAL PER DIEM
      if (totalTimeInMinutes > 360 && totalTimeInMinutes <= 600) {
        const partialPerDiem = capAtMaxllowance(year === '2023' ? 22 : 24, maxAllowance)
        tripCalendar[month][day].perDiem = setReducedPerDiem(
          lastDestination,
          lastDestination.endDate,
          partialPerDiem,
          true
        )
      }

      // TRIP LASTED MORE THAN 10H - FULL PER DIEM IS COUNTED
      if (totalTimeInMinutes > 600) {
        const perDiem = capAtMaxllowance(setPerDiem(year, lastDestination.destination), maxAllowance)
        tripCalendar[month][day].perDiem = setReducedPerDiem(lastDestination, lastDestination.endDate, perDiem, false)
      }

      tripCalendar[month][day].index = lastDestination.index

      return tripCalendar
    }

    const fullDaysCount = end.diff(start, 'days')
    const exceededTime = totalTimeInMinutes - fullDaysCount * 1440

    // COUNT PER DIEM FOR EACH FULL DAY
    times(fullDaysCount, () => {
      const nextFullDay = start.add(24, 'hours')
      const dayEndsInDestination = destinations.find((destination) => {
        if (destination.startDate && destination.startTime && destination.endDate && destination.endTime) {
          const destinationStart = convertToDate(destination.startDate, destination.startTime)
          const destinationEnd = convertToDate(destination.endDate, destination.endTime)
          return moment(nextFullDay).isBetween(destinationStart, destinationEnd, 'minute', '[]')
        }
        return false
      })

      if (dayEndsInDestination) {
        const day = setDay(nextFullDay)
        const month = setMonth(nextFullDay)
        const year = month.slice(0, 4)
        const nextFullDayDate = moment(nextFullDay).format('YYYY-MM-DD')

        if (!tripCalendar[month]) tripCalendar[month] = {}
        if (!tripCalendar[month][day]) tripCalendar[month][day] = {}

        const perDiem = capAtMaxllowance(setPerDiem(year, dayEndsInDestination.destination), maxAllowance)
        tripCalendar[month][day].perDiem = setReducedPerDiem(dayEndsInDestination, nextFullDayDate, perDiem, false)
        tripCalendar[month][day].index = dayEndsInDestination.index
      }
    })

    // HANDLE TIME EXCEEDED ON LAST DAY
    if (exceededTime > 120) {
      const lastDestination = destinations[destinations.length - 1]
      const day = setDay(lastDestination.endDate)
      const month = setMonth(lastDestination.endDate)
      const year = month.slice(0, 4)
      const lastDayPerDiem = setExceededTimePerDiem(exceededTime, year, lastDestination.destination, maxAllowance)

      if (!tripCalendar[month]) tripCalendar[month] = {}
      if (!tripCalendar[month][day]) tripCalendar[month][day] = {}

      if (tripCalendar[month][day].perDiem) {
        tripCalendar[month][day].perDiem += setReducedPerDiem(
          lastDestination,
          lastDestination.endDate,
          lastDayPerDiem,
          false
        )
      } else {
        tripCalendar[month][day].perDiem = setReducedPerDiem(
          lastDestination,
          lastDestination.endDate,
          lastDayPerDiem,
          false
        )
        tripCalendar[month][day].index = lastDestination.index
      }
    }
  }

  return tripCalendar
}

function capAtMaxllowance(perDiem, maxAllowance) {
  if (!maxAllowance) return perDiem
  return perDiem > maxAllowance ? maxAllowance : perDiem
}

function setPerDiem(year, countryCode) {
  let perDiem = year === '2023' ? finlandPerDiems2023[countryCode] : finlandPerDiems2024[countryCode]
  if (!perDiem) perDiem = year === '2023' ? 53 : 52
  return perDiem
}

function setExceededTimePerDiem(exceededTime, year, countryCode, maxAllowance) {
  // BETWEEN 2 AND 10 H ABROAD
  if (exceededTime > 120 && exceededTime <= 600 && countryCode !== 'FI') {
    return capAtMaxllowance(setPerDiem(year, countryCode), maxAllowance) / 2
  }

  // BETWEEN 2 AND 6H IN FINLAND
  if (exceededTime > 120 && exceededTime <= 360 && countryCode === 'FI') {
    return capAtMaxllowance(year === '2023' ? 22 : 24, maxAllowance)
  }

  // MORE THAN 6H IN FINLAND OR MORE THAN 10 H ABROAD
  return capAtMaxllowance(setPerDiem(year, countryCode), maxAllowance)
}

function setReducedPerDiem(destination, date, perDiem, isPartialDayPerDiem) {
  const isFreeMealCounted =
    (destination.oneFreeMeal?.find((freeMealDate) => freeMealDate === date) && isPartialDayPerDiem) ||
    destination.twoFreeMeals?.find((freeMealDate) => freeMealDate === date)
  return isFreeMealCounted ? perDiem / 2 : perDiem
}

function convertToDate(date, time) {
  return moment(date + ' ' + time, 'YYYY-MM-DDLT')
}

function setDay(date) {
  return moment(date, 'YYYY-MM-DD').format('DD').toString()
}

function setMonth(date) {
  return moment(date, 'YYYY-MM-DD').format('YYYY-MM').toString()
}
