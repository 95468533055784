/* eslint-disable no-unused-vars */
import { makeURL } from '@/lib/vue-instance-helpers'
import { xstore } from '@/store'
import Axios from 'axios'
import Raven from 'raven-js'
import moment from 'moment'
import { Receipt } from '@/models'
import { CONFIG_STORE_MUTATIONS, CURRENCY, ERRROR_MESSAGES } from '@/enums'
import sanitize from '@ui/directives/sanitize'
import { App } from 'vue'
import type { Plugin as PluginType } from 'vue'

//@ts-ignore
function getUrl(url: string, basenames = Axios.defaults.__basenames, defaultIfMissing = 'https://api.costpocket.com') {
  return url.replace(/^@\w+/, (string) => (basenames && basenames[string] ? basenames[string] : defaultIfMissing))
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $moment: typeof moment
    $makeURL: typeof makeURL
    $getUrl: typeof getUrl
    $thumbnail: typeof thumbnail
    $approvalImg: typeof approvalImg
    $original: typeof original
    $pdf: typeof pdf
    $date: typeof date
    $setLoggingContext: typeof n
    $captureException: typeof setLoggingContext
    n: typeof captureException
    eh: typeof eh
  }
}

export const thumbnail = ({ companyId, updatedAt, id }: Receipt) => {
  if (!companyId || !id) return
  return getUrl(
    `@coreapi/companies/${companyId}/receipts/${id}/image/thumbnail?access_token=${localStorage.token}&cache=${
      updatedAt || ''
    }`
  )
}

export const approvalImg = (
  companyId: number | string,
  receiptId: number | string,
  approvalId: number | string,
  fileType: 'pdf' | 'img'
) => {
  if (!companyId || !receiptId || !approvalId || !fileType) return
  return getUrl(
    `@coreapi/companies/${companyId}/approvals/${approvalId}/doc/${receiptId}/attachment/${fileType}?access_token=${localStorage.token}`
  )
}

export const original = ({ companyId, updatedAt, id }: Receipt) => {
  if (!companyId || !updatedAt || !id) return
  return getUrl(
    `@coreapi/companies/${companyId}/receipts/${id}/image/thumbnail?type=original&access_token=${localStorage.token}&cache=${updatedAt}`
  )
}

export const pdf = ({ companyId, updatedAt, id }: Receipt) => {
  if (!companyId || !updatedAt || !id) return
  return getUrl(
    `@coreapi/companies/${companyId}/receipts/${id}/file/pdf?access_token=${localStorage.token}&cache=${updatedAt}`
  )
}

export const date = (date: moment.MomentInput, format = 'DD.MM') => {
  return moment.utc(date).format(format)
}

export const n = (num: any, currency: CURRENCY = CURRENCY.EUR, spacer = '') => {
  const target = {
    [CURRENCY.EUR]: '€',
    [CURRENCY.GBP]: '£',
    [CURRENCY.USD]: '$'
  }

  const sign = target[currency] || target[CURRENCY.EUR]

  return `${num}${spacer}${sign}`
}

export const setLoggingContext = (context: any) => {
  if (context?.user?.id) {
    Raven.setUserContext({ id: context.user.id })
  }
}

export const captureException = (error: unknown) => {
  Raven.captureException(error)
}

export const eh = (error: any = {}) => {
  const error1 = ERRROR_MESSAGES[error?.response?.data?.codename as keyof typeof ERRROR_MESSAGES]
  const error2 = ERRROR_MESSAGES[error?.response?.data?.efException as keyof typeof ERRROR_MESSAGES]
  const error3 = ERRROR_MESSAGES[error?.efException as keyof typeof ERRROR_MESSAGES]
  const error4 = ERRROR_MESSAGES[error?.message as keyof typeof ERRROR_MESSAGES]
  const error5 = ERRROR_MESSAGES[error?.response?.data?.message as keyof typeof ERRROR_MESSAGES]
  const error6 = ERRROR_MESSAGES[error as keyof typeof ERRROR_MESSAGES]

  const errorFinal = error1 || error2 || error3 || error4 || error5 || error6 || false

  if (error?.response?.status === 403) {
    return 'isufficientAccessRights'
  } else if (error?.response?.data?.invalidFields?.[0]?.expected === 'Issued date must not be in the future!') {
    return 'issueDateNotInFuture'
  } else if (errorFinal) {
    return errorFinal
  } else if (error?.response?.data?.message) {
    return error.response.data.message
  } else if (error) {
    return error
  } else {
    return 'somethingWentWrong'
  }
}

const Plugin: PluginType = (app: App<Element>, config: any) => {
  if (config) {
    xstore.commit(CONFIG_STORE_MUTATIONS.SET_CONFIG, config)
  }

  app.config.globalProperties.$moment = moment
  app.config.globalProperties.$makeURL = makeURL
  app.config.globalProperties.$getUrl = getUrl
  app.config.globalProperties.$thumbnail = thumbnail
  app.config.globalProperties.$approvalImg = approvalImg
  app.config.globalProperties.$original = original
  app.config.globalProperties.$pdf = pdf
  app.config.globalProperties.$date = date
  app.config.globalProperties.n = n
  app.config.globalProperties.$setLoggingContext = setLoggingContext
  app.config.globalProperties.$captureException = captureException
  app.config.globalProperties.eh = eh

  app.directive('sanitize', sanitize)

  app.mixin({
    methods: {
      $moment: moment,
      $makeURL: makeURL,
      $getUrl: getUrl,
      $thumbnail: thumbnail,
      $approvalImg: approvalImg,
      $original: original,
      $pdf: pdf,
      $date: date,
      $setLoggingContext: n,
      $captureException: setLoggingContext,
      n: captureException,
      eh: eh
    }
  })
}

export default Plugin
