<template>
  <div class="input-chips-container">
    <div
      class="input-chip"
      v-for="(option, index) in options"
      :key="index + 'radio'"
      :class="{ selected: option.value === modelValue }"
      @click="$emit('selected', option)"
    >
      <div class="radio-button-value">
        <div class="radio-button-inner-layer"></div>
      </div>

      <p class="paragraph-lg-regular">
        {{ option.title }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiRadioButtons',
  emits: ['selected'],
  props: {
    modelValue: {
      type: [String, Number]
    },
    options: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.input-chips-container {
  display: flex;
}

.input-chip {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 6px 16px;
  border-radius: 20px;
  background: $white;
  border: 1px solid $light-blue;
  margin-right: 8px;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
    color: $main-blue;
  }

  &:hover .radio-button-value {
    background: $white;

    border: 2px solid $main-blue;
  }

  &:hover .radio-button-inner-layer {
    background: $main-blue;
  }
}

.radio-button-value {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background: $medium-light-grey;
  border: 2px solid $medium-light-grey;
  margin-right: 12px;
}

.radio-button-inner-layer {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background: transparent;
}

.selected.input-chip {
  color: $main-blue;
}

.selected .radio-button-value {
  background: $white;
  border: 2px solid $main-blue;
}

.selected .radio-button-inner-layer {
  background: $main-blue;
}
</style>
