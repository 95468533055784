<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 20 20"
    :height="height"
    :viewBox="viewBox"
    :width="width"
    :fill="fill"
  >
    <g><rect fill="none" height="20" width="20" x="0" /></g>
    <g>
      <g>
        <path
          d="M16,4H4C3.45,4,3,4.45,3,5v10c0,0.55,0.45,1,1,1h12c0.55,0,1-0.45,1-1V5 C17,4.45,16.55,4,16,4z M16,15H4V5h12V15z"
          fill-rule="evenodd"
        />
        <rect fill-rule="evenodd" height="1" width="4" x="5" y="7" />
        <rect fill-rule="evenodd" height="1" width="4" x="5" y="9.5" />
        <rect fill-rule="evenodd" height="1" width="4" x="5" y="12" />
        <polygon fill-rule="evenodd" points="11.97,11.77 14.8,8.94 14.1,8.23 11.97,10.35 11.27,9.65 10.56,10.35" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'black'
    },
    viewBox: {
      type: String,
      default: '0 0 20 20'
    },
    width: {
      type: String,
      default: '18px'
    },
    height: {
      type: String,
      default: '18px'
    }
  }
}
</script>
