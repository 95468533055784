import { OVERVIEW_STORE_STATE, OVERVIEW_STORE_GETTERS } from '@/enums'

import { OverviewStoreState } from '@/models'

export default {
  [OVERVIEW_STORE_GETTERS.TRIPS_COUNT]: (state: OverviewStoreState): any => {
    return state[OVERVIEW_STORE_STATE.TRIPS_COUNT] || {}
  },
  [OVERVIEW_STORE_GETTERS.RECEIPTS_COUNT]: (state: OverviewStoreState): any => {
    return state[OVERVIEW_STORE_STATE.RECEIPTS_COUNT] || {}
  }
}
