<template>
    <div class="card-container" :style="{
      '--max-width': maxWidth,
      '--cursor': cursor
    }">
      <slot name="content"></slot>
    </div>
</template>

<script>

export default {
    name: 'UiCard',
    props: {
        maxWidth: {
            type: String,
            default: '100%'
        },
        cursor: {
            type: String,
            default: 'pointer'
        }
    }
}
</script>

<style lang="scss" scoped>

.card-container {
  --max-width: 100%;
  --cursor: pointer;

  @extend %box-outline;
  position: relative;
  max-width: var(--max-width);
  margin-top: 8px;
  padding: 16px;
  background: $white;
  cursor: var(--cursor);
}
</style>