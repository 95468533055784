import { PAYMENTS_STORE_GETTERS, PAYMENTS_STORE_STATE } from '@/enums'
import { PaymentMethod, PaymentMethods, PaymentsStoreState } from '@/models'

export default {
  [PAYMENTS_STORE_GETTERS.PAYMENT_METHODS]: (state: PaymentsStoreState): PaymentMethods => {
    return state[PAYMENTS_STORE_STATE.PAYMENT_METHODS]
  },
  [PAYMENTS_STORE_GETTERS.PAYMENT_METHOD]: (state: PaymentsStoreState) => (id: number): PaymentMethod | undefined => {
    return state[PAYMENTS_STORE_STATE.PAYMENT_METHODS][id]
  },
}
