import mutations from './receipts.mutations'
import actions from './receipts.actions'
import getters from './receipts.getters'
import state from './receipts.state'

export default {
  mutations,
  actions,
  getters,
  state
}
