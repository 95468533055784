import Axios from 'axios'
import moment from 'moment'
import { OVERVIEW_STORE_ACTIONS, OVERVIEW_STORE_MUTATIONS } from '@/enums'
import queryCreator from '@/lib/queryCreator'

export default {
  [OVERVIEW_STORE_ACTIONS.GET_TRIPS_COUNT]: async ({ commit }: any, params: any) => {
    try {
      const { startDate, endDate } = params

      const endDatePlusOneDay = moment(endDate).add(1, 'days').format('YYYY-MM-DD')

      const reportsUrl = queryCreator()
        .where('createdAt', ({ isBetween }: any) => isBetween(startDate, endDatePlusOneDay))
        .base(`@coreapi/expenses/reports/count`)
        .get()

      const { data: tripsCountPerCompany } = await Axios.get(reportsUrl)

      commit(OVERVIEW_STORE_MUTATIONS.SET_TRIPS_COUNT, tripsCountPerCompany)

      return tripsCountPerCompany
    } catch (error) {
      console.error(OVERVIEW_STORE_ACTIONS.GET_TRIPS_COUNT, error)
    }
  },
  [OVERVIEW_STORE_ACTIONS.GET_RECEIPTS_COUNT]: async ({ commit }: any, params: any) => {
    try {
      const { startDate, endDate } = params

      const endDatePlusOneDay = moment(endDate).add(1, 'days').format('YYYY-MM-DD')

      const receiptsUrl = queryCreator()
        .where('createdAt', ({ isBetween }: any) => isBetween(startDate, endDatePlusOneDay))
        .where('tripId', ({ isEqualTo }: any) => isEqualTo(null))
        .base(`@coreapi/expenses/documents/count`)
        .get()

      const { data: receiptsCountPerCompany } = await Axios.get(receiptsUrl)

      commit(OVERVIEW_STORE_MUTATIONS.SET_RECEIPTS_COUNT, receiptsCountPerCompany)

      return receiptsCountPerCompany
    } catch (error) {
      console.error(OVERVIEW_STORE_ACTIONS.GET_RECEIPTS_COUNT, error)
    }
  }
}
