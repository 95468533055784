<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 24 24"
    :viewBox="viewBox"
    :fill="fill"
    :width="width"
    :height="height"
  >
    <g><rect fill="none" height="24" width="24" /></g>
    <g>
      <path
        d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,5v9h-3.56 c-0.36,0-0.68,0.19-0.86,0.5C14.06,15.4,13.11,16,12,16s-2.06-0.6-2.58-1.5C9.24,14.19,8.91,14,8.56,14H5V5H19z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'black'
    },
    viewBox: {
      type: String,
      default: '0 0 24 24'
    },
    width: {
      type: String,
      default: '18px'
    },
    height: {
      type: String,
      default: '18px'
    }
  }
}
</script>
