import { APPROVALS_STORE_GETTERS, APPROVALS_STORE_STATE } from '@/enums'
import { ApprovalsStoreState } from '@/models'

export default {
  [APPROVALS_STORE_GETTERS.APPROVALS]: (state: ApprovalsStoreState) => {
    return state[APPROVALS_STORE_STATE.APPROVALS]
  },
  [APPROVALS_STORE_GETTERS.APPROVER_OPTIONS]: (state: ApprovalsStoreState) => (companyId: string | number) => {
    return state[APPROVALS_STORE_STATE.APPROVERS][companyId] 
  }
}
