import { SETTING_STORE_GETTERS, SETTING_STORE_STATE } from '@/enums'

import { SettingStoreState } from '@/models'

export default {
  [SETTING_STORE_GETTERS.ACTIVE_DATES]: (state: SettingStoreState) => {
    return state[SETTING_STORE_STATE.ACTIVE_DATES]
  },
  [SETTING_STORE_GETTERS.IS_LOADING]: (state: SettingStoreState) => {
    return state[SETTING_STORE_STATE.LOADING_STATE]
  },
  [SETTING_STORE_GETTERS.SELECT_RECEIPTS_BY]: (state: SettingStoreState) => {
    return state[SETTING_STORE_STATE.SELECT_RECEIPTS_BY]
  },
  [SETTING_STORE_GETTERS.SELECT_TRIPS_BY]: (state: SettingStoreState) => {
    return state[SETTING_STORE_STATE.SELECT_TRIPS_BY]
  }
}
