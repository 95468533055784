import mutations from './trips.mutations'
import actions from './trips.actions'
import getters from './trips.getters'
import state from './trips.state'

export default {
  mutations,
  actions,
  getters,
  state
}
