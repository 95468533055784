<template>
  <!-- TODO: time picker -->
  <div class="cp-datepicker">
    <div class="cp-datepicker__wrap">
      <div class="cp-datepicker__title">
        <slot name="title"></slot>
      </div>
      <div class="cp-datepicker__target">
        <IconCalendar class="clickable cp-datepicker__icon" fill="#484e70" height="24px" width="24px" />
        <Datepicker class="cp-theme" v-model="computedValue" :lower-limit="min" :upper-limit="max"/>
      </div>
    </div>
  </div>
  <!-- <div class="datepicker-input-container clickable" @click="openCalendar($event)">
    <Datetime
      ref="calendar"
      v-model="selectedDate"
      @input="emitValue"
      :placeholder="placeholder"
      :min-datetime="min"
      :max-datetime="max"
      :phrases="btnText"
      input-class="datepicker-input"
      class="cp-theme"
    ></Datetime>
    <icon-calendar class="clickable" fill="#484e70" height="24px" width="24px"></icon-calendar>
  </div> -->
</template>

<script setup>
import { ref, computed, defineEmits, defineProps } from 'vue'

import moment from 'moment'
import Datepicker from 'vue3-datepicker'
import IconCalendar from '../icons/IconCalendar.vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  btnText: Object,
  modelValue: String,
  min: Date,
  max: Date,
  placeholder: String,
  format: {
    type: String,
    default: 'YYYY-MM-DD'
  },
  locale: {
    type: String,
    default: 'eng'
  }
})

const selectedDate = ref(new Date())

if (moment(props.modelValue).isValid()) {
  selectedDate.value = moment(props.modelValue, props.format).toDate()
}

const computedValue = computed({
  get: () => selectedDate.value,
  set: (date) => {
    selectedDate.value = date
    emit('update:modelValue', moment(date).format(props.format))
  }
})

// export default {
//   name: 'UiDatepicker',
//   components: {
//     Datetime,
//     IconCalendar
//   },
//   emits: ['input'],
//   props: {
//     value: {
//       type: String,
//     },
//     placeholder: {
//       type: String,
//       default: ''
//     },
//     locale: {
//       type: String,
//       default: 'eng'
//     },
//     min: {
//       type: String
//     },
//     max: {
//       type: String
//     },
//     btnText: {
//       type: Object
//     }
//   },
//   setup(props, { emit }) {
//     const selectedDate = ref('')
//     const calendar = ref(null)

//     onMounted(() => {
//       Settings.defaultLocale = props.locale
//       selectedDate.value = props.value
//     })

//     watch(() => props.value, () => (selectedDate.value = props.value))

//     function openCalendar(e) {
//       if (e.target.classList.contains('clickable')) {
//         calendar.value.open(e)
//       }
//     }

//     function emitValue() {
//       if (selectedDate.value.length > 0) {
//         emit('input', selectedDate.value)
//       }
//     }

//     return {
//       selectedDate,
//       calendar,
//       openCalendar,
//       emitValue
//     }
//   }
// }
</script>
<style lang="scss">

.cp-datepicker__wrap {
  display: flex;
  flex-direction: column;
}

.cp-datepicker__title {
  margin-left: 30px;
}

.cp-datepicker {
  box-sizing: border-box;
  border: 1px solid #ebf2ff;
  border-radius: 8px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 16px;
  text-align: left;
  cursor: pointer;

  &:hover {
    border: 1.5px solid #969bb0;
  }

  &__target {
    input {
      font-family: 'IBM Plex Sans';
      font-size: 16px;
      line-height: 24px;
      border: none;
      color: #2a2e41;
      cursor: pointer;

      &::placeholder {
        color: #969bb0;
      }
    }
  }
}

.cp-datepicker__target {
  display: flex;
}

.cp-datepicker__icon {
  flex: 1;
  margin-right: 5px;
}

// CALENDAR CUSTOMIZATION

.cp-theme {
  .vdatetime-popup__header,
  .vdatetime-calendar__month__day--selected > span > span,
  .vdatetime-calendar__month__day--selected:hover > span > span {
    background: #0053ff;
  }

  .vdatetime-year-picker__item--selected,
  .vdatetime-month-picker__item--selected,
  .vdatetime-time-picker__item--selected,
  .vdatetime-popup__actions__button {
    color: #0053ff;
  }

  .vdatetime-popup__header {
    font-family: 'IBM Plex Mono';
    border-radius: 8px 8px 0 0;
  }

  .vdatetime-popup__body,
  .vdatetime-popup__actions {
    font-family: 'IBM Plex Sans';
    color: #2a2e41;
  }

  .vdatetime-popup {
    border-radius: 8px;
  }
}
</style>
