import {
  COMPANIES_STORE_GETTERS,
  COMPANIES_STORE_STATE,
} from '@/enums'

import {
  CompaniesStoreState,
  Company,
} from '@/models'

export default {
  [COMPANIES_STORE_GETTERS.COMPANIES]: (state: CompaniesStoreState) => {
    return state[COMPANIES_STORE_STATE.COMPANIES]
  },
  [COMPANIES_STORE_GETTERS.ACTIVE_COMPANY]: (state: CompaniesStoreState) => {
    return state[COMPANIES_STORE_STATE.ACTIVE_COMPANY]
  }
}