<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 20 20"
    :height="height"
    viewBox="0 0 20 20"
    :width="width"
    :fill="fill"
  >
    <g><rect fill="none" height="20" width="20" x="0" /></g>
    <g>
      <path
        d="M17,11.46V6l-3-3H4.5C3.67,3,3,3.67,3,4.5v11C3,16.33,3.67,17,4.5,17h6.97l1.5-1.5H4.5v-11h8.88l2.12,2.12v6.34L17,11.46z M12.5,8.5h-7v-3h7V8.5z M12.25,12.5c0,1.24-1.01,2.25-2.25,2.25s-2.25-1.01-2.25-2.25s1.01-2.25,2.25-2.25S12.25,11.26,12.25,12.5 z M16.82,13.77l1.41,1.41L14.41,19H13l0-1.41L16.82,13.77z M17.31,13.27l0.63-0.63c0.2-0.2,0.51-0.2,0.71,0l0.71,0.71 c0.2,0.2,0.2,0.51,0,0.71l-0.63,0.63L17.31,13.27z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'black'
    },
    viewBox: {
      type: String,
      default: '0 0 24 24'
    },
    width: {
      type: String,
      default: '18px'
    },
    height: {
      type: String,
      default: '18px'
    }
  }
}
</script>
