import { computed } from 'vue'
import { useAccountingSettings } from '@/composables'
import { ACCOUNTING_OBJECT_TYPE, REFERENCE_OBJECT_TYPE, POLICY, APPROVAL_STATE } from '@/enums'
import { AccountingRefs, ApprovalItem } from '@/models'

export default function useAccountingFields() {
  const { accountingObjectsByType } = useAccountingSettings()

  const hasApproverRightsToEdit = (
    approvalItem: ApprovalItem,
    refObjectType: REFERENCE_OBJECT_TYPE,
    accountingObjectType: ACCOUNTING_OBJECT_TYPE
  ) => {
    const workflowPolicies = approvalItem?.workflowPolicies || []
    const activeRound = approvalItem?.decisions?.find((decision) => decision.state === APPROVAL_STATE.PENDING)
    const accountingFieldsPolicy = workflowPolicies.find((policy) => {
      return policy.policyKey === POLICY.INPUT_ACCOUNTING_FIELDS && policy.roundNumber === activeRound?.approvalRoundNo
    })
    const allowedInput = (accountingFieldsPolicy?.policyValue || []).find((policy: any) => {
      return policy.target === refObjectType
    })
    return (allowedInput?.types || []).includes(accountingObjectType)
  }

  const fieldValue = (
    allRefs: AccountingRefs,
    refType: REFERENCE_OBJECT_TYPE,
    refId: string | number,
    objectType: ACCOUNTING_OBJECT_TYPE
  ) => {
    const refsToArray = Object.values(allRefs || {})
    const found = refsToArray.find((oneRef) => {
      return oneRef.refType === refType && oneRef.refId === Number(refId) && oneRef.accounting?.type === objectType
    })
    return found?.accounting?.id || ''
  }

  const mapOptions = (objectType: ACCOUNTING_OBJECT_TYPE) => {
    return accountingObjectsByType(objectType).map((accountingObject) => ({
      title: `${accountingObject.value} - ${accountingObject.name}`,
      value: accountingObject.id,
      disabled: !accountingObject.active
    }))
  }

  const accountingFieldOptions = computed(() => {
    return {
      [ACCOUNTING_OBJECT_TYPE.ACCOUNT]: {
        type: ACCOUNTING_OBJECT_TYPE.ACCOUNT,
        options: mapOptions(ACCOUNTING_OBJECT_TYPE.ACCOUNT),
        getValue: (allRefs: AccountingRefs, refType: REFERENCE_OBJECT_TYPE, refId: string | number) => {
          return fieldValue(allRefs, refType, refId, ACCOUNTING_OBJECT_TYPE.ACCOUNT)
        }
      },
      [ACCOUNTING_OBJECT_TYPE.DEPARTMENT]: {
        type: ACCOUNTING_OBJECT_TYPE.DEPARTMENT,
        options: mapOptions(ACCOUNTING_OBJECT_TYPE.DEPARTMENT),
        getValue: (allRefs: AccountingRefs, refType: REFERENCE_OBJECT_TYPE, refId: string | number) => {
          return fieldValue(allRefs, refType, refId, ACCOUNTING_OBJECT_TYPE.DEPARTMENT)
        }
      },
      [ACCOUNTING_OBJECT_TYPE.DIMENSION]: {
        type: ACCOUNTING_OBJECT_TYPE.DIMENSION,
        options: mapOptions(ACCOUNTING_OBJECT_TYPE.DIMENSION),
        getValue: (allRefs: AccountingRefs, refType: REFERENCE_OBJECT_TYPE, refId: string | number) => {
          return fieldValue(allRefs, refType, refId, ACCOUNTING_OBJECT_TYPE.DIMENSION)
        }
      },
      [ACCOUNTING_OBJECT_TYPE.ITEM_CODE]: {
        type: ACCOUNTING_OBJECT_TYPE.ITEM_CODE,
        options: mapOptions(ACCOUNTING_OBJECT_TYPE.ITEM_CODE),
        getValue: (allRefs: AccountingRefs, refType: REFERENCE_OBJECT_TYPE, refId: string | number) => {
          return fieldValue(allRefs, refType, refId, ACCOUNTING_OBJECT_TYPE.ITEM_CODE)
        }
      },
      [ACCOUNTING_OBJECT_TYPE.PROJECT]: {
        type: ACCOUNTING_OBJECT_TYPE.PROJECT,
        options: mapOptions(ACCOUNTING_OBJECT_TYPE.PROJECT),
        getValue: (allRefs: AccountingRefs, refType: REFERENCE_OBJECT_TYPE, refId: string | number) => {
          return fieldValue(allRefs, refType, refId, ACCOUNTING_OBJECT_TYPE.PROJECT)
        }
      },
      [ACCOUNTING_OBJECT_TYPE.COST_OBJECTIVE]: {
        type: ACCOUNTING_OBJECT_TYPE.COST_OBJECTIVE,
        options: mapOptions(ACCOUNTING_OBJECT_TYPE.COST_OBJECTIVE),
        getValue: (allRefs: AccountingRefs, refType: REFERENCE_OBJECT_TYPE, refId: string | number) => {
          return fieldValue(allRefs, refType, refId, ACCOUNTING_OBJECT_TYPE.COST_OBJECTIVE)
        }
      },
      [ACCOUNTING_OBJECT_TYPE.VAT_CODE]: {
        type: ACCOUNTING_OBJECT_TYPE.VAT_CODE,
        options: mapOptions(ACCOUNTING_OBJECT_TYPE.VAT_CODE),
        getValue: (allRefs: AccountingRefs, refType: REFERENCE_OBJECT_TYPE, refId: string | number) => {
          return fieldValue(allRefs, refType, refId, ACCOUNTING_OBJECT_TYPE.VAT_CODE)
        }
      }
    }
  })

  return {
    accountingFieldOptions,
    hasApproverRightsToEdit
  }
}
