<template>
  <button
    :class="btnType"
    class="fab"
    @click="$emit('click')"
  >
    <span class="fab-icon">
      <slot></slot>
    </span>
  </button>
</template>

<script>
export default {
  name: 'UiFabButton',
  props: {
    btnType: {
      type: String,
      default: 'primary'
    }
  },
  emits: ['click']
}
</script>

<style lang="scss" scoped>
.fab {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  box-shadow: 0px 0px 8px rgba(0, 83, 255, 0.16), 0px 0px 4px rgba(72, 78, 112, 0.12);
  transition: all 150ms ease-in-out;

  .fab-icon {
    height: 28px;

    svg {
      height: 28px;
      width: 28px;
    }
  }

}

/*  Primary FAB  */

[class*="primary"] {
  background: $main-blue;

  &:hover {
    background: #3375ff;
  }

  &:active {
    background: #6698ff;
  }

  .fab-icon svg {
    fill: $white;
  }
}

/*  Secondary FAB  */

[class*="secondary"] {
  background: $white;

  &:hover {
    background: #f5f8ff;
  }

  &:active {
    background: #ebf2ff;
  }

  .fab-icon svg {
    fill: $main-blue;
  }
}

/* smaller size */

[class*="small"] {
  width: 40px;
  height: 40px;

  .fab-icon {
    height: 20px;

    svg {
      height: 20px;
      width: 20px;
    }
  }
}
</style>
