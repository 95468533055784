<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 24 24"
    :fill="fill"
    :height="height"
    :viewBox="viewBox"
    :width="width"
  >
    <g>
      <g><path d="M0,0h24v24H0V0z" fill="none" /></g>
    </g>
    <g>
      <g>
        <path
          d="M17.59,5.59C17.21,5.21,16.7,5,16.17,5H3C1.9,5,1,5.89,1,7v7c0,1.1,0.9,2,2,2h0c0,1.66,1.34,3,3,3s3-1.34,3-3h6 c0,1.66,1.34,3,3,3s3-1.34,3-3h0c1.1,0,2-0.9,2-2v-2.17c0-0.53-0.21-1.04-0.59-1.41L17.59,5.59z M15,7h1l3,3h-4V7z M9,7h4v3H9V7z M3,7h4v3H3V7z M6,17.25c-0.69,0-1.25-0.56-1.25-1.25S5.31,14.75,6,14.75S7.25,15.31,7.25,16C7.25,16.69,6.69,17.25,6,17.25z M18,17.25c-0.69,0-1.25-0.56-1.25-1.25s0.56-1.25,1.25-1.25s1.25,0.56,1.25,1.25C19.25,16.69,18.69,17.25,18,17.25z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'black'
    },
    viewBox: {
      type: String,
      default: '0 0 24 24'
    },
    width: {
      type: String,
      default: '18px'
    },
    height: {
      type: String,
      default: '18px'
    }
  }
}
</script>
