export const ALLOWED_OPERATORS = {
  isGreaterThan: '>',
  isGreaterOrEqualThan: '>=',
  isLesserThan: '<',
  isLesserOrEqualThan: '<=',
  isEqualWith: '=',
  whereNotEqualTo: '!=',
  isBetween: '<>',
  and: '^',
  isEqualTo: '~',
  isNotEqualTo: '!~',
  continue: ';'
}

export const ALLOWED_WHERE_KEYS = {
  paymentTransactionsCount: 'paymentTransactionsCount',
  currencyTotals: 'currencyTotals',
  submitter: 'submitter',
  startDate: 'startDate',
  createdAt: 'createdAt',
  endDate: 'endDate',
  deleted: 'deleted',
  summary: 'summary',
  subtype: 'subtype',
  tripId: 'tripId',
  userId: 'userId',
  status: 'status',
  issued: 'issued',
  type: 'type'
}

export const ALLOWED_ORDER_BY_KEYS = {
  createdAt: 'createdAt',
  supplier: 'supplier',
  userId: 'userId',
  status: 'status',
  issued: 'issued'
}

function singleValue(key, operator, value) {
  return `${key}${operator}${value}`
}

function inBetweenValue(key, operator, compareOperator, value_1, value_2) {
  return `${key}${operator}${value_1}${compareOperator}${value_2}`
}

function limit(value) {
  const query = singleValue('limit', ALLOWED_OPERATORS.isEqualWith, value)
  this.addToQuery(query)

  return this
}

function offset(value) {
  const query = singleValue('offset', ALLOWED_OPERATORS.isEqualWith, value)
  this.addToQuery(query)

  return this
}

function includeSummary(value) {
  const query = singleValue('summary', ALLOWED_OPERATORS.isEqualWith, value)
  this.addToQuery(query)

  return this
}

function includeCurrencyTotals(value) {
  const query = singleValue('currencyTotals', ALLOWED_OPERATORS.isEqualWith, value)
  this.addToQuery(query)

  return this
}

function includeSubmitter(value) {
  const query = singleValue('submitter', ALLOWED_OPERATORS.isEqualWith, value)
  this.addToQuery(query)

  return this
}

function includeItemRows(value) {
  const query = singleValue('itemrows', ALLOWED_OPERATORS.isEqualWith, value)
  this.addToQuery(query)

  return this
}

function includeTransactionCount(value) {
  const query = singleValue('paymentTransactionsCount', ALLOWED_OPERATORS.isEqualWith, value)
  this.addToQuery(query)

  return this
}

function orderBy(value) {
  if (Object.prototype.hasOwnProperty.call(ALLOWED_ORDER_BY_KEYS, value.replace('-', ''))) {
    const query = singleValue('orderby', ALLOWED_OPERATORS.isEqualWith, value)
    this.addToQuery(query)
  }

  return this
}

function status(value, operator) {
  const query = singleValue('status', ALLOWED_OPERATORS[operator], value)
  this.addToQuery(query)

  return this
}

function paymentCard(value) {
  const query = singleValue('paymentCard', ALLOWED_OPERATORS.isEqualWith, value)
  this.addToQuery(query)

  return this
}

function isEqualTo(key) {
  return (value) => singleValue(key, ALLOWED_OPERATORS.isEqualTo, value)
}

function isNotEqualTo(key) {
  return (value) => singleValue(key, ALLOWED_OPERATORS.isNotEqualTo, value)
}

function isEqualWith(key) {
  return (value) => singleValue(key, ALLOWED_OPERATORS.isEqualWith, value)
}

function isLesserThan(key) {
  return (value) => singleValue(key, ALLOWED_OPERATORS.isLesserThan, value)
}

function isGreaterThan(key) {
  return (value) => singleValue(key, ALLOWED_OPERATORS.isGreaterThan, value)
}

function isGreaterOrEqualThan(key) {
  return (value) => singleValue(key, ALLOWED_OPERATORS.isGreaterOrEqualThan, value)
}

function whereNotEqualTo(key) {
  return (value) => singleValue(key, ALLOWED_OPERATORS.whereNotEqualTo, value)
}

function where(key, callbackFn) {
  if (Object.prototype.hasOwnProperty.call(ALLOWED_WHERE_KEYS, key)) {
    const resultQuery = callbackFn({
      isEqualTo: this.isEqualTo(key),
      isNotEqualTo: this.isNotEqualTo(key),
      isEqualWith: this.isEqualWith(key),
      isBetween: this.isBetween(key),
      isLesserThan: this.isLesserThan(key),
      isGreaterThan: this.isGreaterThan(key),
      isGreaterOrEqualThan: this.isGreaterOrEqualThan(key),
      whereNotEqualTo: this.whereNotEqualTo(key)
    })

    if (resultQuery) {
      this.addToWhereQuery(resultQuery)
    }
  }

  return this
}

function addToQuery(domain) {
  if (Array.isArray(domain)) {
    domain.forEach((query) => this._query.push(query))
  } else {
    this._query.push(domain)
  }
}

function addToWhereQuery(domain) {
  if (Array.isArray(domain)) {
    domain.forEach((query) => this._whereQuery.push(query))
  } else {
    this._whereQuery.push(domain)
  }
}

function isBetween(key) {
  return (value1, value2) => inBetweenValue(key, ALLOWED_OPERATORS.isBetween, ALLOWED_OPERATORS.and, value1, value2)
}

function base(base = '') {
  this._base = base

  return this
}

function get() {
  const query = this._query.join('&')
  const whereQuery = this._whereQuery.join(';')
  const final = [query, whereQuery].join(whereQuery.length ? '&where=' : '')

  return [this._base, final].join('?')
}

function clone(queryCreatorInstance = {}) {
  // This should have better solution
  // but i have no idea how to kill a prototype (Please update as fast as possible)
  const cleanArgs = Object.values(JSON.parse(JSON.stringify(queryCreatorInstance)))
  const instance = new QueryCreator(...cleanArgs)

  return instance
}

function QueryCreator(_base = '', _query = [], _whereQuery = []) {
  this._base = _base
  this._query = _query
  this._whereQuery = _whereQuery
}

function queryCreator(...args) {
  return new QueryCreator(...args)
}

QueryCreator.prototype = queryCreator.prototype = {
  includeTransactionCount,
  includeCurrencyTotals,
  includeSubmitter,
  includeItemRows,
  includeSummary,
  isEqualTo,
  isNotEqualTo,
  isEqualWith,
  isBetween,
  isLesserThan,
  isGreaterThan,
  isGreaterOrEqualThan,
  whereNotEqualTo,
  addToQuery,
  addToWhereQuery,
  paymentCard,
  orderBy,
  offset,
  status,
  clone,
  limit,
  where,
  base,
  get
}

export default queryCreator
