import { INTEGRATIONS_STORE_MUTATIONS, INTEGRATIONS_STORE_STATE } from '@/enums'
import { IntegrationsStoreState, Integration, ServiceActivation } from '@/models'

export default {
  [INTEGRATIONS_STORE_MUTATIONS.SET_ACTIVE_INTEGRATION]: (state: IntegrationsStoreState, integration: Integration) => {
    state[INTEGRATIONS_STORE_STATE.ACTIVE_INTEGRATION] = integration
  },
  [INTEGRATIONS_STORE_MUTATIONS.SET_SERVICE_ACTIVATION]: (
    state: IntegrationsStoreState,
    serviceActivation: ServiceActivation
  ) => {
    state[INTEGRATIONS_STORE_STATE.SERVICE_ACTIVATION] = serviceActivation
  }
}
