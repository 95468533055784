import Axios from 'axios'
import getRandomId from '@/lib/helpers/getRandomId'

const state = {
  activeRequests: {
    // global state shared by modules
    // example..
    // [randomId]: {
    //    receiptId: receiptId,
    //    companyId: companyId,
    //    cancelToken: generatedToken,
    //    caller: 'GET_OCR
    // }
  }
}

const actions = {
  CANCEL_ACTIVE_REQUEST: (store, { target, ...identifiers }) => {
    const requestId = Object.keys(store.state.activeRequests).find((id) => {
      const request = store.state.activeRequests[id]
      const isRightCaller = request.caller === target
      const rightIdentifiers = Object.entries(identifiers).every(([key, value]) => request[key] === value)

      return isRightCaller && rightIdentifiers
    })

    if (requestId && store.state.activeRequests[requestId]?.cancelToken) {
      store.state.activeRequests[requestId].cancelToken.cancel('CANCEL_REQUEST')
    }

    store.commit('REMOVE_ACTIVE_REQUEST', {
      id: requestId
    })
  }
}

const mutations = {
  /**
   * If id is not given, generate one
   * If cancelToken not given, generate one
   */
  SET_ACTIVE_REQUEST: (state, { id = getRandomId(), ...payload }) => {
    state.activeRequests[id] = payload

    // Just in case add canceltoken if not included
    if (!payload.cancelToken) {
      state.activeRequests[id].cancelToken = Axios.CancelToken.source()
    }
  },
  REMOVE_ACTIVE_REQUEST: (state, { id }) => {
    delete state.activeRequests[id]
  }
}

const getters = {
  IS_PROCESS_ACTIVE:
    ({ activeRequests }) =>
    ({ target, ...identifiers }) => {
      return !!Object.values(activeRequests).find((targetRequest) => {
        const foundTarget = targetRequest.caller === target
        const allRightIdentifiers = Object.entries(identifiers).every(([identifierKey, identifierValue]) => {
          return targetRequest[identifierKey] === identifierValue
        })
        return foundTarget && allRightIdentifiers
      })
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
