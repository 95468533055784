import { computed } from 'vue'
import { uniq, deburr } from 'lodash-es'
import currencyCodes from 'currency-codes'
import { useCompanies, useReceipts } from '@/composables'

export default function useHelpers() {
  const { activeCompany } = useCompanies()
  const { companyReceipts, activeReceipt } = useReceipts()

  const companyRoles = computed(() => activeCompany.value?.companyRoles || [])

  const findDocumentSubmitter = (document: any) => {
    const submitterFullName = document?.submitter?.fullName
    let submitterFromRoles
    const submitter = companyRoles.value.find((r) => r.userId === document.userId)
    if (submitter?.firstName && submitter?.lastName) {
      submitterFromRoles = `${submitter.firstName} ${submitter.lastName}`
    }
    const submitterEmail = document?.submitter?.email
    return submitterFullName || submitterFromRoles || submitterEmail || '-'
  }

  const findUserFullName = (userId: number | undefined) => {
    const submitter = companyRoles.value.find((role) => role.userId === userId)
    return `${submitter?.firstName || ''} ${submitter?.lastName || ''}`.trim()
  }

  const findFileExtension = (fileName: string) => {
    if (!fileName) return ''
    const testExpression = /(?:\.([^.]+))?$/
    return testExpression.exec(fileName)?.[1]
  }

  const safeAdd = (value1: number | string, value2: number | string) => {
    return Number((Number(value1 || 0) + Number(value2 || 0)).toFixed(2))
  }

  const search = (searchableWord: string, searchInput: string): Boolean => {
    const modifiedInput = deburr(searchInput || '').trim().toLowerCase()
    return deburr(searchableWord || '').trim().toLowerCase().includes(modifiedInput)
  }

  const findMostUsedCurrencies = (value: string): (string | null)[] => {
    const lastReceipts = companyReceipts.value.slice(-20)
    const popularCurrencies = ['EUR', 'GBP', 'USD', 'PLN', 'SEK']

    const filteredCurrencies = lastReceipts
      .filter(({ currency }) => currency !== null && currency !== '')
      .map(({ currency }) => currency)
      .filter((c, i, a) => {
        return a.indexOf(c) === i
      })

    const filteredPopularCurrencies = popularCurrencies.filter((c) => !filteredCurrencies.includes(c))

    let payload = filteredCurrencies.concat(filteredPopularCurrencies)

    // Filter out any currencies that do not match the strict format of three capital letters
    payload = payload.filter((currency) => currency && /^[A-Z]{3}$/.test(currency))

    // Add all other currencies from the library
    let currencies = payload.slice(0, 6).concat(currencyCodes.codes().filter((currency) => /^[A-Z]{3}$/.test(currency)))

    // Filter currencies by the provided value if it exists
    currencies = currencies.filter((currency) => currency && currency.includes(value))

    // Remove duplicates
    currencies = Array.from(new Set(currencies))

    return currencies
  }

  const ocrWordList = () => {
    const words = activeReceipt.value?.ocr?.wordList || []
    const expressions = activeReceipt.value?.ocr?.expressionList || []
    return uniq([...words, ...expressions])
  }

  function updateObject(initialObject: any, updatedObject: any) {
    for (let key in updatedObject) {
      // Check if the property is an object or array (nested object or array)
      if (typeof updatedObject[key] === 'object' && updatedObject[key] !== null && !Array.isArray(updatedObject[key])) {
        // Recursively update nested objects
        initialObject[key] = updateObject(initialObject[key] || {}, updatedObject[key])
      } else {
        // Update only if the value is different from the initial value
        if (initialObject[key] !== updatedObject[key]) {
          initialObject[key] = updatedObject[key]
        }
      }
    }
    return initialObject
  }

  return {
    safeAdd,
    search,
    findUserFullName,
    findDocumentSubmitter,
    findMostUsedCurrencies,
    findFileExtension,
    ocrWordList,
    updateObject
  }
}
