import { PAYMENTS_STORE_MUTATIONS, PAYMENTS_STORE_STATE } from '@/enums'
import { PaymentMethod, PaymentsStoreState } from '@/models'

export default {
  [PAYMENTS_STORE_MUTATIONS.SET_PAYMENT_METHODS]: (
    state: PaymentsStoreState,
    payload: { paymentMethods: PaymentMethod[]; companyId: string | number }
  ) => {
    const { companyId, paymentMethods } = payload
    if (!companyId) return
    if (!state[PAYMENTS_STORE_STATE.PAYMENT_METHODS][companyId]) {
      state[PAYMENTS_STORE_STATE.PAYMENT_METHODS][companyId] = {}
    }
    paymentMethods.forEach((paymentMethod: PaymentMethod) => {
      state[PAYMENTS_STORE_STATE.PAYMENT_METHODS][companyId][paymentMethod.id] = paymentMethod
    })
  },
  [PAYMENTS_STORE_MUTATIONS.REMOVE_PAYMENT_METHOD]: (
    state: PaymentsStoreState,
    payload: { companyId: string | number; paymentMethodId: string | number }
  ) => {
    const { companyId, paymentMethodId } = payload
    if (!companyId || !paymentMethodId) return
    if (state[PAYMENTS_STORE_STATE.PAYMENT_METHODS]?.[companyId]?.[paymentMethodId]) {
      delete state[PAYMENTS_STORE_STATE.PAYMENT_METHODS][companyId][paymentMethodId]
    }
  }
}
