module.exports = {
  AL: 265,
  DZ: 376,
  AO: 956,
  AG: 814,
  AR: 305,
  AM: 544,
  AU: 744,
  AZ: 436,
  BS: 1140,
  BH: 867,
  BD: 520,
  BB: 1095,
  BE: 794,
  BZ: 658,
  BJ: 531,
  BO: 438,
  BW: 379,
  BR: 430,
  BG: 389,
  BF: 433,
  CF: 461,
  CL: 487,
  CO: 260,
  CR: 610,
  CY: 613,
  DK: 1051,
  DJ: 677,
  EC: 689,
  EG: 275,
  SV: 565,
  ER: 480,
  EE: 526,
  ET: 269,
  PH: 468,
  FI: 786,
  FR: 852,
  AE: 1057,
  GA: 680,
  GM: 321,
  GE: 315,
  GH: 260,
  GR: 639,
  GD: 698,
  GL: 1051,
  GY: 660,
  HT: 700,
  HN: 490,
  IN: 359,
  ID: 483,
  IQ: 659,
  IR: 1880,
  IE: 827,
  IS: 889,
  IL: 1060,
  IT: 701,
  JM: 529,
  JP: 480,
  JO: 914,
  KH: 573,
  CM: 517,
  CA: 844,
  KZ: 261,
  KE: 502,
  CN: 688,
  KG: 260,
  HR: 472,
  CU: 613,
  KW: 899,
  LA: 260,
  LV: 603,
  LR: 784,
  LY: 260,
  LI: 1032,
  LT: 497,
  LU: 847,
  MO: 688,
  MG: 260,
  MW: 314,
  MY: 335,
  MV: 491,
  ML: 467,
  MT: 580,
  MA: 454,
  MR: 398,
  MU: 440,
  MX: 507,
  FM: 560,
  MD: 337,
  MC: 898,
  MN: 395,
  ME: 392,
  NL: 721,
  NP: 287,
  NI: 470,
  NE: 361,
  NG: 663,
  NO: 1019,
  NZ: 623,
  OM: 915,
  PK: 260,
  PA: 682,
  PG: 634,
  PY: 343,
  PE: 480,
  PL: 457,
  PT: 499,
  PR: 1113,
  QA: 897,
  RO: 357,
  RW: 331,
  RU: 727,
  SM: 701,
  SA: 1008,
  CH: 1149,
  SN: 561,
  RS: 390,
  SC: 1058,
  SL: 262,
  SG: 862,
  SK: 548,
  SI: 539,
  ES: 646,
  LK: 260,
  SD: 522,
  SZ: 260,
  ZA: 289,
  KR: 636,
  TW: 631,
  TZ: 462,
  TH: 546,
  CZ: 529,
  TG: 530,
  TO: 482,
  TT: 927,
  TN: 260,
  TR: 260,
  TM: 1533,
  DE: 718,
  UG: 415,
  UA: 331,
  HU: 385,
  UY: 547,
  US: 1113,
  UZ: 260,
  VU: 731,
  VE: 367,
  VN: 411,
  ZM: 630,
  ZW: 260,
  AT: 669,
  BY: 317,
  BA: 345,
  BN: 524,
  MM: 260,
  CI: 693,
  GN: 694,
  HK: 688,
  CG: 576,
  CD: 808,
  XK: 274,
  MZ: 415,
  MK: 305,
  LC: 681,
  VC: 590,
  WS: 608,
  GB: 779,
  AN: 754,
  SE: 260
}
