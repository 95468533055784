import { USERS_STORE_ACTIONS, USERS_STORE_GETTERS, USERS_STORE_MUTATIONS, USERS_STORE_STATE } from '@/enums'
import errorHandler from '@/lib/errorHandler'
import { root } from '@/store/store.root'
import { User } from '@/models'
import Axios from 'axios'

export const users = root.createModule('users', {
  state: {
    [USERS_STORE_STATE.ACTIVE_USER]: undefined as undefined | User
  },
  getters: {
    [USERS_STORE_GETTERS.ACTIVE_USER]: (state, getters, rootState, rootGetters) => {
      
      return state[USERS_STORE_STATE.ACTIVE_USER]
    } 
  },
  mutations: {
    [USERS_STORE_MUTATIONS.SET_USER]: (state, user: User) => {
      state[USERS_STORE_STATE.ACTIVE_USER] = user
    }
  },
  actions: {
    [USERS_STORE_ACTIONS.GET_USER]: async ({ commit }) => {
      try {
        const { data = {} } = await Axios.get('@coreapi/user')
        commit(USERS_STORE_MUTATIONS.SET_USER, data)
  
        return data
      } catch (error: any) {
        errorHandler(USERS_STORE_ACTIONS.GET_USER, error)
  
        // clear auth state
        localStorage.removeItem('token')
      }
    },
    [USERS_STORE_ACTIONS.UPDATE_USER]: async ({ commit }: any, payload: any = {}) => {
      try {
        const { data = {} } = await Axios.put('@coreapi/user/edit', payload)
        commit(USERS_STORE_MUTATIONS.SET_USER, data)
  
        return data
      } catch (error: any) {
        throw error
      }
    }
  }
})