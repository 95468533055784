module.exports = [
  { code: 'EE',
    emoji: '🇪🇪',
    unicode: 'U+1F1EA U+1F1EA',
    name: 'Estonia' },
  { code: 'FI',
    emoji: '🇫🇮',
    unicode: 'U+1F1EB U+1F1EE',
    name: 'Finland' },
  { code: 'LV',
    emoji: '🇱🇻',
    unicode: 'U+1F1F1 U+1F1FB',
    name: 'Latvia' },
  { code: 'PL',
    emoji: '🇵🇱',
    unicode: 'U+1F1F5 U+1F1F1',
    name: 'Poland' },
  { code: 'LT',
    emoji: '🇱🇹',
    unicode: 'U+1F1F1 U+1F1F9',
    name: 'Lithuania' },
  { code: 'RU',
    emoji: '🇷🇺',
    unicode: 'U+1F1F7 U+1F1FA',
    name: 'Russia' },
  { code: 'SE',
    emoji: '🇸🇪',
    unicode: 'U+1F1F8 U+1F1EA',
    name: 'Sweden' },  
  { code: 'DE',
    emoji: '🇩🇪',
    unicode: 'U+1F1E9 U+1F1EA',
    name: 'Germany' },  
  { code: 'AF',
    emoji: '🇦🇫',
    unicode: 'U+1F1E6 U+1F1EB',
    name: 'Afghanistan' },
  { code: 'AL',
    emoji: '🇦🇱',
    unicode: 'U+1F1E6 U+1F1F1',
    name: 'Albania' },
  { code: 'DZ',
    emoji: '🇩🇿',
    unicode: 'U+1F1E9 U+1F1FF',
    name: 'Algeria' },
  { code: 'AS',
    emoji: '🇦🇸',
    unicode: 'U+1F1E6 U+1F1F8',
    name: 'American Samoa' },
  { code: 'AD',
    emoji: '🇦🇩',
    unicode: 'U+1F1E6 U+1F1E9',
    name: 'Andorra' },
  { code: 'AO',
    emoji: '🇦🇴',
    unicode: 'U+1F1E6 U+1F1F4',
    name: 'Angola' },
  { code: 'AI',
    emoji: '🇦🇮',
    unicode: 'U+1F1E6 U+1F1EE',
    name: 'Anguilla' },
  { code: 'AQ',
    emoji: '🇦🇶',
    unicode: 'U+1F1E6 U+1F1F6',
    name: 'Antarctica' },
  { code: 'AG',
    emoji: '🇦🇬',
    unicode: 'U+1F1E6 U+1F1EC',
    name: 'Antigua and Barbuda' },
  { code: 'AR',
    emoji: '🇦🇷',
    unicode: 'U+1F1E6 U+1F1F7',
    name: 'Argentina' },
  { code: 'AM',
    emoji: '🇦🇲',
    unicode: 'U+1F1E6 U+1F1F2',
    name: 'Armenia' },
  { code: 'AW',
    emoji: '🇦🇼',
    unicode: 'U+1F1E6 U+1F1FC',
    name: 'Aruba' },
  { code: 'AU',
    emoji: '🇦🇺',
    unicode: 'U+1F1E6 U+1F1FA',
    name: 'Australia' },
  { code: 'AT',
    emoji: '🇦🇹',
    unicode: 'U+1F1E6 U+1F1F9',
    name: 'Austria' },
  { code: 'AZ',
    emoji: '🇦🇿',
    unicode: 'U+1F1E6 U+1F1FF',
    name: 'Azerbaijan' },
  { code: 'BS',
    emoji: '🇧🇸',
    unicode: 'U+1F1E7 U+1F1F8',
    name: 'Bahamas' },
  { code: 'BH',
    emoji: '🇧🇭',
    unicode: 'U+1F1E7 U+1F1ED',
    name: 'Bahrain' },
  { code: 'BD',
    emoji: '🇧🇩',
    unicode: 'U+1F1E7 U+1F1E9',
    name: 'Bangladesh' },
  { code: 'BB',
    emoji: '🇧🇧',
    unicode: 'U+1F1E7 U+1F1E7',
    name: 'Barbados' },
  { code: 'BY',
    emoji: '🇧🇾',
    unicode: 'U+1F1E7 U+1F1FE',
    name: 'Belarus' },
  { code: 'BE',
    emoji: '🇧🇪',
    unicode: 'U+1F1E7 U+1F1EA',
    name: 'Belgium' },
  { code: 'BZ',
    emoji: '🇧🇿',
    unicode: 'U+1F1E7 U+1F1FF',
    name: 'Belize' },
  { code: 'BJ',
    emoji: '🇧🇯',
    unicode: 'U+1F1E7 U+1F1EF',
    name: 'Benin' },
  { code: 'BM',
    emoji: '🇧🇲',
    unicode: 'U+1F1E7 U+1F1F2',
    name: 'Bermuda' },
  { code: 'BT',
    emoji: '🇧🇹',
    unicode: 'U+1F1E7 U+1F1F9',
    name: 'Bhutan' },
  { code: 'BO',
    emoji: '🇧🇴',
    unicode: 'U+1F1E7 U+1F1F4',
    name: 'Bolivia' },
  { code: 'BQ',
    emoji: '🇧🇶',
    unicode: 'U+1F1E7 U+1F1F6',
    name: 'Bonaire, Sint Eustatius and Saba' },
  { code: 'BA',
    emoji: '🇧🇦',
    unicode: 'U+1F1E7 U+1F1E6',
    name: 'Bosnia and Herzegovina' },
  { code: 'BW',
    emoji: '🇧🇼',
    unicode: 'U+1F1E7 U+1F1FC',
    name: 'Botswana' },
  { code: 'BV',
    emoji: '🇧🇻',
    unicode: 'U+1F1E7 U+1F1FB',
    name: 'Bouvet Island' },
  { code: 'BR',
    emoji: '🇧🇷',
    unicode: 'U+1F1E7 U+1F1F7',
    name: 'Brazil' },
  { code: 'IO',
    emoji: '🇮🇴',
    unicode: 'U+1F1EE U+1F1F4',
    name: 'British Indian Ocean Territory' },
  { code: 'BN',
    emoji: '🇧🇳',
    unicode: 'U+1F1E7 U+1F1F3',
    name: 'Brunei Darussalam' },
  { code: 'BG',
    emoji: '🇧🇬',
    unicode: 'U+1F1E7 U+1F1EC',
    name: 'Bulgaria' },
  { code: 'BF',
    emoji: '🇧🇫',
    unicode: 'U+1F1E7 U+1F1EB',
    name: 'Burkina Faso' },
  { code: 'BI',
    emoji: '🇧🇮',
    unicode: 'U+1F1E7 U+1F1EE',
    name: 'Burundi' },
  { code: 'KH',
    emoji: '🇰🇭',
    unicode: 'U+1F1F0 U+1F1ED',
    name: 'Cambodia' },
  { code: 'CM',
    emoji: '🇨🇲',
    unicode: 'U+1F1E8 U+1F1F2',
    name: 'Cameroon' },
  { code: 'CA',
    emoji: '🇨🇦',
    unicode: 'U+1F1E8 U+1F1E6',
    name: 'Canada' },
  { code: 'CV',
    emoji: '🇨🇻',
    unicode: 'U+1F1E8 U+1F1FB',
    name: 'Cape Verde' },
  { code: 'KY',
    emoji: '🇰🇾',
    unicode: 'U+1F1F0 U+1F1FE',
    name: 'Cayman Islands' },
  { code: 'CF',
    emoji: '🇨🇫',
    unicode: 'U+1F1E8 U+1F1EB',
    name: 'Central African Republic' },
  { code: 'TD',
    emoji: '🇹🇩',
    unicode: 'U+1F1F9 U+1F1E9',
    name: 'Chad' },
  { code: 'CL',
    emoji: '🇨🇱',
    unicode: 'U+1F1E8 U+1F1F1',
    name: 'Chile' },
  { code: 'CN',
    emoji: '🇨🇳',
    unicode: 'U+1F1E8 U+1F1F3',
    name: 'China' },
  { code: 'CX',
    emoji: '🇨🇽',
    unicode: 'U+1F1E8 U+1F1FD',
    name: 'Christmas Island' },
  { code: 'CC',
    emoji: '🇨🇨',
    unicode: 'U+1F1E8 U+1F1E8',
    name: 'Cocos (Keeling) Islands' },
  { code: 'CO',
    emoji: '🇨🇴',
    unicode: 'U+1F1E8 U+1F1F4',
    name: 'Colombia' },
  { code: 'KM',
    emoji: '🇰🇲',
    unicode: 'U+1F1F0 U+1F1F2',
    name: 'Comoros' },
  { code: 'CD',
    emoji: '🇨🇩',
    unicode: 'U+1F1E8 U+1F1E9',
    name: 'Congo' },
  { code: 'CG',
    emoji: '🇨🇬',
    unicode: 'U+1F1E8 U+1F1EC',
    name: 'Congo' },
  { code: 'CK',
    emoji: '🇨🇰',
    unicode: 'U+1F1E8 U+1F1F0',
    name: 'Cook Islands' },
  { code: 'CR',
    emoji: '🇨🇷',
    unicode: 'U+1F1E8 U+1F1F7',
    name: 'Costa Rica' },
  { code: 'HR',
    emoji: '🇭🇷',
    unicode: 'U+1F1ED U+1F1F7',
    name: 'Croatia' },
  { code: 'CU',
    emoji: '🇨🇺',
    unicode: 'U+1F1E8 U+1F1FA',
    name: 'Cuba' },
  { code: 'CW',
    emoji: '🇨🇼',
    unicode: 'U+1F1E8 U+1F1FC',
    name: 'Curaçao' },
  { code: 'CY',
    emoji: '🇨🇾',
    unicode: 'U+1F1E8 U+1F1FE',
    name: 'Cyprus' },
  { code: 'CZ',
    emoji: '🇨🇿',
    unicode: 'U+1F1E8 U+1F1FF',
    name: 'Czech Republic' },
  { code: 'CI',
    emoji: '🇨🇮',
    unicode: 'U+1F1E8 U+1F1EE',
    name: 'Côte D\'Ivoire' },
  { code: 'DK',
    emoji: '🇩🇰',
    unicode: 'U+1F1E9 U+1F1F0',
    name: 'Denmark' },
  { code: 'DJ',
    emoji: '🇩🇯',
    unicode: 'U+1F1E9 U+1F1EF',
    name: 'Djibouti' },
  { code: 'DM',
    emoji: '🇩🇲',
    unicode: 'U+1F1E9 U+1F1F2',
    name: 'Dominica' },
  { code: 'DO',
    emoji: '🇩🇴',
    unicode: 'U+1F1E9 U+1F1F4',
    name: 'Dominican Republic' },
  { code: 'EC',
    emoji: '🇪🇨',
    unicode: 'U+1F1EA U+1F1E8',
    name: 'Ecuador' },
  { code: 'EG',
    emoji: '🇪🇬',
    unicode: 'U+1F1EA U+1F1EC',
    name: 'Egypt' },
  { code: 'SV',
    emoji: '🇸🇻',
    unicode: 'U+1F1F8 U+1F1FB',
    name: 'El Salvador' },
  { code: 'GQ',
    emoji: '🇬🇶',
    unicode: 'U+1F1EC U+1F1F6',
    name: 'Equatorial Guinea' },
  { code: 'ER',
    emoji: '🇪🇷',
    unicode: 'U+1F1EA U+1F1F7',
    name: 'Eritrea' },
  { code: 'ET',
    emoji: '🇪🇹',
    unicode: 'U+1F1EA U+1F1F9',
    name: 'Ethiopia' },
  { code: 'EU',
    emoji: '🇪🇺',
    unicode: 'U+1F1EA U+1F1FA',
    name: 'European Union' },
  { code: 'FK',
    emoji: '🇫🇰',
    unicode: 'U+1F1EB U+1F1F0',
    name: 'Falkland Islands (Malvinas)' },
  { code: 'FO',
    emoji: '🇫🇴',
    unicode: 'U+1F1EB U+1F1F4',
    name: 'Faroe Islands' },
  { code: 'FJ',
    emoji: '🇫🇯',
    unicode: 'U+1F1EB U+1F1EF',
    name: 'Fiji' },
  { code: 'FR',
    emoji: '🇫🇷',
    unicode: 'U+1F1EB U+1F1F7',
    name: 'France' },
  { code: 'GF',
    emoji: '🇬🇫',
    unicode: 'U+1F1EC U+1F1EB',
    name: 'French Guiana' },
  { code: 'PF',
    emoji: '🇵🇫',
    unicode: 'U+1F1F5 U+1F1EB',
    name: 'French Polynesia' },
  { code: 'TF',
    emoji: '🇹🇫',
    unicode: 'U+1F1F9 U+1F1EB',
    name: 'French Southern Territories' },
  { code: 'GA',
    emoji: '🇬🇦',
    unicode: 'U+1F1EC U+1F1E6',
    name: 'Gabon' },
  { code: 'GM',
    emoji: '🇬🇲',
    unicode: 'U+1F1EC U+1F1F2',
    name: 'Gambia' },
  { code: 'GE',
    emoji: '🇬🇪',
    unicode: 'U+1F1EC U+1F1EA',
    name: 'Georgia' },
  { code: 'GH',
    emoji: '🇬🇭',
    unicode: 'U+1F1EC U+1F1ED',
    name: 'Ghana' },
  { code: 'GI',
    emoji: '🇬🇮',
    unicode: 'U+1F1EC U+1F1EE',
    name: 'Gibraltar' },
  { code: 'GR',
    emoji: '🇬🇷',
    unicode: 'U+1F1EC U+1F1F7',
    name: 'Greece' },
  { code: 'GL',
    emoji: '🇬🇱',
    unicode: 'U+1F1EC U+1F1F1',
    name: 'Greenland' },
  { code: 'GD',
    emoji: '🇬🇩',
    unicode: 'U+1F1EC U+1F1E9',
    name: 'Grenada' },
  { code: 'GP',
    emoji: '🇬🇵',
    unicode: 'U+1F1EC U+1F1F5',
    name: 'Guadeloupe' },
  { code: 'GU',
    emoji: '🇬🇺',
    unicode: 'U+1F1EC U+1F1FA',
    name: 'Guam' },
  { code: 'GT',
    emoji: '🇬🇹',
    unicode: 'U+1F1EC U+1F1F9',
    name: 'Guatemala' },
  { code: 'GG',
    emoji: '🇬🇬',
    unicode: 'U+1F1EC U+1F1EC',
    name: 'Guernsey' },
  { code: 'GN',
    emoji: '🇬🇳',
    unicode: 'U+1F1EC U+1F1F3',
    name: 'Guinea' },
  { code: 'GW',
    emoji: '🇬🇼',
    unicode: 'U+1F1EC U+1F1FC',
    name: 'Guinea-Bissau' },
  { code: 'GY',
    emoji: '🇬🇾',
    unicode: 'U+1F1EC U+1F1FE',
    name: 'Guyana' },
  { code: 'HT',
    emoji: '🇭🇹',
    unicode: 'U+1F1ED U+1F1F9',
    name: 'Haiti' },
  { code: 'HM',
    emoji: '🇭🇲',
    unicode: 'U+1F1ED U+1F1F2',
    name: 'Heard Island and Mcdonald Islands' },
  { code: 'HN',
    emoji: '🇭🇳',
    unicode: 'U+1F1ED U+1F1F3',
    name: 'Honduras' },
  { code: 'HK',
    emoji: '🇭🇰',
    unicode: 'U+1F1ED U+1F1F0',
    name: 'Hong Kong' },
  { code: 'HU',
    emoji: '🇭🇺',
    unicode: 'U+1F1ED U+1F1FA',
    name: 'Hungary' },
  { code: 'IS',
    emoji: '🇮🇸',
    unicode: 'U+1F1EE U+1F1F8',
    name: 'Iceland' },
  { code: 'IN',
    emoji: '🇮🇳',
    unicode: 'U+1F1EE U+1F1F3',
    name: 'India' },
  { code: 'ID',
    emoji: '🇮🇩',
    unicode: 'U+1F1EE U+1F1E9',
    name: 'Indonesia' },
  { code: 'IR',
    emoji: '🇮🇷',
    unicode: 'U+1F1EE U+1F1F7',
    name: 'Iran' },
  { code: 'IQ',
    emoji: '🇮🇶',
    unicode: 'U+1F1EE U+1F1F6',
    name: 'Iraq' },
  { code: 'IE',
    emoji: '🇮🇪',
    unicode: 'U+1F1EE U+1F1EA',
    name: 'Ireland' },
  { code: 'IM',
    emoji: '🇮🇲',
    unicode: 'U+1F1EE U+1F1F2',
    name: 'Isle of Man' },
  { code: 'IL',
    emoji: '🇮🇱',
    unicode: 'U+1F1EE U+1F1F1',
    name: 'Israel' },
  { code: 'IT',
    emoji: '🇮🇹',
    unicode: 'U+1F1EE U+1F1F9',
    name: 'Italy' },
  { code: 'JM',
    emoji: '🇯🇲',
    unicode: 'U+1F1EF U+1F1F2',
    name: 'Jamaica' },
  { code: 'JP',
    emoji: '🇯🇵',
    unicode: 'U+1F1EF U+1F1F5',
    name: 'Japan' },
  { code: 'JE',
    emoji: '🇯🇪',
    unicode: 'U+1F1EF U+1F1EA',
    name: 'Jersey' },
  { code: 'JO',
    emoji: '🇯🇴',
    unicode: 'U+1F1EF U+1F1F4',
    name: 'Jordan' },
  { code: 'KZ',
    emoji: '🇰🇿',
    unicode: 'U+1F1F0 U+1F1FF',
    name: 'Kazakhstan' },
  { code: 'KE',
    emoji: '🇰🇪',
    unicode: 'U+1F1F0 U+1F1EA',
    name: 'Kenya' },
  { code: 'KI',
    emoji: '🇰🇮',
    unicode: 'U+1F1F0 U+1F1EE',
    name: 'Kiribati' },
  { code: 'XK',
    emoji: '🇽🇰',
    unicode: 'U+1F1FD U+1F1F0',
    name: 'Kosovo' },
  { code: 'KW',
    emoji: '🇰🇼',
    unicode: 'U+1F1F0 U+1F1FC',
    name: 'Kuwait' },
  { code: 'KG',
    emoji: '🇰🇬',
    unicode: 'U+1F1F0 U+1F1EC',
    name: 'Kyrgyzstan' },
  { code: 'LA',
    emoji: '🇱🇦',
    unicode: 'U+1F1F1 U+1F1E6',
    name: 'Lao People\'s Democratic Republic' },
  { code: 'LB',
    emoji: '🇱🇧',
    unicode: 'U+1F1F1 U+1F1E7',
    name: 'Lebanon' },
  { code: 'LS',
    emoji: '🇱🇸',
    unicode: 'U+1F1F1 U+1F1F8',
    name: 'Lesotho' },
  { code: 'LR',
    emoji: '🇱🇷',
    unicode: 'U+1F1F1 U+1F1F7',
    name: 'Liberia' },
  { code: 'LY',
    emoji: '🇱🇾',
    unicode: 'U+1F1F1 U+1F1FE',
    name: 'Libya' },
  { code: 'LI',
    emoji: '🇱🇮',
    unicode: 'U+1F1F1 U+1F1EE',
    name: 'Liechtenstein' },
  { code: 'LU',
    emoji: '🇱🇺',
    unicode: 'U+1F1F1 U+1F1FA',
    name: 'Luxembourg' },
  { code: 'MO',
    emoji: '🇲🇴',
    unicode: 'U+1F1F2 U+1F1F4',
    name: 'Macao' },
  { code: 'MK',
    emoji: '🇲🇰',
    unicode: 'U+1F1F2 U+1F1F0',
    name: 'Macedonia' },
  { code: 'MG',
    emoji: '🇲🇬',
    unicode: 'U+1F1F2 U+1F1EC',
    name: 'Madagascar' },
  { code: 'MW',
    emoji: '🇲🇼',
    unicode: 'U+1F1F2 U+1F1FC',
    name: 'Malawi' },
  { code: 'MY',
    emoji: '🇲🇾',
    unicode: 'U+1F1F2 U+1F1FE',
    name: 'Malaysia' },
  { code: 'MV',
    emoji: '🇲🇻',
    unicode: 'U+1F1F2 U+1F1FB',
    name: 'Maldives' },
  { code: 'ML',
    emoji: '🇲🇱',
    unicode: 'U+1F1F2 U+1F1F1',
    name: 'Mali' },
  { code: 'MT',
    emoji: '🇲🇹',
    unicode: 'U+1F1F2 U+1F1F9',
    name: 'Malta' },
  { code: 'MH',
    emoji: '🇲🇭',
    unicode: 'U+1F1F2 U+1F1ED',
    name: 'Marshall Islands' },
  { code: 'MQ',
    emoji: '🇲🇶',
    unicode: 'U+1F1F2 U+1F1F6',
    name: 'Martinique' },
  { code: 'MR',
    emoji: '🇲🇷',
    unicode: 'U+1F1F2 U+1F1F7',
    name: 'Mauritania' },
  { code: 'MU',
    emoji: '🇲🇺',
    unicode: 'U+1F1F2 U+1F1FA',
    name: 'Mauritius' },
  { code: 'YT',
    emoji: '🇾🇹',
    unicode: 'U+1F1FE U+1F1F9',
    name: 'Mayotte' },
  { code: 'MX',
    emoji: '🇲🇽',
    unicode: 'U+1F1F2 U+1F1FD',
    name: 'Mexico' },
  { code: 'FM',
    emoji: '🇫🇲',
    unicode: 'U+1F1EB U+1F1F2',
    name: 'Micronesia' },
  { code: 'MD',
    emoji: '🇲🇩',
    unicode: 'U+1F1F2 U+1F1E9',
    name: 'Moldova' },
  { code: 'MC',
    emoji: '🇲🇨',
    unicode: 'U+1F1F2 U+1F1E8',
    name: 'Monaco' },
  { code: 'MN',
    emoji: '🇲🇳',
    unicode: 'U+1F1F2 U+1F1F3',
    name: 'Mongolia' },
  { code: 'ME',
    emoji: '🇲🇪',
    unicode: 'U+1F1F2 U+1F1EA',
    name: 'Montenegro' },
  { code: 'MS',
    emoji: '🇲🇸',
    unicode: 'U+1F1F2 U+1F1F8',
    name: 'Montserrat' },
  { code: 'MA',
    emoji: '🇲🇦',
    unicode: 'U+1F1F2 U+1F1E6',
    name: 'Morocco' },
  { code: 'MZ',
    emoji: '🇲🇿',
    unicode: 'U+1F1F2 U+1F1FF',
    name: 'Mozambique' },
  { code: 'MM',
    emoji: '🇲🇲',
    unicode: 'U+1F1F2 U+1F1F2',
    name: 'Myanmar' },
  { code: 'NA',
    emoji: '🇳🇦',
    unicode: 'U+1F1F3 U+1F1E6',
    name: 'Namibia' },
  { code: 'NR',
    emoji: '🇳🇷',
    unicode: 'U+1F1F3 U+1F1F7',
    name: 'Nauru' },
  { code: 'NP',
    emoji: '🇳🇵',
    unicode: 'U+1F1F3 U+1F1F5',
    name: 'Nepal' },
  { code: 'NL',
    emoji: '🇳🇱',
    unicode: 'U+1F1F3 U+1F1F1',
    name: 'Netherlands' },
  { code: 'NC',
    emoji: '🇳🇨',
    unicode: 'U+1F1F3 U+1F1E8',
    name: 'New Caledonia' },
  { code: 'NZ',
    emoji: '🇳🇿',
    unicode: 'U+1F1F3 U+1F1FF',
    name: 'New Zealand' },
  { code: 'NI',
    emoji: '🇳🇮',
    unicode: 'U+1F1F3 U+1F1EE',
    name: 'Nicaragua' },
  { code: 'NE',
    emoji: '🇳🇪',
    unicode: 'U+1F1F3 U+1F1EA',
    name: 'Niger' },
  { code: 'NG',
    emoji: '🇳🇬',
    unicode: 'U+1F1F3 U+1F1EC',
    name: 'Nigeria' },
  { code: 'NU',
    emoji: '🇳🇺',
    unicode: 'U+1F1F3 U+1F1FA',
    name: 'Niue' },
  { code: 'NF',
    emoji: '🇳🇫',
    unicode: 'U+1F1F3 U+1F1EB',
    name: 'Norfolk Island' },
  { code: 'KP',
    emoji: '🇰🇵',
    unicode: 'U+1F1F0 U+1F1F5',
    name: 'North Korea' },
  { code: 'MP',
    emoji: '🇲🇵',
    unicode: 'U+1F1F2 U+1F1F5',
    name: 'Northern Mariana Islands' },
  { code: 'NO',
    emoji: '🇳🇴',
    unicode: 'U+1F1F3 U+1F1F4',
    name: 'Norway' },
  { code: 'OM',
    emoji: '🇴🇲',
    unicode: 'U+1F1F4 U+1F1F2',
    name: 'Oman' },
  { code: 'PK',
    emoji: '🇵🇰',
    unicode: 'U+1F1F5 U+1F1F0',
    name: 'Pakistan' },
  { code: 'PW',
    emoji: '🇵🇼',
    unicode: 'U+1F1F5 U+1F1FC',
    name: 'Palau' },
  { code: 'PS',
    emoji: '🇵🇸',
    unicode: 'U+1F1F5 U+1F1F8',
    name: 'Palestinian Territory' },
  { code: 'PA',
    emoji: '🇵🇦',
    unicode: 'U+1F1F5 U+1F1E6',
    name: 'Panama' },
  { code: 'PG',
    emoji: '🇵🇬',
    unicode: 'U+1F1F5 U+1F1EC',
    name: 'Papua New Guinea' },
  { code: 'PY',
    emoji: '🇵🇾',
    unicode: 'U+1F1F5 U+1F1FE',
    name: 'Paraguay' },
  { code: 'PE',
    emoji: '🇵🇪',
    unicode: 'U+1F1F5 U+1F1EA',
    name: 'Peru' },
  { code: 'PH',
    emoji: '🇵🇭',
    unicode: 'U+1F1F5 U+1F1ED',
    name: 'Philippines' },
  { code: 'PN',
    emoji: '🇵🇳',
    unicode: 'U+1F1F5 U+1F1F3',
    name: 'Pitcairn' },
  { code: 'PT',
    emoji: '🇵🇹',
    unicode: 'U+1F1F5 U+1F1F9',
    name: 'Portugal' },
  { code: 'PR',
    emoji: '🇵🇷',
    unicode: 'U+1F1F5 U+1F1F7',
    name: 'Puerto Rico' },
  { code: 'QA',
    emoji: '🇶🇦',
    unicode: 'U+1F1F6 U+1F1E6',
    name: 'Qatar' },
  { code: 'RO',
    emoji: '🇷🇴',
    unicode: 'U+1F1F7 U+1F1F4',
    name: 'Romania' },
  { code: 'RW',
    emoji: '🇷🇼',
    unicode: 'U+1F1F7 U+1F1FC',
    name: 'Rwanda' },
  { code: 'RE',
    emoji: '🇷🇪',
    unicode: 'U+1F1F7 U+1F1EA',
    name: 'Réunion' },
  { code: 'BL',
    emoji: '🇧🇱',
    unicode: 'U+1F1E7 U+1F1F1',
    name: 'Saint Barthélemy' },
  { code: 'SH',
    emoji: '🇸🇭',
    unicode: 'U+1F1F8 U+1F1ED',
    name: 'Saint Helena, Ascension and Tristan Da Cunha' },
  { code: 'KN',
    emoji: '🇰🇳',
    unicode: 'U+1F1F0 U+1F1F3',
    name: 'Saint Kitts and Nevis' },
  { code: 'LC',
    emoji: '🇱🇨',
    unicode: 'U+1F1F1 U+1F1E8',
    name: 'Saint Lucia' },
  { code: 'MF',
    emoji: '🇲🇫',
    unicode: 'U+1F1F2 U+1F1EB',
    name: 'Saint Martin (French Part)' },
  { code: 'PM',
    emoji: '🇵🇲',
    unicode: 'U+1F1F5 U+1F1F2',
    name: 'Saint Pierre and Miquelon' },
  { code: 'VC',
    emoji: '🇻🇨',
    unicode: 'U+1F1FB U+1F1E8',
    name: 'Saint Vincent and The Grenadines' },
  { code: 'WS',
    emoji: '🇼🇸',
    unicode: 'U+1F1FC U+1F1F8',
    name: 'Samoa' },
  { code: 'SM',
    emoji: '🇸🇲',
    unicode: 'U+1F1F8 U+1F1F2',
    name: 'San Marino' },
  { code: 'ST',
    emoji: '🇸🇹',
    unicode: 'U+1F1F8 U+1F1F9',
    name: 'Sao Tome and Principe' },
  { code: 'SA',
    emoji: '🇸🇦',
    unicode: 'U+1F1F8 U+1F1E6',
    name: 'Saudi Arabia' },
  { code: 'SN',
    emoji: '🇸🇳',
    unicode: 'U+1F1F8 U+1F1F3',
    name: 'Senegal' },
  { code: 'RS',
    emoji: '🇷🇸',
    unicode: 'U+1F1F7 U+1F1F8',
    name: 'Serbia' },
  { code: 'SC',
    emoji: '🇸🇨',
    unicode: 'U+1F1F8 U+1F1E8',
    name: 'Seychelles' },
  { code: 'SL',
    emoji: '🇸🇱',
    unicode: 'U+1F1F8 U+1F1F1',
    name: 'Sierra Leone' },
  { code: 'SG',
    emoji: '🇸🇬',
    unicode: 'U+1F1F8 U+1F1EC',
    name: 'Singapore' },
  { code: 'SX',
    emoji: '🇸🇽',
    unicode: 'U+1F1F8 U+1F1FD',
    name: 'Sint Maarten (Dutch Part)' },
  { code: 'SK',
    emoji: '🇸🇰',
    unicode: 'U+1F1F8 U+1F1F0',
    name: 'Slovakia' },
  { code: 'SI',
    emoji: '🇸🇮',
    unicode: 'U+1F1F8 U+1F1EE',
    name: 'Slovenia' },
  { code: 'SB',
    emoji: '🇸🇧',
    unicode: 'U+1F1F8 U+1F1E7',
    name: 'Solomon Islands' },
  { code: 'SO',
    emoji: '🇸🇴',
    unicode: 'U+1F1F8 U+1F1F4',
    name: 'Somalia' },
  { code: 'ZA',
    emoji: '🇿🇦',
    unicode: 'U+1F1FF U+1F1E6',
    name: 'South Africa' },
  { code: 'GS',
    emoji: '🇬🇸',
    unicode: 'U+1F1EC U+1F1F8',
    name: 'South Georgia' },
  { code: 'KR',
    emoji: '🇰🇷',
    unicode: 'U+1F1F0 U+1F1F7',
    name: 'South Korea' },
  { code: 'SS',
    emoji: '🇸🇸',
    unicode: 'U+1F1F8 U+1F1F8',
    name: 'South Sudan' },
  { code: 'ES',
    emoji: '🇪🇸',
    unicode: 'U+1F1EA U+1F1F8',
    name: 'Spain' },
  { code: 'LK',
    emoji: '🇱🇰',
    unicode: 'U+1F1F1 U+1F1F0',
    name: 'Sri Lanka' },
  { code: 'SD',
    emoji: '🇸🇩',
    unicode: 'U+1F1F8 U+1F1E9',
    name: 'Sudan' },
  { code: 'SR',
    emoji: '🇸🇷',
    unicode: 'U+1F1F8 U+1F1F7',
    name: 'Suriname' },
  { code: 'SJ',
    emoji: '🇸🇯',
    unicode: 'U+1F1F8 U+1F1EF',
    name: 'Svalbard and Jan Mayen' },
  { code: 'SZ',
    emoji: '🇸🇿',
    unicode: 'U+1F1F8 U+1F1FF',
    name: 'Swaziland' },
  { code: 'CH',
    emoji: '🇨🇭',
    unicode: 'U+1F1E8 U+1F1ED',
    name: 'Switzerland' },
  { code: 'SY',
    emoji: '🇸🇾',
    unicode: 'U+1F1F8 U+1F1FE',
    name: 'Syrian Arab Republic' },
  { code: 'TW',
    emoji: '🇹🇼',
    unicode: 'U+1F1F9 U+1F1FC',
    name: 'Taiwan' },
  { code: 'TJ',
    emoji: '🇹🇯',
    unicode: 'U+1F1F9 U+1F1EF',
    name: 'Tajikistan' },
  { code: 'TZ',
    emoji: '🇹🇿',
    unicode: 'U+1F1F9 U+1F1FF',
    name: 'Tanzania' },
  { code: 'TH',
    emoji: '🇹🇭',
    unicode: 'U+1F1F9 U+1F1ED',
    name: 'Thailand' },
  { code: 'TL',
    emoji: '🇹🇱',
    unicode: 'U+1F1F9 U+1F1F1',
    name: 'Timor-Leste' },
  { code: 'TG',
    emoji: '🇹🇬',
    unicode: 'U+1F1F9 U+1F1EC',
    name: 'Togo' },
  { code: 'TK',
    emoji: '🇹🇰',
    unicode: 'U+1F1F9 U+1F1F0',
    name: 'Tokelau' },
  { code: 'TO',
    emoji: '🇹🇴',
    unicode: 'U+1F1F9 U+1F1F4',
    name: 'Tonga' },
  { code: 'TT',
    emoji: '🇹🇹',
    unicode: 'U+1F1F9 U+1F1F9',
    name: 'Trinidad and Tobago' },
  { code: 'TN',
    emoji: '🇹🇳',
    unicode: 'U+1F1F9 U+1F1F3',
    name: 'Tunisia' },
  { code: 'TR',
    emoji: '🇹🇷',
    unicode: 'U+1F1F9 U+1F1F7',
    name: 'Turkey' },
  { code: 'TM',
    emoji: '🇹🇲',
    unicode: 'U+1F1F9 U+1F1F2',
    name: 'Turkmenistan' },
  { code: 'TC',
    emoji: '🇹🇨',
    unicode: 'U+1F1F9 U+1F1E8',
    name: 'Turks and Caicos Islands' },
  { code: 'TV',
    emoji: '🇹🇻',
    unicode: 'U+1F1F9 U+1F1FB',
    name: 'Tuvalu' },
  { code: 'UG',
    emoji: '🇺🇬',
    unicode: 'U+1F1FA U+1F1EC',
    name: 'Uganda' },
  { code: 'UA',
    emoji: '🇺🇦',
    unicode: 'U+1F1FA U+1F1E6',
    name: 'Ukraine' },
  { code: 'AE',
    emoji: '🇦🇪',
    unicode: 'U+1F1E6 U+1F1EA',
    name: 'United Arab Emirates' },
  { code: 'GB',
    emoji: '🇬🇧',
    unicode: 'U+1F1EC U+1F1E7',
    name: 'United Kingdom' },
  { code: 'US',
    emoji: '🇺🇸',
    unicode: 'U+1F1FA U+1F1F8',
    name: 'United States' },
  { code: 'UM',
    emoji: '🇺🇲',
    unicode: 'U+1F1FA U+1F1F2',
    name: 'United States Minor Outlying Islands' },
  { code: 'UY',
    emoji: '🇺🇾',
    unicode: 'U+1F1FA U+1F1FE',
    name: 'Uruguay' },
  { code: 'UZ',
    emoji: '🇺🇿',
    unicode: 'U+1F1FA U+1F1FF',
    name: 'Uzbekistan' },
  { code: 'VU',
    emoji: '🇻🇺',
    unicode: 'U+1F1FB U+1F1FA',
    name: 'Vanuatu' },
  { code: 'VA',
    emoji: '🇻🇦',
    unicode: 'U+1F1FB U+1F1E6',
    name: 'Vatican City' },
  { code: 'VE',
    emoji: '🇻🇪',
    unicode: 'U+1F1FB U+1F1EA',
    name: 'Venezuela' },
  { code: 'VN',
    emoji: '🇻🇳',
    unicode: 'U+1F1FB U+1F1F3',
    name: 'Viet Nam' },
  { code: 'VG',
    emoji: '🇻🇬',
    unicode: 'U+1F1FB U+1F1EC',
    name: 'Virgin Islands, British' },
  { code: 'VI',
    emoji: '🇻🇮',
    unicode: 'U+1F1FB U+1F1EE',
    name: 'Virgin Islands, U.S.' },
  { code: 'WF',
    emoji: '🇼🇫',
    unicode: 'U+1F1FC U+1F1EB',
    name: 'Wallis and Futuna' },
  { code: 'EH',
    emoji: '🇪🇭',
    unicode: 'U+1F1EA U+1F1ED',
    name: 'Western Sahara' },
  { code: 'YE',
    emoji: '🇾🇪',
    unicode: 'U+1F1FE U+1F1EA',
    name: 'Yemen' },
  { code: 'ZM',
    emoji: '🇿🇲',
    unicode: 'U+1F1FF U+1F1F2',
    name: 'Zambia' },
  { code: 'ZW',
    emoji: '🇿🇼',
    unicode: 'U+1F1FF U+1F1FC',
    name: 'Zimbabwe' },
  { code: 'AX',
    emoji: '🇦🇽',
    unicode: 'U+1F1E6 U+1F1FD',
    name: 'Åland Islands' } ]
