import { computed } from 'vue'
import { CONFIG_STORE_ACTIONS, CONFIG_STORE_GETTERS } from '@/enums'
import xstore from '@/store'

export default function useConfig() {
    // TODO:
  const isConfigLoaded = computed(() => xstore.getters[CONFIG_STORE_GETTERS.IS_CONFIG_LOADED])

  const config = computed(() => xstore.getters[CONFIG_STORE_GETTERS.CONFIG])

  return {
    isConfigLoaded,
    config
  }
}
