export enum USERS_STORE_STATE {
  ACTIVE_USER = 'Users/State/ACTIVE_USER'
}

export enum USERS_STORE_MUTATIONS {
  SET_USER = 'Users/Mutations/SET_USER'
}

export enum USERS_STORE_ACTIONS {
  GET_USER = 'Users/Actions/GET_USER',
  UPDATE_USER = 'Users/Actions/UPDATE_USER'
}

export enum USERS_STORE_GETTERS {
  ACTIVE_USER = 'Users/Getters/ACTIVE_USER',
}