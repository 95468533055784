<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :fill="fill"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)" :fill="fill" stroke="none">
      <path
        d="M120 190 c0 -5 5 -10 12 -10 9 0 8 -5 -2 -15 -13 -13 -21 -12 -62 3
-50 18 -74 11 -42 -13 11 -8 14 -15 7 -15 -21 0 -15 -20 6 -20 10 0 26 -11 35
-25 13 -20 25 -25 55 -25 26 0 41 -5 45 -16 8 -21 50 -14 54 9 2 11 -7 29 -19
40 l-24 22 23 3 c30 4 23 23 -14 42 -16 8 -30 18 -32 23 -5 11 -42 9 -42 -3z
m60 -90 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z m30 -30 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z"
      />
      <path
        d="M14 86 c-10 -26 4 -48 28 -44 33 4 33 52 0 56 -13 2 -25 -3 -28 -12z
m36 -16 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'black'
    },
    viewBox: {
      type: String,
      default: '0 0 24 24'
    },
    width: {
      type: String,
      default: '18px'
    },
    height: {
      type: String,
      default: '18px'
    }
  }
}
</script>
