import mutations from './approval-settings.mutations'
import actions from './approval-settings.actions'
import getters from './approval-settings.getters'
import state from './approval-settings.state'

export default {
  mutations,
  actions,
  getters,
  state
}