import mutations from './config.mutations'
import actions from './config.actions'
import getters from './config.getters'
import state from './config.state'

export default {
  mutations,
  actions,
  getters,
  state
}
