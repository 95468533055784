import { ITEM_ROW_KEY } from '@/enums'

export const overWrittenValuesWhenAutofilling: Record<string, any> = {
  [ITEM_ROW_KEY.VAT_RATE]: {
    sum: null,
    total: null,
    VATsum: null
  },
  [ITEM_ROW_KEY.QUANTITY]: {
    sum: null,
    total: null,
    VATsum: null
  },
  [ITEM_ROW_KEY.PRICE]: {
    sum: null,
    total: null,
    VATsum: null
  },
  [ITEM_ROW_KEY.SUM]: {
    total: null,
    price: null,
    VATsum: null
  },
  [ITEM_ROW_KEY.TOTAL]: {
    sum: null,
    price: null,
    VATsum: null
  }
}
