<template>
  <div :class="{ desktop, 'select-container': showButton }" :tabindex="tabindex" @blur="() => (open = false)">
    <div v-if="showButton" class="selected" :class="{ open }" @click="() => (open = !open)">
      <span>{{ selected }}</span>
      <icon-chevron-down fill="#484e70" width="24px" height="24px"></icon-chevron-down>
    </div>
    <div class="select-options" :class="{ hidden: !open && showButton, top: placement === 'top' }">
      <div
        v-for="(option, index) of options"
        :key="index"
        :class="{ focused: focusedOption.value === option.value }"
        @click="
          () => {
            selected = option.title
            open = false
            $emit('update:modelValue', option.value)
          }
        "
      >
        {{ option.title }}
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, onBeforeUnmount } from 'vue'
import IconChevronDown from '../icons/IconChevronDown.vue'

export default {
  name: 'UiSelectTransparent',
  components: { IconChevronDown },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: String
    },
    options: {
      type: Array,
      required: true
    },
    showButton: {
      type: Boolean,
      default: true
    },
    tabindex: {
      type: Number,
      default: 0
    },
    placement: {
      type: String, // 'top' | 'bottom'
      default: 'bottom'
    },
    desktop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: false
    }
  },
  setup(props, { emit }) {
    const ENTER_KEY_CODE = 13
    const DOWN_ARROW_KEY_CODE = 40
    const UP_ARROW_KEY_CODE = 38
    const selected = ref('')
    const focusedOption = ref('')

    onMounted(() => {
      if (props.modelValue && props.options && props.options.length > 0) {
        let preselect = props.options.find((item) => item.value === props.modelValue)
        selected.value = preselect?.title
        return
      }
      selected.value = props.options?.[0]?.title
      if (props.options?.length) {
        window.addEventListener('keyup', onKeyup)
      }
    })

    onBeforeUnmount(() => {
      window.removeEventListener('keyup', onKeyup)
    })

    function focusNextListItem(direction) {
      let nextActiveElementIndex
      if (typeof focusedOption.value === 'string') {
        nextActiveElementIndex = props.options.indexOf(focusedOption.value)
      } else {
        nextActiveElementIndex = props.options.findIndex((option) => option?.value === focusedOption.value?.value)
      }

      // Select next option
      if (nextActiveElementIndex === -1) {
        nextActiveElementIndex = 0
      } else if (direction === DOWN_ARROW_KEY_CODE) {
        nextActiveElementIndex += 1
      } else if (direction === UP_ARROW_KEY_CODE) {
        nextActiveElementIndex -= 1
      }

      // Go from first to last and last to first option
      nextActiveElementIndex = (nextActiveElementIndex + props.options.length) % props.options.length
      focusedOption.value = props.options[nextActiveElementIndex]
    }

    function onKeyup(event) {
      switch (event.keyCode) {
        case ENTER_KEY_CODE:
          emit('update:modelValue', focusedOption.value)
          return
        case DOWN_ARROW_KEY_CODE:
          focusNextListItem(DOWN_ARROW_KEY_CODE)
          return
        case UP_ARROW_KEY_CODE:
          focusNextListItem(UP_ARROW_KEY_CODE)
          return
        default:
          return
      }
    }

    return {
      selected,
      focusedOption
    }
  }
}
</script>

<style lang="scss" scoped>
.select-container {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 47px;
  line-height: 47px;
}

.selected {
  display: flex;
  align-items: center;
  padding-left: 16px;
  cursor: pointer;

  span {
    margin-right: 4px;
  }
}

.select-options {
  position: absolute;
  max-height: 70vh;
  left: 0;
  right: 0;
  border-radius: 4px;
  color: $white;
  background-color: $transparent-purple;
  overflow: auto;
  z-index: $base-layer;

  div {
    color: $white;
    padding-left: 16px;
    cursor: pointer;
  }

  div:hover {
    background-color: $grey;
  }

  &.hidden {
    display: none;
  }

  &.top {
    top: calc(-100% - 8px);
  }

  .focused {
    background-color: $grey;
  }
}

//DESKTOP VIEW
.desktop {
  font-size: 12px;
  line-height: 40px;

  &.select-container {
    height: 40px;
  }
}
</style>
