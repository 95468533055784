import { log } from '@/lib/log/index'
import * as Sentry from '@sentry/vue'

export default async function errorHandler (
  consoleMessage = '',
  payload = {},
  parameters = {}
) {
  log(`@errorHandler - ${ consoleMessage }`, payload = {}, parameters = {})

  if (location.hostname !== 'localhost' && location.hostname !== '127.0.0.1'){
    Sentry.captureException(`@errorHandler - ${ consoleMessage } ${ JSON.stringify({payload, parameters}) }`)
  }
}