<template>
    <button
      :class="{
        primary: btnType === 'primary',
        secondary: btnType === 'secondary',
        tertiary: btnType === 'tertiary',
        'loading-state': isLoading,
        transparent: isTransparent,
        [btnSize]: btnSize,
        desktop
      }"
      :disabled="isDisabled"
      :style="{ color: textColor }"
      @click="$emit('click', $event)"
    >
      <span v-if="isLoading" class="button-spinner"> </span>
      <span v-if="!isLoading && label">{{ text }}</span>
      <slot></slot>
    </button>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'UiButton',
  emits: ['click'],
  props: {
    btnType: {
      type: String,
      default: 'primary'
    },
    btnSize: {
      type: String,
      default: null
    },
    label: {
      type: String
    },
    labelColor: {
      type: String
    },
    upperCase: {
      type: Boolean,
      default: true
    },
    isTransparent: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    desktop: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const textColor = computed(() => {
      if (props.labelColor) {
        return props.labelColor
      }
      if ((props.btnType === 'secondary' || props.btnType === 'tertiary') && !props.labelColor) {
        return '#0053ff'
      }
      return '#ffffff'
    })

    const text = computed(() => {
      if (props.upperCase && props.label) {
        return props.label.toUpperCase()
      }
      return props.label
    })

    return {
      textColor,
      text
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

%btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 15px 16px;
  min-height: 56px;
  width: 100%;
  font-family: 'IBM Plex Sans';
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  transition: all 150ms ease-in-out;

  &.xs {
    padding: _($spacing, alpha) _($spacing, charlie);
    min-height: unset;
    width: unset;
    font-size: 14px;
  }
}

/*  Primary Button  */

.primary {
  @extend %btn;
  background: $main-blue;
  border: 1px solid $main-blue;
  color: $white;

  &:hover {
    background: #3375ff;
    border-color: #3375ff;
  }

  &:active,
  &.loading-state {
    background: #6698ff;
    border-color: #6698ff;
  }

  &:disabled {
    background: $medium-light-grey;
    border-color: $medium-light-grey;
    color: $grey !important;
    cursor: default;
    pointer-events: none;
  }

  &.loading-state {
    pointer-events: none;
  }
}

.button-spinner {
  display: inline-block;
  border: 2px solid #8bb1ff;
  border-top: 2px solid $white;
  border-radius: 50%;
  height: 22px;
  width: 22px;
  animation: spin 700ms linear infinite;
}

/*  Secondary Button  */

.secondary {
  @extend %btn;
  background: $white;
  border: 1px solid $white;
  color: $main-blue;

  &.transparent {
    background: transparent;
    border-color: transparent;
  }

  &:hover {
    border-color: $light-blue;
  }

  &:active {
    background: $light-blue;
    border-color: $light-blue;
  }

  &:disabled {
    color: $grey !important;
    cursor: default;
    pointer-events: none;
  }
}

/*  Tertiary Button  */

.tertiary {
  @extend %btn;
  background: $light-blue;
  border: 1px solid $light-blue;
  color: $main-blue;

  &:hover {
    border-color: #d3e2ff;
    background: #d3e2ff;
  }

  &:active {
    background: #bad2ff;
    border-color: #bad2ff;
  }

  &:disabled {
    background: $medium-light-grey;
    border-color: $medium-light-grey;
    color: $grey !important;
    cursor: default;
    pointer-events: none;
  }
}

// DESKTOP VIEW
.desktop {
  border-radius: 8px;
  padding: 8px 12px;
  min-height: 32px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}
</style>
