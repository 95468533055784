<template>
  <Transition>
    <div class="modal-container" v-if="showModal === true">
      <div class="modal-backdrop" @click="$emit('backdrop-click')"></div>

      <div class="modal-panel">
        <div class="grid-container">
          <slot></slot>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'UiModal',
  props: {
    showModal: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $modal-layer;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $transparent-purple;
}

.modal-panel {
  @extend %box-outline;
  position: absolute;
  width: 92.5%;
  max-width: 600px;
  max-height: 92%;
  padding: 16px 0;
  background: $white;
  overflow: auto;
  @include scrollbar;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
