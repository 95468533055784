<template>
  <label class="switch-container" :class="{ desktop }">
    <slot name="start"></slot>
    <input
      type="checkbox"
      :checked="modelValue"
      :disabled="isDisabled"
      @change="$emit('update:modelValue', $event.target.checked)"
    />
    <span class="slider no-shrink"></span>
    <slot name="end"></slot>
  </label>
</template>

<script>
export default {
  name: 'UiSwitch',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Boolean
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    desktop: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.switch-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  min-height: 20px;

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: relative;
    cursor: pointer;
    margin-top: 4px;
    width: 34px;
    height: 14px;
    border-radius: 7px;
    background: $medium-light-grey;
    transition: 300ms;

    &::after {
      content: '';
      position: absolute;
      top: -3px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: $grey;
      transition: 300ms;
    }
  }

  input:checked + .slider {
    background-color: #aac6ff;

    &::after {
      background: $main-blue;
      transform: translateX(14px);
    }
  }
}

/*   DESKTOP   */
.switch-container.desktop {
  min-height: 16px;

  .slider {
    margin-top: 3px;
    width: 26px;
    height: 11px;
    border-radius: 7px;

    &::after {
      top: -2.5px;
      height: 16px;
      width: 16px;
    }
  }
}
</style>
