export enum AUTH_STORE_GETTERS {
  IS_AUTHENTICATED = 'Auth/IS_AUTHENTICATED'
}

export enum AUTH_STORE_ACTIONS {
  GET_TOKEN_FROM_AUTH_CODE = 'Auth/GET_TOKEN_FROM_AUTH_CODE'
}

export enum AUTH_STORE_MUTAUTIONS {
  SET_AUTH_RESPONSE = 'Auth/SET_AUTH_RESPONSE'
}