import mutations from './auth.mutations'
import actions from './auth.actions'
import getters from './auth.getters'

export default {
  mutations,
  actions,
  getters,
  state: {}
}
