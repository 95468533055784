<template>
  <div class="ui-radio-card-wrapper" :class="{ selected }">
    <div class="ui-radio-card">
      <slot name="start"></slot>
      <div class="radio-button outer-circle">
        <div class="radio-button inner-circle"></div>
      </div>
      <slot name="end"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiRadio',
  props: {
    selected: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-radio-card-wrapper {
  border-radius: 8px;
  border: 1px solid transparent;
  transition: all 0.2s ease-in;

  .ui-radio-card {
    transition: all 0.2s ease-in;
    display: flex;
    justify-content: space-between;
    width: 100%;
    column-gap: 16px;
    padding: 16px;
    border-radius: 7px;
    border: 1px solid $light-blue;
    background: $white;
    cursor: pointer;

    &:hover {
      background: $light-blue;
    }
  }

  .radio-button {
    flex-shrink: 0;
    border-radius: 50%;
    transition: all 0.2s ease-in;

    &.outer-circle {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 18px;
      width: 18px;
      border: 2px solid $light-blue;
      background: $white;
    }

    &.inner-circle {
      height: 10px;
      width: 10px;
    }
  }

  &.selected {
    border: 1px solid $main-blue;

    .ui-radio-card {
      border: 1px solid $main-blue;
    }

    .outer-circle {
      border: 2px solid $main-blue;
    }

    .inner-circle {
      background: $main-blue;
    }
  }
}
</style>
