import estonia from './countries/estonia'
import finland from './countries/finland'
import latvia from './countries/latvia'
import lithuania from './countries/lithuania'
import poland from './countries/poland'
import sweden from './countries/sweden'
import { TRIP_TYPE } from '@/enums'
import { TripDestination, Trips } from '@/models'

export const allowanceCalculator = (
  countryCode: string,
  tripInput: {
    id: string | number | undefined
    destinations: TripDestination[]
    startDate?: string
    endDate?: string
    includesDailyAllowance?: boolean
    maxAllowance?: number
  },
  previousTrips: Trips
) => {
  if (!tripInput || !previousTrips || !countryCode) {
    throw { error: 'trip, trips or country missing', errorCode: 'tripTripsOrCountryMissing' }
  }

  const onlyTravelReports = Object.values(previousTrips).filter((previousTrip) => {
    return previousTrip.type === TRIP_TYPE.TRAVEL && (!tripInput.id || Number(tripInput.id) !== Number(previousTrip.id))
  })

  switch (countryCode) {
    case 'EE':
      return estonia(tripInput, onlyTravelReports)
    case 'FI':
      return finland(tripInput, onlyTravelReports)
    case 'LV':
      return latvia(tripInput, onlyTravelReports)
    case 'LT':
      return lithuania(tripInput, onlyTravelReports)
    case 'PL':
      return poland(tripInput, onlyTravelReports)
    case 'SE':
      return sweden(tripInput, onlyTravelReports)
    default:
      throw { error: 'Country not supported', errorCode: 'countryNotSupported' }
  }
}
