/**
 * Define helpers accessible from vue instances.
 */

"use strict";

/**
 * Make URL with pointers:
 * pointer to conf: (at)confparam
 * poiner to instance: $instancearg
 * pointer to url param: :urlparam
 * For example makeURL('@coreapi/companies/:companyId/receipts/$receiptId')
 * @param {String} urlString
 * @param {Array<Object>} [args]
 */
export function makeURL(url, args = []) {
    url = url.replace(/^@\w+\//, s => {
        var baseName = s.substr(1, s.length - 2)
        return this.$config.services[baseName].url + '/'
    })

    url = url.replace(/\/:\w+/g, s => {
        var param = s.substr(2)
        return '/' + this.$route.params[param]
    })

    url = url.replace(/\/\$\w+/g, s => {
        var arg = s.substr(2)
        return '/' + this[arg]
    })

    return url
}