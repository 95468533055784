import {
  INTEGRATIONS_STORE_GETTERS,
  INTEGRATIONS_STORE_STATE
} from '@/enums'

import {
  IntegrationsStoreState
} from '@/models'

export default {
  [INTEGRATIONS_STORE_GETTERS.ACTIVE_INTEGRATION]: (state: IntegrationsStoreState) => {
    return state[INTEGRATIONS_STORE_STATE.ACTIVE_INTEGRATION]
  },
  [INTEGRATIONS_STORE_GETTERS.SERVICE_ACTIVATION]: (state: IntegrationsStoreState) => {
    return state[INTEGRATIONS_STORE_STATE.SERVICE_ACTIVATION]
  }
}