module.exports = {
    AF: 40,
    AL: 33,
    DZ: 40, 
    US: 60,
    AD: 50,
    AE: 50,
    AU: 50,
    AT: 50,
    AZ: 40,
    BH: 50,
    BY: 30,
    BE: 60,
    BA: 35,
    BR: 50,
    BG: 35,
    CZ: 40,
    DK: 60,
    KR: 52,
    EG: 30,
    FR: 60,
    GI: 35,
    GL: 46,
    GR: 55,
    GE: 30,
    GT: 35,
    HR: 40,
    EE: 40,
    IN: 40,
    ID: 40,
    IQ: 40,
    IR: 35,
    IS: 65,
    IT: 60,
    IL: 60,
    IE: 60,
    JP: 70,
    NZ: 74,
    YE: 50,
    CA: 50,
    QA: 50,
    KZ: 30,
    KE: 40,
    CY: 46,
    KG: 30,
    XK: 35,
    RU: 35,
    KW: 50,
    CN: 35,
    LR: 50,
    GB: 65,
    LT: 30,
    LI: 60,
    LU: 60,
    LV: 8,
    MY: 40,
    MT: 45,
    MA: 40,
    ME: 40,
    MD: 30,
    MC: 50,
    MN: 35,
    NL: 60,
    NO: 65,
    OM: 50,
    PL: 35,
    PT: 50,
    RO: 35,
    SM: 40,
    SA: 50,
    RS: 40,
    SG: 50,
    SK: 35,
    SI: 35,
    FI: 55,
    ES: 50,
    CH: 60,
    TJ: 40,
    TN: 30,
    TR: 35,
    TM: 40,
    UA: 35,
    HU: 35,
    UZ: 40,
    DE: 50,
    VA: 60,
    VN: 35,
    MK: 35,
    SE: 50,
    'RU, Moscow': 46,
    'RU, Saint Petersburg': 46,
    'US, New York': 60,
    'US, Virgin Islands': 40,
    'CN, Beijing': 45,
    'CN, Shanghai': 45,
    'CN, Shenzhen': 45,
    'CN, Guangzhou': 45,
    'CN, Hangzhou': 45,
    'CN, Hong Kong': 55,
    'CN, Macau': 55,
    'DE, Hamburg': 50,
    'DE, Munich': 50,
    'DE, Frankfurt': 50,
    'DE, Berlin': 50,
}