import {
  CONFIG_STORE_MUTATIONS,
  CONFIG_STORE_STATE,
} from '@/enums'

import {
  ConfigStoreState
} from '@/models'

export default {
  [CONFIG_STORE_MUTATIONS.SET_CONFIG]: (state: ConfigStoreState, config: any) => {
    state[CONFIG_STORE_STATE.CONFIG] = config
  }
}