import mutations from './cost-types.mutations'
import actions from './cost-types.actions'
import getters from './cost-types.getters'
import state from './cost-types.state'

export default {
  mutations,
  actions,
  getters,
  state
}
