import { COST_TYPES_STORE_MUTATIONS, COST_TYPES_STORE_STATE } from '@/enums'
import { ReceiptsStoreState, ExpenseType } from '@/models'

export default {
  [COST_TYPES_STORE_MUTATIONS.SET_EXPENSE_TYPES]: (state: ReceiptsStoreState, expenseTypes: ExpenseType[]) => {
    if (!Array.isArray(expenseTypes)) return
    state[COST_TYPES_STORE_STATE.EXPENSE_TYPES] = expenseTypes
  },
  [COST_TYPES_STORE_MUTATIONS.SET_EXPENSE_TYPE]: (state: ReceiptsStoreState, expenseType: ExpenseType) => {
    const expenseTypes = (state[COST_TYPES_STORE_STATE.EXPENSE_TYPES] || []).map((existingExpenseType) => {
      return Number(existingExpenseType.id) === Number(expenseType.id) ? expenseType : existingExpenseType
    })
    state[COST_TYPES_STORE_STATE.EXPENSE_TYPES] = expenseTypes
  },
  [COST_TYPES_STORE_MUTATIONS.DELETE_EXPENSE_TYPE]: (state: ReceiptsStoreState, expenseTypeId: string | number) => {
    const expenseTypes = (state[COST_TYPES_STORE_STATE.EXPENSE_TYPES] || []).filter((expenseType) => {
      return Number(expenseTypeId) !== Number(expenseType.id)
    })
    state[COST_TYPES_STORE_STATE.EXPENSE_TYPES] = expenseTypes
  }
}
