import Axios from 'axios'
import { COST_TYPES_STORE_MUTATIONS, COST_TYPES_STORE_ACTIONS } from '@/enums'
import { ExpenseType } from '@/models'

export default {
  [COST_TYPES_STORE_ACTIONS.GET_EXPENSE_TYPES]: async ({ commit }: any, companyId = null) => {
    try {
      if (!companyId) return

      const { data } = await Axios.get(`@api/core/v1/companies/${companyId}/costtypes/tree`)

      commit(COST_TYPES_STORE_MUTATIONS.SET_EXPENSE_TYPES, data)

      return data
    } catch (error) {
      console.error(COST_TYPES_STORE_ACTIONS.GET_EXPENSE_TYPES, error)
    }
  },
  [COST_TYPES_STORE_ACTIONS.CREATE_EXPENSE_TYPE]: async (
    { commit }: any,
    payload: {
      companyId: string | number
      expenseType: ExpenseType
    }
  ) => {
    try {
      const { companyId, expenseType } = payload

      if (!companyId) return

      const { data } = await Axios.post(`@coreapi/companies/${companyId}/costtypes`, expenseType)

      commit(COST_TYPES_STORE_MUTATIONS.SET_EXPENSE_TYPE, data)

      return data
    } catch (error) {
      console.error(COST_TYPES_STORE_ACTIONS.CREATE_EXPENSE_TYPE, error)
      throw error
    }
  },
  [COST_TYPES_STORE_ACTIONS.UPDATE_EXPENSE_TYPE]: async (
    { commit }: any,
    payload: {
      companyId: string | number
      expenseTypeId: string | number
      expenseType: ExpenseType
    }
  ) => {
    try {
      const { companyId, expenseTypeId, expenseType } = payload

      if (!companyId || !expenseTypeId) return

      const { data } = await Axios.put(`@coreapi/companies/${companyId}/costtypes/${expenseTypeId}`, expenseType)

      commit(COST_TYPES_STORE_MUTATIONS.SET_EXPENSE_TYPE, data)

      return data
    } catch (error) {
      console.error(COST_TYPES_STORE_ACTIONS.UPDATE_EXPENSE_TYPE, error)
      throw error
    }
  },
  [COST_TYPES_STORE_ACTIONS.DELETE_EXPENSE_TYPE]: async (
    { commit }: any,
    payload: {
      companyId: string | number
      expenseTypeId: string | number
    }
  ) => {
    try {
      const { companyId, expenseTypeId } = payload

      if (!companyId || !expenseTypeId) return

      const { data } = await Axios.delete(`@coreapi/companies/${companyId}/costtypes/${expenseTypeId}`)

      commit(COST_TYPES_STORE_MUTATIONS.DELETE_EXPENSE_TYPE, expenseTypeId)

      return data
    } catch (error) {
      console.error(COST_TYPES_STORE_ACTIONS.DELETE_EXPENSE_TYPE, error)
      throw error
    }
  }
}
