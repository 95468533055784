import mutations from './overview.mutations'
import actions from './overview.actions'
import getters from './overview.getters'
import state from './overview.state'

export default {
  mutations,
  actions,
  getters,
  state
}