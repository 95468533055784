export enum CONFIG_STORE_STATE {
  CONFIG = 'Config/CONFIG'
}

export enum CONFIG_STORE_MUTATIONS {
  SET_CONFIG = 'Config/SET_CONFIG'
}

export enum CONFIG_STORE_ACTIONS {
  LOAD_CONFIG = 'Config/LOAD_CONFIG'
}

export enum CONFIG_STORE_GETTERS {
  IS_CONFIG_LOADED = 'Config/IS_CONFIG_LOADED',
  CONFIG = 'Config/CONFIG'
}

export enum STORE_STATE_LOCAL_KEY {
  FILTERS = '__Local/Filters'
}

export enum STORE_STATE {
  CANCEL_TOKENS = 'CANCEL_TOKENS',
  LOCALSTORAGE = 'LOCALSTORAGE'
}

export enum STORE_GETTERS {
  LOCALSTORAGE = 'LOCALSTORAGE'
}

export enum STORE_ACTIONS {
  UPDATE_LOCALSTORAGE = 'UPDATE_LOCALSTORAGE',
  INIT_LOCALSTORAGE = 'INIT_LOCALSTORAGE',
  USE_CANCEL_TOKEN = 'USE_CANCEL_TOKEN'
}

export enum STORE_MUTATIONS {
  REMOVE_CANCEL_TOKEN = 'REMOVE_CANCEL_TOKEN',
  SET_CANCEL_TOKEN = 'SET_CANCEL_TOKEN',
  SET_LOCALSTORE = 'SET_LOCALSTORE',
  SET_STATE = 'SET_STATE'
}

export enum STORAGE {
  ACTIVE_COMPANY = 'STORAGE__ACTIVE_COMPANY'
}
