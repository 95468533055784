import { TRIPS_STORE_GETTERS, TRIPS_STORE_STATE } from '@/enums'

import { TripsStoreState, Trip } from '@/models'

export default {
  [TRIPS_STORE_GETTERS.TRIPS]: (state: TripsStoreState): any => {
    return state[TRIPS_STORE_STATE.TRIPS] || {}
  },
  [TRIPS_STORE_GETTERS.ALLOWANCE]: (state: any): any => {
    return state[TRIPS_STORE_STATE.ALLOWANCE] || {}
  },
  [TRIPS_STORE_GETTERS.STATEMENT]: (state: any): any => {
    return state[TRIPS_STORE_STATE.STATEMENT] || {}
  },
  [TRIPS_STORE_GETTERS.STATEMENT_FORMATS]: (state: any): any => {
    return state[TRIPS_STORE_STATE.STATEMENT_FORMATS] || []
  }
}
