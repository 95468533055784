import {
  CONFIG_STORE_GETTERS,
  CONFIG_STORE_STATE,
} from '@/enums'

import {
  ConfigStoreState
} from '@/models'

export default {
  [CONFIG_STORE_GETTERS.CONFIG]: (state: ConfigStoreState) => {
    return state[CONFIG_STORE_STATE.CONFIG]
  },
  [CONFIG_STORE_GETTERS.IS_CONFIG_LOADED]: (state: ConfigStoreState) => {
    return !!state[CONFIG_STORE_STATE.CONFIG]
  }
}