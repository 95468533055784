import errorHandler from '@/lib/errorHandler'

import {
  AUTH_STORE_ACTIONS,
  AUTH_STORE_MUTAUTIONS
} from '@/enums'

import Axios from 'axios'

export default {
  [AUTH_STORE_ACTIONS.GET_TOKEN_FROM_AUTH_CODE]: async ({ commit }: any, payload: any = {}) => {
    try {
      const { code } = payload

      if (!code) {
        return
      }

      const { data = {} } = await Axios.post('@auth/api/v2/code', {
        grant_type: 'authorization_code',
        code
      })

      commit(AUTH_STORE_MUTAUTIONS.SET_AUTH_RESPONSE, data)

      return data
    } catch (error: any) {
      errorHandler('@GET_TOKEN_FROM_AUTH_CODE', error)
    }
  }
}