import { APPROVALS_STORE_MUTATIONS, APPROVALS_STORE_STATE } from '@/enums'
import { ApprovalsStoreState, ApprovalItem, SelectOptions } from '@/models'
import { useHelpers } from '@/composables'

export default {
  [APPROVALS_STORE_MUTATIONS.SET_APPROVAL]: (state: ApprovalsStoreState, payload: any) => {
    const { companyId, approval }: { companyId: string | number; approval: ApprovalItem } = payload
    const { updateObject } = useHelpers()

    if (!state[APPROVALS_STORE_STATE.APPROVALS][companyId]) {
      state[APPROVALS_STORE_STATE.APPROVALS][companyId] = {}
    }

    const initialApproval = state[APPROVALS_STORE_STATE.APPROVALS][companyId][approval.id]
    if (initialApproval) {
      state[APPROVALS_STORE_STATE.APPROVALS][companyId][approval.id] = updateObject(initialApproval, approval)
    } else {
      state[APPROVALS_STORE_STATE.APPROVALS][companyId][approval.id] = approval
    }
  },
  [APPROVALS_STORE_MUTATIONS.SET_APPROVALS]: (state: ApprovalsStoreState, payload: any) => {
    const { companyId, approvals = [] }: { companyId: string | number; approvals: ApprovalItem[] } = payload

    if (!companyId) return

    if (!state[APPROVALS_STORE_STATE.APPROVALS][companyId]) {
      state[APPROVALS_STORE_STATE.APPROVALS][companyId] = {}
    }

    approvals.forEach((approval) => {
      state[APPROVALS_STORE_STATE.APPROVALS][companyId][approval.id] = approval
    })
  },
  [APPROVALS_STORE_MUTATIONS.DELETE_APPROVAL]: (state: ApprovalsStoreState, payload: any) => {
    const { companyId, approvalId }: { companyId: string | number; approvalId: string | number } = payload

    delete state[APPROVALS_STORE_STATE.APPROVALS][companyId][approvalId]
  },
  [APPROVALS_STORE_MUTATIONS.SET_APPROVER_OPTIONS]: (state: ApprovalsStoreState, payload: any) => {
    const { companyId, assignableApprovers } = payload

    const approverOptions: SelectOptions[] = []
    if (assignableApprovers && assignableApprovers.length > 0) {
      let assignable = assignableApprovers.map((approver: any) => {
        return { title: approver.fullName || '-', extraInfo: approver.email || '-', value: approver.id }
      })

      assignable.forEach((approver: any) => {
        let found = approverOptions.find((option: any) => option.value === approver.value)
        if (!found) {
          approverOptions.push(approver)
        }
      })
    }

    state[APPROVALS_STORE_STATE.APPROVERS][companyId] = approverOptions
  }
}
