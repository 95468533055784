import { COMPANIES_STORE_MUTATIONS, COMPANIES_STORE_ACTIONS } from '@/enums'
import errorHandler from '@/lib/errorHandler'
import axios from 'axios'

export default {
  [COMPANIES_STORE_ACTIONS.GET_COMPANIES]: async ({ commit }: any) => {
    try {
      const { data: companies = [] } = await axios.get('@api/core/v1/companies?includeRoles=true')

      companies.forEach((company: any) => delete company.settings)

      commit(COMPANIES_STORE_MUTATIONS.SET_COMPANIES, companies)

      return companies
    } catch (error: any) {
      errorHandler(COMPANIES_STORE_ACTIONS.GET_COMPANIES, error)
    }
  },
  [COMPANIES_STORE_ACTIONS.GET_COMPANY_ROLES]: async (_: any, payload: any) => {
    try {
      const { data = [] } = await axios.get(`@api/core/v1/companies/${payload.companyId}/roles`)

      return data
    } catch (error: any) {
      errorHandler(COMPANIES_STORE_ACTIONS.GET_COMPANY_ROLES, error)
    }
  },
  [COMPANIES_STORE_ACTIONS.SEND_INVITE]: async ({ commit, state }: any, payload: any) => {
    try {
      const { companyId, user } = payload

      const { data = {} } = await axios.post(
        `@api/core/v1/companies/${companyId}/roles?invitationLang=${user.language}`,
        {
          email: user.email,
          accountant: user.isAdmin
        }
      )

      commit(COMPANIES_STORE_MUTATIONS.SET_COMPANY_VALUES, {
        companyId,
        values: {
          companyRoles: [
            ...(state?.companies?.[companyId].companyRoles && state?.companies?.[companyId].companyRoles),
            data
          ]
        }
      })

      return data
    } catch (error: any) {
      errorHandler(COMPANIES_STORE_ACTIONS.SEND_INVITE, error)
    }
  },
  [COMPANIES_STORE_ACTIONS.GET_COMPANY]: async ({ commit }: any, id: string | number) => {
    try {
      const { data: company } = await axios.get(`@api/core/v1/companies/${id}`)

      commit(COMPANIES_STORE_MUTATIONS.SET_COMPANIES, [company])

      return company
    } catch (error: any) {
      errorHandler(COMPANIES_STORE_ACTIONS.GET_COMPANY, error)
    }
  },
  [COMPANIES_STORE_ACTIONS.UPDATE_SETTINGS]: async ({ commit }: any, payload: any) => {
    try {
      const { companyId, settings } = payload

      const { data: updatedSettings = {} } = await axios.post(`@coreapi/companies/${companyId}/settings/features`, settings)

      commit(COMPANIES_STORE_MUTATIONS.SET_COMPANY_SETTINGS, { companyId, updatedSettings })

      return updatedSettings
    } catch (error: any) {
      errorHandler(COMPANIES_STORE_ACTIONS.UPDATE_SETTINGS, error)
      throw error
    }
  }
}
