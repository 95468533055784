const Estonian = require('./data/et.json')
  , Polish = require('./data/pl.json')
  , Latvian = require('./data/lv.json')
  , Finnish = require('./data/fi.json')
  , English = require('./data/en.json')
  , Russian = require('./data/ru.json')
  , Swedish = require('./data/se.json')
  , Lithuanian = require('./data/lt.json')
  , Spanish = require('./data/es.json')

module.exports = {
  est: Estonian,
  pol: Polish,
  lav: Latvian,
  fin: Finnish,
  rus: Russian,
  eng: English,
  swe: Swedish,
  lit: Lithuanian,
  esp: Spanish
}
